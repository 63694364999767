import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTimes, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { Formik, Form, Field } from "formik";
import { ScheduleMeeting } from "react-schedule-meeting";
import {
  format,
  addDays,
  getWeekOfMonth,
  getDay,
  addHours,
  roundToNearestMinutes,
  isEqual,
  isAfter,
  isBefore,
} from "date-fns";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import FileUpload from "../Forms/FileUpload";

export default function ModalBookAppointment(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_staff } = useSelector((state) => state.auth);
  const [appointmentNotFound, setAppointmentNotFound] = useState(false);
  const [activeAppointment, setActiveAppointment] = useState([]);
  const [appointment, setAppointment] = useState([]);
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeChosen, setTimeChosen] = useState(false);
  const [timeScheduled, setTimeScheduled] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedToTime, setSelectedToTime] = useState("");
  const filterProp = useRef();

  const [chosenBuilding, setChosenBuilding] = useState("");
  const [chosenUnit, setChosenUnit] = useState("");
  const [chosenBed, setChosenBed] = useState("");
  const [completeStep1, setCompleteStep1] = useState(false);
  const { properties } = useSelector((state) => state.properties);
  const [units, setUnits] = useState([]);
  const [beds, setBeds] = useState([]);

  const [propertyName, setPropertyName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [bedName, setBedName] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [bedId, setBedId] = useState("");

  const [description, setDescription] = useState("");

  const [booking, setBooking] = useState([]);

  const [hours, setHours] = useState([
    { value: 0, label: "0" },
    { value: 60, label: "1" },
    { value: 120, label: "2" },
    { value: 180, label: "3" },
    { value: 240, label: "4" },
    { value: 300, label: "5" },
    { value: 360, label: "6" },
  ]);
  const [minutes, setMinutes] = useState([
    { value: 0, label: "0" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
  ]);

  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState("");

  useEffect(async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}custom_appointment_details/${props.appointment_type}`,
    }).then((response) => {
      if (response.data.length === 0) {
        setAppointmentNotFound(true);
      } else {
        setActiveAppointment(response.data);
      }
    });

    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}custom_current_appointment/${props.appointment_type}`,
    }).then((response) => {
      let resData = response.data[0];
      let fullData = response.data[1];
      setAppointment(resData);

      let days_arr = [];
      let days_full_arr = [];
      let i = 0;
      while (i < resData.days_amount) {
        if (
          resData.available_type === 1 ||
          (resData.available_type === 2 &&
            (((isEqual(
              new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
              new Date(resData?.custom?.jan_from_date_1)
            ) ||
              isAfter(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jan_from_date_1)
              )) &&
              (isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jan_to_date_1)
              ) ||
                isBefore(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jan_to_date_1)
                ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jan_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jan_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jan_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.jan_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.feb_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.feb_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.feb_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.feb_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.feb_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.feb_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.feb_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.feb_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.mar_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.mar_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.mar_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.mar_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.mar_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.mar_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.mar_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.mar_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.apr_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.apr_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.apr_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.apr_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.apr_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.apr_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.apr_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.apr_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.may_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.may_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.may_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.may_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.may_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.may_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.may_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.may_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jun_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jun_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jun_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.jun_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jun_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jun_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jun_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.jun_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jul_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jul_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jul_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.jul_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.jul_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jul_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.jul_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.jul_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.aug_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.aug_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.aug_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.aug_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.aug_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.aug_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.aug_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.aug_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.sept_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.sept_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.sept_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.sept_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.sept_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.sept_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.sept_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.sept_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.oct_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.oct_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.oct_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.oct_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.oct_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.oct_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.oct_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.oct_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.nov_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.nov_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.nov_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.nov_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.nov_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.nov_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.nov_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.nov_to_date_2)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.dec_from_date_1)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.dec_from_date_1)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.dec_to_date_1)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.dec_to_date_1)
                  ))) ||
              ((isEqual(
                new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                new Date(resData?.custom?.dec_from_date_2)
              ) ||
                isAfter(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.dec_from_date_2)
                )) &&
                (isEqual(
                  new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                  new Date(resData?.custom?.dec_to_date_2)
                ) ||
                  isBefore(
                    new Date(format(addDays(new Date(), i), "yyyy-MM-dd")),
                    new Date(resData?.custom?.dec_to_date_2)
                  )))))
        ) {
          let varName =
            format(addDays(new Date(), i), "EEE").toLowerCase() + "_hours";
          let startName =
            format(addDays(new Date(), i), "EEE").toLowerCase() + "_start";
          let endName =
            format(addDays(new Date(), i), "EEE").toLowerCase() + "_end";

          let allowDate = true;

          let day_date = parseFloat(format(addDays(new Date(), i), "dd"));

          if (resData.available_type === 1) {
            if (resData.first_week === 1 && day_date > 0 && day_date < 8) {
              allowDate = true;
            } else if (
              resData.second_week === 1 &&
              day_date > 7 &&
              day_date < 15
            ) {
              allowDate = true;
            } else if (
              resData.third_week === 1 &&
              day_date > 14 &&
              day_date < 22
            ) {
              allowDate = true;
            } else if (resData.fourth_week === 1 && day_date > 21) {
              allowDate = true;
            } else {
              allowDate = false;
            }
          }

          async function processBlockedDays(array) {
            for (const blocked of array) {
              if (
                format(addDays(new Date(), i), "yyyy-MM-dd") ===
                blocked.blocked_date
              ) {
                allowDate = false;
              }
            }
          }
          processBlockedDays(resData.blocked);

          if (resData[varName] === 1 && allowDate === true) {
            days_arr.push({
              id: i,
              start: resData[startName],
              end: resData[endName],
            });
          }
        }

        i++;
      }

      async function processFullDays(array) {
        for (const full of array) {
          days_full_arr.push({
            startTime: new Date(full.booked_date + " " + full.booked_time),
            endTime: new Date(full.booked_date + " " + full.time_to),
          });
        }
      }

      days_full_arr.push({
        startTime: new Date(
          format(new Date(), "yyyy-MM-dd") + " " + format(new Date(), "HH:mm")
        ),
        endTime: new Date(
          format(new Date(), "yyyy-MM-dd") +
            " " +
            format(
              roundToNearestMinutes(addHours(new Date(), 2), { nearestTo: 30 }),
              "HH:mm"
            )
        ),
      });

      processFullDays(fullData);

      let avail_days = [];

      async function processDays(array) {
        for (const day of array) {
          const [startHours, startMinutes] = day.start.split(":");
          const [endHours, endMinutes] = day.end.split(":");

          let interuption_found = false;
          let first_start = new Date(
            new Date(
              new Date().setDate(new Date().getDate() + day.id)
            ).setHours(startHours, startMinutes, 0, 0)
          );
          let default_start = new Date(
            new Date(
              new Date().setDate(new Date().getDate() + day.id)
            ).setHours(startHours, startMinutes, 0, 0)
          );

          async function processArray(array) {
            for (const full of array) {
              if (
                format(new Date(full.startTime), "yy-MM-dd") ===
                format(
                  new Date(
                    new Date(new Date().setDate(new Date().getDate() + day.id))
                  ),
                  "yy-MM-dd"
                )
              ) {
                interuption_found = true;
                if (first_start.getTime() === default_start.getTime()) {
                  avail_days.push({
                    id: day.id,
                    startTime: new Date(
                      new Date(
                        new Date().setDate(new Date().getDate() + day.id)
                      ).setHours(startHours, startMinutes, 0, 0)
                    ),
                    endTime: full.startTime,
                  });
                  first_start = full.endTime;
                } else {
                  avail_days.push({
                    id: day.id,
                    startTime: first_start,
                    endTime: full.startTime,
                  });
                  first_start = full.endTime;
                }
              }
            }
          }

          processArray(days_full_arr);

          if (first_start.getTime() !== default_start.getTime()) {
            avail_days.push({
              id: day.id,
              startTime: first_start,
              endTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(endHours, endMinutes, 0, 0)
              ),
            });
          }

          if (interuption_found === false) {
            avail_days.push({
              id: day.id,
              startTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(startHours, startMinutes, 0, 0)
              ),
              endTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(endHours, endMinutes, 0, 0)
              ),
            });
          }
        }
      }

      processDays(days_arr);
      setAvailableTimeslots(avail_days);
      setLoading(false);
    });
  }, []);

  const handleTimeslotClicked = (startTimeEventEmit) => {
    startTimeEventEmit.resetDate();
    startTimeEventEmit.resetSelectedTimeState();
    setTimeChosen(true);
    setSelectedDate(format(startTimeEventEmit.startTime, "yyyy-MM-dd"));
    setSelectedTime(format(startTimeEventEmit.startTime, "HH:mm:ss"));

    let totalDuration = 0;

    if (appointment.duration == 1) {
      totalDuration = 15;
    } else if (appointment.duration == 2) {
      totalDuration = 30;
    } else if (appointment.duration == 3) {
      totalDuration = 45;
    } else if (appointment.duration == 4) {
      totalDuration = 60;
    } else if (appointment.duration == 5) {
      totalDuration = 75;
    } else if (appointment.duration == 6) {
      totalDuration = 90;
    } else if (appointment.duration == 7) {
      totalDuration = 105;
    } else if (appointment.duration == 8) {
      totalDuration = 120;
    }

    if (selectedHours === "" && selectedMinutes === "") {
      totalDuration = totalDuration;
    } else {
      totalDuration =
        parseFloat(selectedHours === "" ? 0 : selectedHours) +
        parseFloat(selectedMinutes === "" ? 0 : selectedMinutes);
    }

    let endTime = new Date(
      new Date(new Date(startTimeEventEmit.startTime)).setMinutes(
        new Date(startTimeEventEmit.startTime).getMinutes() + totalDuration
      )
    );
    setSelectedToTime(format(endTime, "HH:mm:ss"));
  };

  async function getUnits(property_id) {
    setPropertyName(property_id.split("|")[1]);
    setPropertyId(property_id.split("|")[0]);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}unit_appointment_list/${
        property_id.split("|")[0]
      }`,
    }).then((response) => {
      setUnits(response.data);
    });
  }

  async function getBeds(unit_id) {
    setUnitName(unit_id.split("|")[1]);
    setUnitId(unit_id.split("|")[0]);
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}bed_list/${unit_id.split("|")[0]}`,
    }).then((response) => {
      setBeds(response.data);
    });
  }

  async function getBookingInfo(property_id, unit_id) {
    if (unit_id !== "0") {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "booking_for_appointment",
        data: { property_id: property_id, unit_id: unit_id },
      }).then((response) => {
        setBooking(response.data);
      });
    }
  }

  async function makeAppointment(unit_id) {
    var link = "create_custom_appointment";

    if (unit_id !== "0") {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "" + link,
        data: {
          name: booking?.user?.name,
          email: booking?.user.email,
          cell: booking?.user?.cell,
          property_id: propertyId,
          unit_id: unitId,
          booking_ref: booking?.booking_reference,
          description: description,
          appointment_url: appointment.url,
          booked_date: selectedDate,
          booked_time: selectedTime,
          time_to: selectedToTime,
          appointment_id: appointment.id,
          booking_id: booking?.id,
          user_id: booking?.user?.id,
          general: 0,
        },
      }).then((response) => {
        props.getBookings(props.property_id);
        setTimeScheduled(true);
        dispatch(closeModal());
      });
    } else {
      await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "" + link,
        data: {
          property_id: propertyId,
          description: description,
          appointment_url: appointment.url,
          booked_date: selectedDate,
          booked_time: selectedTime,
          time_to: selectedToTime,
          appointment_id: appointment.id,
          general: 1,
        },
      }).then((response) => {
        props.getBookings(props.property_id);
        setTimeScheduled(true);
        dispatch(closeModal());
      });
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Panel className="text-center">
                      <p className="text-lg font-bold">
                        Create a new {appointment.name}
                      </p>

                      {completeStep1 === false ? (
                        <>
                          <div className="flex mt-5">
                            <select
                              name="property_id"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                              onChange={(v) => getUnits(v.target.value)}
                            >
                              <option value="0" selected="0" disabled>
                                Property
                              </option>

                              {properties &&
                                properties.length > 0 &&
                                properties.map((p) => (
                                  <>
                                    {current_staff.building_manager === 0 ||
                                    (current_staff.building_manager === 1 &&
                                      current_staff.bm_property_id ===
                                        p.value) ? (
                                      <>
                                        <option
                                          key={p.value}
                                          value={p.value + "|" + p.label}
                                        >
                                          {p.label}
                                        </option>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                            </select>

                            <select
                              name="unit_id"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              onChange={(v) => getBeds(v.target.value)}
                            >
                              <option value="0" selected="0" disabled>
                                Unit
                              </option>
                              <option value="0">Building General</option>

                              {units &&
                                units.length > 0 &&
                                units.map((u) => (
                                  <option
                                    key={u.value}
                                    value={u.value + "|" + u.label}
                                  >
                                    {u.label}
                                  </option>
                                ))}
                            </select>

                            {process.env.REACT_APP_COLOUR === "UC" ? (
                              <>
                                <select
                                  name="bed_id"
                                  ref={filterProp}
                                  className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                                  onChange={(v) => {
                                    setBedName(v.target.value.split("|")[1]);
                                    setBedId(v.target.value.split("|")[0]);
                                  }}
                                >
                                  <option value="0" selected="0" disabled>
                                    Bed
                                  </option>

                                  {beds &&
                                    beds.length > 0 &&
                                    beds.map((b) => (
                                      <option
                                        key={b.value}
                                        value={b.value + "|" + b.label}
                                      >
                                        {b.label}
                                      </option>
                                    ))}
                                </select>
                              </>
                            ) : (
                              ""
                            )}

                            <select
                              name="duration_hours"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              onChange={(v) => setSelectedHours(v.target.value)}
                            >
                              <option value="0" selected="0" disabled>
                                Hours
                              </option>

                              {hours &&
                                hours.length > 0 &&
                                hours.map((u) => (
                                  <option key={u.value} value={u.value}>
                                    {u.label}
                                  </option>
                                ))}
                            </select>

                            <select
                              name="duration_minutes"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2"
                              onChange={(v) =>
                                setSelectedMinutes(v.target.value)
                              }
                            >
                              <option value="0" selected="0" disabled>
                                Minutes
                              </option>

                              {minutes &&
                                minutes.length > 0 &&
                                minutes.map((u) => (
                                  <option key={u.value} value={u.value}>
                                    {u.label}
                                  </option>
                                ))}
                            </select>

                            <CustomButton
                              text="select a time"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                setCompleteStep1(true);
                                getBookingInfo(propertyId, unitId);
                              }}
                              disabled={
                                propertyName == "" || unitName == ""
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="text-left">
                          <p>
                            <b>Property:</b> {propertyName}
                          </p>
                          {unitId === "0" ? (
                            <p>
                              <b>Building General</b>
                            </p>
                          ) : (
                            <>
                              <p>
                                <b>Unit:</b> {unitName}
                              </p>
                              {process.env.REACT_APP_COLOUR === "UC" ? (
                                <p>
                                  <b>Bed:</b> {bedName}
                                </p>
                              ) : (
                                ""
                              )}

                              <br />
                              <p>
                                <b>Name:</b>{" "}
                                {booking?.user?.name ? booking?.user?.name : ""}{" "}
                                {booking?.user?.surname
                                  ? booking?.user?.surname
                                  : ""}
                              </p>
                              <p>
                                <b>Email:</b>{" "}
                                {booking?.user?.email
                                  ? booking?.user?.email
                                  : ""}
                              </p>
                              <p>
                                <b>Cell:</b>{" "}
                                {booking?.user?.cell ? booking?.user?.cell : ""}{" "}
                              </p>
                              <p>
                                <b>Booking reference:</b>{" "}
                                {booking?.booking_reference
                                  ? booking?.booking_reference
                                  : ""}{" "}
                              </p>
                            </>
                          )}

                          {timeChosen === false && timeScheduled === false ? (
                            <>
                              <ScheduleMeeting
                                eventStartTimeSpreadInMinutes={
                                  appointment.minute_break
                                }
                                borderRadius={10}
                                primaryColor={
                                  process.env.REACT_APP_COLOUR === "UC"
                                    ? "#EE355C"
                                    : "#9F7D38"
                                }
                                eventDurationInMinutes={
                                  selectedHours === "" && selectedMinutes === ""
                                    ? appointment.duration === 1
                                      ? 15
                                      : appointment.duration === 2
                                      ? 30
                                      : appointment.duration === 3
                                      ? 45
                                      : appointment.duration === 4
                                      ? 60
                                      : appointment.duration === 5
                                      ? 75
                                      : appointment.duration === 6
                                      ? 90
                                      : appointment.duration === 7
                                      ? 105
                                      : appointment.duration === 8
                                      ? 120
                                      : 30
                                    : parseFloat(
                                        selectedHours === "" ? 0 : selectedHours
                                      ) +
                                      parseFloat(
                                        selectedMinutes === ""
                                          ? 0
                                          : selectedMinutes
                                      )
                                }
                                availableTimeslots={availableTimeslots}
                                onStartTimeSelect={handleTimeslotClicked}
                                startTimeListStyle="scroll-list"
                              />
                            </>
                          ) : (
                            <>
                              <br />
                              <p>
                                <b>Time:</b> {selectedDate} @ {selectedTime}
                              </p>
                              <textarea
                                placeholder="Please share anything that will help prepare for the appointment."
                                className="border w-96 mt-2 mb-2 rounded"
                                onChange={(v) => setDescription(v.target.value)}
                              ></textarea>
                              <br />
                              <p>
                                <b>
                                  Attachments can be added after the appointment
                                  has been scheduled*
                                </b>
                              </p>
                              <br />
                              <CustomButton
                                text="Schedule Appointment"
                                onClick={() => makeAppointment(unitId)}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </Dialog.Panel>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
