import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";

export default function ModalPropFloor(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [floor, setFloor] = useState({
    id: props.id > 0 ? props.id : 0,
    property_id: props.property_id,
    name: "",
    gender: 0,
    year: 0,
    map_upload: "",
  });

  useEffect(async () => {
    setLoading(true);

    if (!loading && props.id > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_floor/${props.id}`,
      })
        .then((response) => {
          setFloor(response.data);

          setLoading(false);
        })
        .catch((error) => {
          alert("floor load failed");
        });
    }
  }, []);

  const options_gender = [
    { value: 0, label: "Specified by Floor" },
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      {props.id > 0 ? "Edit" : "Add"} Floor
                    </Dialog.Title>

                    <Formik
                      initialValues={floor}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}save_floor`,
                          data: values,
                        })
                          .then((response) => {
                            window.location.reload();
                          })
                          .catch((error) => {
                            alert("floor save failed");
                          });

                        setLoading(false);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <div className="flex space-x-4">
                            <div className="w-1/3">
                              <Field
                                name="name"
                                type="text"
                                as={SettingsTextInput}
                                label="Floor Name"
                              />
                            </div>

                            <div className="w-1/3">
                              <SettingsSelect
                                label="Gender"
                                name="gender"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.gender}
                                touched={touched.gender}
                                error={errors.gender}
                                options={options_gender}
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="year"
                                type="text"
                                as={SettingsTextInput}
                                label="Year"
                              />
                            </div>
                          </div>

                          <div className="flex space-x-6">
                            <SubmitButton
                              name="Save"
                              disabled={!isValid || isSubmitting || !dirty}
                              isSubmitting={isSubmitting}
                              text="Save"
                            />
                            {/* <CustomButton
                              type="alert"
                              text="Delete"
                              onClick={() => alert("delete...")}
                            /> */}
                            <CustomButton
                              type="secondary"
                              text="Close"
                              onClick={() => dispatch(closeModal())}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
