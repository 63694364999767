import {
    GET_FLAGGED_IDS_STARTED,
    GET_FLAGGED_IDS_SUCCESS,
    GET_FLAGGED_IDS_FAILED,
    SET_BOOKING_LIST,
    GET_BOOKING_FILES_STARTED,
    GET_BOOKING_FILES_SUCCESS,
    GET_BOOKING_FILES_FAILED,
    GET_BOOKING_STARTED,
    GET_BOOKING_SUCCESS,
    GET_BOOKING_FAILED,
} from "./bookingConstants";

import axios from "axios";

export function setBookingList(booking_list) {
    return (dispatch) => {
        dispatch({
            type: SET_BOOKING_LIST,
            payload: {
                booking_list,
            },
        });
    };
}

export const getBooking = (id, user_id=0, active_booking_id) => {
    return (dispatch) => {
        dispatch(getBookingStarted());

        let url = 'booking';
        let extention = "";

        // console.log("active_booking_id", active_booking_id)

        if (id === 0 && user_id > 0) {
            url = 'booking_user_id';
            id = user_id;
            extention = "/"+active_booking_id

        }

        if (id) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}${url}/${id}${extention}`,
            })
                .then((response) => {
                    //Fix Assigned To
                    if (response.data.assigned_to && response.data.assigned_to !== "") {
                        response.data.assigned_to = response.data.assigned_to.split(",");
                    }

                    dispatch(getBookingStartedSuccess(response.data));
                })
                .catch((error) => {
                    dispatch(getBookingStartedFailed(error.data));
                });
        } else {
            dispatch(getBookingStartedSuccess());
        }
    };
};

export function getBookingStarted() {
    return {
        type: GET_BOOKING_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const getBookingStartedSuccess = (booking) => {
    return {
        type: GET_BOOKING_SUCCESS,
        payload: {
            booking,
        },
    };
};

const getBookingStartedFailed = (error) => {
    return {
        type: GET_BOOKING_FAILED,
        payload: {
            error,
        },
    };
};

export const getBookingFiles = (id) => {
    return (dispatch) => {
        dispatch(getBookingFilesStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}files/${id}`,
        })
            .then((response) => {
                dispatch(getBookingFilesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getBookingFilesFailed(error.data));
            });
    };
};

export const getFlaggedIds = (id) => {
    return (dispatch) => {
        dispatch(getFlaggedIdsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}flagged_ids`,
        })
            .then((response) => {
                dispatch(getFlaggedIdsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getFlaggedIdsFailed(error.data));
            });
    };
};

export function getFlaggedIdsStarted() {
    return {
        type: GET_FLAGGED_IDS_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const getFlaggedIdsSuccess = (ids) => {
    return {
        type: GET_FLAGGED_IDS_SUCCESS,
        payload: {
            ids,
        },
    };
};

const getFlaggedIdsFailed = (error) => {
    return {
        type: GET_FLAGGED_IDS_FAILED,
        payload: {
            error,
        },
    };
};

export function getBookingFilesStarted() {
    return {
        type: GET_BOOKING_FILES_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const getBookingFilesSuccess = (files) => {
    return {
        type: GET_BOOKING_FILES_SUCCESS,
        payload: {
            files,
        },
    };
};

const getBookingFilesFailed = (error) => {
    return {
        type: GET_BOOKING_FILES_FAILED,
        payload: {
            error,
        },
    };
};
