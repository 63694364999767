import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { signInUser, signInToken, verifyAuth } from "../../store/auth/authActions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import CustomButton from "../../components/Helpers/CustomButton";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { toast } from "react-toastify";

export default function ForgetPassword({ history }) {
    const [forgot, setForgot] = useState(false);
    const dispatch = useDispatch();
    let location = useLocation();
    const { current_staff } = useSelector((state) => state.auth);

    useEffect(() => {
        if (location.search) {
            let token = location.search.replace("?token=", "");

            dispatch(signInToken({ token: token }));

            history.push("/booking/unassigned");
        }
    });

    return (
        <div className="min-h-screen flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 xl:flex-none xl:px-24 ">
                <div className="mx-auto w-full max-w-sm xl:w-96">
                    <div>
                        <img
                            className="h-24 w-auto"
                            src={process.env.REACT_APP_COLOUR === "UC" ? "/images/logo_uc.svg" : process.env.REACT_APP_COLOUR === "QL" ? "/images/logo.svg" : "/images/logo-qholdings-v2.png"}
                            alt="Quorom Properties"
                        />
                        <h2 className="mt-6 text-2xl font-bold text-gray-900">Forgotten your password?</h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <Formik
                                hidden={forgot === true ? true : false}
                                initialValues={{
                                    email: "",
                                }}
                                validate={(values) => {
                                    const errors = {};
                                    if (!values.email) {
                                        errors.email = "Required";
                                    }
                                    return errors;
                                }}
                                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                    await axios({
                                        method: "post",
                                        url: `${process.env.REACT_APP_API_URL}forget-password`,
                                        data: { email: values.email },
                                    })
                                        .then(() => {
                                            toast(<NotificationToast title={"Password reset requested updated"} message={"You have been sent an email with a unique link to reset your password"} />);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            console.log(error.response);
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                            resetForm();
                                        });
                                }}
                            >
                                {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Field name="email" as={SettingsTextInput} type="email" label="Email Address" />

                                        {errors.auth ? <p className="my-4 text-medium text-red-500">{errors.auth}</p> : ""}

                                        <div className="py-8 flex space-x-2">
                                            <SubmitButton disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} type="submit" name="submit" text="Request Password Reset" />
                                            <Link to={"/adminlogin"}>
                                                <CustomButton text="Back to login" />
                                            </Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden xl:block relative w-0 flex-1">
                <img className="absolute inset-0 h-full w-full object-cover" src={process.env.REACT_APP_COLOUR === "UC" ? "/images/login_bg_uc.jpg" : "/images/login_bg.jpg"} alt="Login Background" />
            </div>
        </div>
    );
}
