import React, { useEffect } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import ProfilePicture from "./ProfilePicture";

export default function StaffOnline({ type, staff, styling }) {
    // const { staff } = useSelector((state) => state.auth);

    let staffNew = [];

    if (staff && staff.length > 0) {
        for (const st of staff) {
            var now = new Date();
            var end = new Date(st.last_seen);

            var diff = parseInt((Math.abs(end.getTime() - now.getTime()) / (1000 * 60)) % 60);
            var diffdays = Math.ceil(Math.abs(now - end) / (1000 * 60 * 60 * 24));

            if ((type === "online" && diffdays === 1 && diff < 15) || (type === "offline" && diffdays >= 1 && diff >= 15) || (type === "offline" && st.last_seen == null)) {
                staffNew.push(st);
            }
        }
    }

    return (
        <>
            {staffNew &&
                staffNew.length > 0 &&
                staffNew.map((sender) => (
                    <div key={sender.id}>{sender ? <ProfilePicture id={sender.id} size="40" styling={styling} /> : <img src="/images/profile.png" className="rounded-full" width="40" />}</div>
                ))}
        </>
    );
}
