import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTimes, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { Formik, Form, Field } from "formik";
import { ScheduleMeeting } from "react-schedule-meeting";
import {
  format,
  addDays,
  getWeekOfMonth,
  getDay,
  addHours,
  roundToNearestMinutes,
  isEqual,
  isAfter,
  isBefore,
} from "date-fns";
import SettingsSelect from "../Forms/SettingsSelect";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import FileUpload from "../Forms/FileUpload";
import Select from "react-select";

export default function ModalBMAppointment(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_staff, staff } = useSelector((state) => state.auth);
  const [appointmentNotFound, setAppointmentNotFound] = useState(false);
  const { managers } = useSelector((state) => state.managers);
  const [managerOptions, setManagerOptions] = useState([]);
  const [activeAppointment, setActiveAppointment] = useState([]);
  const [appointment, setAppointment] = useState([]);
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeChosen, setTimeChosen] = useState(false);
  const [timeScheduled, setTimeScheduled] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedToTime, setSelectedToTime] = useState("");
  const filterProp = useRef();

  const [chosenBuilding, setChosenBuilding] = useState("");
  const [chosenUnit, setChosenUnit] = useState("");
  const [chosenBed, setChosenBed] = useState("");
  const [completeStep1, setCompleteStep1] = useState(false);
  const { properties } = useSelector((state) => state.properties);
  const [units, setUnits] = useState([]);
  const [beds, setBeds] = useState([]);

  const [propertyName, setPropertyName] = useState("");
  const [unitName, setUnitName] = useState("");
  const [bedName, setBedName] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [bedId, setBedId] = useState("");

  const [description, setDescription] = useState("");

  const [booking, setBooking] = useState([]);

  const [hours, setHours] = useState([
    { value: 0, label: "0" },
    { value: 60, label: "1" },
    { value: 120, label: "2" },
    { value: 180, label: "3" },
    { value: 240, label: "4" },
    { value: 300, label: "5" },
    { value: 360, label: "6" },
  ]);
  const [minutes, setMinutes] = useState([
    { value: 0, label: "0" },
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 25, label: "25" },
    { value: 30, label: "30" },
    { value: 35, label: "35" },
    { value: 40, label: "40" },
    { value: 45, label: "45" },
    { value: 50, label: "50" },
    { value: 55, label: "55" },
  ]);

  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinutes, setSelectedMinutes] = useState("");

  const [selectedManagers, setSelectedManagers] = useState([]);

  useEffect(async () => {
    let manList = [];
    managers.forEach(function (manager) {
      manList.push({
        value: manager.id,
        label: manager.name + " " + manager.surname + " - " + manager.email,
      });
    });
    setManagerOptions(manList);

    getTimes();
  }, []);

  async function getTimes() {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}bm_active_appointments`,
    }).then((response) => {
      let fullData = response.data;

      let days_arr = [];
      let days_full_arr = [];
      let i = 0;
      while (i < 15) {
        days_arr.push({ id: i, start: "05:00:00", end: "23:00:00" });

        i++;
      }

      async function processFullDays(array) {
        for (const full of array) {
          days_full_arr.push({
            startTime: new Date(full.booked_date + " " + full.booked_time),
            endTime: new Date(full.booked_date + " " + full.time_to),
            send_to: full.send_to,
          });
        }
      }

      // days_full_arr.push({
      //     startTime: new Date(format(new Date(), "yyyy-MM-dd")+" "+format(new Date(), "HH:mm")),
      //     endTime: new Date(format(new Date(), "yyyy-MM-dd")+" "+format(roundToNearestMinutes(addHours(new Date(), 2), {nearestTo: 30}), "HH:mm"))
      // })

      processFullDays(fullData);

      let avail_days = [];

      async function processDays(array) {
        for (const day of array) {
          const [startHours, startMinutes] = day.start.split(":");
          const [endHours, endMinutes] = day.end.split(":");

          let interuption_found = false;
          let first_start = new Date(
            new Date(
              new Date().setDate(new Date().getDate() + day.id)
            ).setHours(startHours, startMinutes, 0, 0)
          );
          let default_start = new Date(
            new Date(
              new Date().setDate(new Date().getDate() + day.id)
            ).setHours(startHours, startMinutes, 0, 0)
          );

          async function processArray(array) {
            for (const full of array) {
              let includes = false;
              selectedManagers.map((manager) => {
                if (full.send_to.includes(manager.value)) {
                  includes = true;
                }
              });

              if (
                format(new Date(full.startTime), "yy-MM-dd") ===
                  format(
                    new Date(
                      new Date(
                        new Date().setDate(new Date().getDate() + day.id)
                      )
                    ),
                    "yy-MM-dd"
                  ) &&
                includes === true
              ) {
                interuption_found = true;
                avail_days.push({
                  id: day.id,
                  startTime: first_start,
                  endTime: full.startTime,
                });
                first_start = full.endTime;
              }
            }
          }

          processArray(days_full_arr);

          if (first_start.getTime() !== default_start.getTime()) {
            avail_days.push({
              id: day.id,
              startTime: first_start,
              endTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(endHours, endMinutes, 0, 0)
              ),
            });
          }

          if (interuption_found === false) {
            avail_days.push({
              id: day.id,
              startTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(startHours, startMinutes, 0, 0)
              ),
              endTime: new Date(
                new Date(
                  new Date().setDate(new Date().getDate() + day.id)
                ).setHours(endHours, endMinutes, 0, 0)
              ),
            });
          }
        }
      }

      processDays(days_arr);
      setAvailableTimeslots(avail_days);
      setLoading(false);
    });
  }

  const handleTimeslotClicked = (startTimeEventEmit) => {
    startTimeEventEmit.resetDate();
    startTimeEventEmit.resetSelectedTimeState();
    setTimeChosen(true);
    setSelectedDate(format(startTimeEventEmit.startTime, "yyyy-MM-dd"));
    setSelectedTime(format(startTimeEventEmit.startTime, "HH:mm:ss"));

    let totalDuration =
      parseFloat(selectedHours === "" ? 0 : selectedHours) +
      parseFloat(selectedMinutes === "" ? 0 : selectedMinutes);

    let endTime = new Date(
      new Date(new Date(startTimeEventEmit.startTime)).setMinutes(
        new Date(startTimeEventEmit.startTime).getMinutes() + totalDuration
      )
    );
    setSelectedToTime(format(endTime, "HH:mm:ss"));
  };

  async function makeAppointment() {
    await axios({
      method: "post",
      url: process.env.REACT_APP_API_URL + "create_bm_meeting",
      data: {
        description: description,
        booked_date: selectedDate,
        booked_time: selectedTime,
        time_to: selectedToTime,
        selectedManagers: selectedManagers,
      },
    }).then((response) => {
      // props.getBookings(props.property_id);
      setTimeScheduled(true);
      dispatch(closeModal());
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* overflow-hidden */}
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Panel className="text-center">
                      <p className="text-lg font-bold">
                        Create a new {appointment.name}
                      </p>

                      {completeStep1 === false ? (
                        <>
                          <div className="flex mt-5 items-end">
                            <select
                              name="duration_hours"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2 flex-none"
                              onChange={(v) => setSelectedHours(v.target.value)}
                            >
                              <option value="0" selected="0" disabled>
                                Hours
                              </option>
                              {hours &&
                                hours.length > 0 &&
                                hours.map((u) => (
                                  <option key={u.value} value={u.value}>
                                    {u.label}
                                  </option>
                                ))}
                            </select>

                            <select
                              name="duration_minutes"
                              ref={filterProp}
                              className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-2 flex-none"
                              onChange={(v) =>
                                setSelectedMinutes(v.target.value)
                              }
                            >
                              <option value="0" selected="0" disabled>
                                Minutes
                              </option>
                              {minutes &&
                                minutes.length > 0 &&
                                minutes.map((u) => (
                                  <option key={u.value} value={u.value}>
                                    {u.label}
                                  </option>
                                ))}
                            </select>

                            <div
                              classname="flex-initial"
                              style={{ width: "300px", marginLeft: "10px" }}
                            >
                              <Select
                                options={managerOptions}
                                isMulti
                                name="managers"
                                onChange={(v) => setSelectedManagers(v)}
                              />
                            </div>

                            <CustomButton
                              text="select a time"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                setCompleteStep1(true);
                                getTimes();
                              }}
                              disabled={
                                selectedHours == "" && selectedMinutes == ""
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="text-left">
                          <p>
                            <b>
                              <u>Managers</u>
                            </b>
                          </p>
                          {selectedManagers && selectedManagers.length > 0 ? (
                            <>
                              {selectedManagers.map((manager) => (
                                <>
                                  <p>
                                    <b>Attending: </b> {manager.label}
                                  </p>
                                </>
                              ))}
                            </>
                          ) : (
                            ""
                          )}

                          {timeChosen === false && timeScheduled === false ? (
                            <>
                              <ScheduleMeeting
                                eventStartTimeSpreadInMinutes={0}
                                borderRadius={10}
                                primaryColor={
                                  process.env.REACT_APP_COLOUR === "UC"
                                    ? "#EE355C"
                                    : "#9F7D38"
                                }
                                eventDurationInMinutes={
                                  parseFloat(
                                    selectedHours === "" ? 0 : selectedHours
                                  ) +
                                  parseFloat(
                                    selectedMinutes === "" ? 0 : selectedMinutes
                                  )
                                }
                                availableTimeslots={availableTimeslots}
                                onStartTimeSelect={handleTimeslotClicked}
                                startTimeListStyle="scroll-list"
                              />
                            </>
                          ) : (
                            <>
                              <br />
                              <p>
                                <b>Time:</b> {selectedDate} @ {selectedTime}
                              </p>
                              <textarea
                                placeholder="Please share anything that will help prepare for the appointment."
                                className="border w-96 mt-2 mb-2 rounded"
                                onChange={(v) => setDescription(v.target.value)}
                              ></textarea>
                              <br />
                              <p>
                                <b>
                                  Attachments can be added after the appointment
                                  has been scheduled*
                                </b>
                              </p>
                              <br />
                              <CustomButton
                                text="Schedule Meeting"
                                onClick={() => makeAppointment()}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </Dialog.Panel>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
