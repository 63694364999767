import {
    GET_SETTINGS_STARTED,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILED,
} from "./settingsConstants";

const initialState = {
    settings: {},
    loading: false,
    error: null,
};

export default function settingsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_SETTINGS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                settings: payload.settings.settings,
                years: payload.settings.years,
            };
        case GET_SETTINGS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
