import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Properties1Page() {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);

    useEffect(async () => {
        setLoading(true);

        if (!loading) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}properties`,
            })
                .then((response) => {
                    setProperties(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    alert("properties failed");
                });
        }

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, [properties]);

    let colourborder = "uc_prop_border1";

    if (process.env.REACT_APP_COLOUR == "QL") {
        colourborder = "ql_prop_border1";
    }

    return (
        <>
            <div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <div className="flex flex-wrap">
                    {properties?.length > 0 &&
                        properties?.map((p) => (
                            <div className="w-72 mx-6 relative h-80 rounded-xl shadow-md mb-7" key={p.value}>
                                <h2 className={`absolute top-8 left-0 z-20 bg-dark_background h-14 w-44 flex items-center justify-center text-white uppercase font-bold ${colourborder}`}>{p.label}</h2>

                                <div
                                    className="z-10 absolute top-3 right-3 bottom-3 left-3 flex items-end justify-center p-5 rounded-xl"
                                    style={{ backgroundImage: "url('/images/" + p.background_image + "')", backgroundSize: "cover" }}
                                >
                                    <Link
                                        className="text-white uppercase px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                        to={"/properties/step2/" + p.value}
                                    >
                                        VIEW PROPERTY
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
}
