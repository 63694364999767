import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { faFile, faFileSignature, faHouseCircleXmark, faSave, faVacuum } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import { openModal } from "../../store/modal/modalReducer";
import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import { getBooking } from "../../store/booking/bookingActions";
import SettingsSwitch from "../Forms/SettingsSwitch";
import QuickFormDate from "../Forms/QuickFormDate";

export default function GridMoveIn({ setDoc, db }) {
    const [loading, setLoading] = useState(true);
    const { current_staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const dispatch = useDispatch();
    const [hasInFiles, setHasInFiles] = useState(false);
    const [inFiles, setInFiles] = useState(false);
    const [hasOutFiles, setHasOutFiles] = useState(false);
    const [outFiles, setOutFiles] = useState(false);

    useEffect(async () => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}check_move_files`,
            data: { userId: current_booking?.user.id, booking_id: current_booking.id },
        })
            .then((response) => {
                // console.log(response.data);
                if (response.data[0] === 1) {
                    setHasInFiles(true);
                    setInFiles(response.data[1]);
                }

                if (response.data[2] === 1) {
                    setHasOutFiles(true);
                    setOutFiles(response.data[3]);
                }
            })
            .catch((error) => {
                // alert("failed");
            });
    }, []);

    async function regenInForm(userId) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}regen_in`,
            data: { userId: userId },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                alert("failed");
            });
    }

    async function regenOutForm(userId) {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}regen_out`,
            data: { userId: userId },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                alert("failed");
            });
    }

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            {current_booking?.files && current_booking?.files.length > 0 ? (
                <>
                    {current_booking?.files.map((file) => (
                        <>
                            {file.file_type == "move in form" || file.file_type == "move out form" || file.file_type == "cleaning form" ? (
                                <>
                                    <div
                                        className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                        onClick={() =>
                                            dispatch(
                                                openModal({
                                                    modal_type: "ModalViewFiles",
                                                    modal_props: {
                                                        files: [
                                                            {
                                                                id: file.id,
                                                            },
                                                        ],
                                                    },
                                                })
                                            )
                                        }
                                    >
                                        <p
                                            className={
                                                process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "UC"
                                                    ? file.dirty == 0
                                                        ? "text-center  bg-green-600 text-white"
                                                        : "text-center bg-red-600 text-white"
                                                    : "text-center"
                                            }
                                        >
                                            {file.name}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </>
                    ))}

                    {hasInFiles === true ? (
                        <>
                            <CustomButton
                                text="Move In Attachments"
                                styling="w-full mt-2"
                                icon={faFile}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalMoveFiles",
                                            modal_props: { files: inFiles },
                                        })
                                    )
                                }
                            />
                        </>
                    ) : (
                        ""
                    )}

                    {hasOutFiles === true ? (
                        <>
                            <CustomButton
                                text="Move Out Attachments"
                                styling="w-full mt-2"
                                icon={faFile}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalMoveFiles",
                                            modal_props: { files: outFiles },
                                        })
                                    )
                                }
                            />
                        </>
                    ) : (
                        ""
                    )}

                    {hasInFiles === true || hasOutFiles === true ? (
                        <>
                            <br />
                            <hr />
                            <br />
                        </>
                    ) : (
                        ""
                    )}
                </>
            ) : (
                ""
            )}

            {(process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0) || process.env.REACT_APP_COLOUR === "QL" ? (
                <>
                    {process.env.REACT_APP_COLOUR === "QL" ? (
                        <>
                            <CustomButton
                                text="Customise TBA Pricing"
                                styling="w-full mt-2"
                                icon={faFileSignature}
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalCustomTba",
                                        })
                                    )
                                }
                            />

                            <br />
                        </>
                    ) : (
                        ""
                    )}

                    {current_staff.id == 1 ? (
                        <>
                            <CustomButton text="Re-print In" styling="w-full mt-2" icon={faFileSignature} onClick={() => regenInForm(current_booking?.user.id)} />
                            <CustomButton text="Re-print Out" styling="w-full mt-2" icon={faFileSignature} onClick={() => regenOutForm(current_booking?.user.id)} />
                        </>
                    ) : (
                        ""
                    )}

                    <hr />

                    <Formik
                        initialValues={{
                            in_inspection: current_booking.inspection,
                            first_month_paid: current_booking.first_month_paid,
                            first_month_paid_at: current_booking.first_month_paid_at,
                            in_inspection_at: current_booking.inspection_date,
                            key_issued: current_booking.key_issued,
                            key_issued_at: current_booking.key_issued_date,
                            biometrics_done: current_booking.biometrics,
                            biometrics_done_at: current_booking.biometrics_date,
                            move_out: current_booking.move_out,
                            move_out_at: current_booking.move_out_at,
                            pre_move_out: current_booking.pre_move_out,
                            pre_move_out_at: current_booking.pre_move_out_at,
                            cleaned: current_booking.cleaned,
                            cleaned_at: current_booking.cleaned_at,
                        }}
                        enableReinitialize
                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                            values.booking_id = current_booking.id;
                            values.current_staff = current_staff;

                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}user_move_in`,
                                data: values,
                            })
                                .then(() => {
                                    dispatch(getBooking(current_booking.id));
                                    toast(<NotificationToast title="Success" message="Data has been updated sucessfully" />);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast(<NotificationToast title="Something went wrong" message="" type="error" />);
                                });

                            setSubmitting(false);
                        }}
                    >
                        {({ values, touched, errors, handleSubmit, setFieldValue, setFieldTouched, isValid, isSubmitting, dirty }) => (
                            <Form onSubmit={handleSubmit} className="rounded-3xl">
                                {process.env.REACT_APP_COLOUR === "UC" || process.env.REACT_APP_COLOUR === "QL" ? (
                                    <>
                                        <p className="text-xl">Move In</p>
                                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2 mb-5">
                                            <Field name="in_inspection" as={SettingsSwitch} label="In-inspection" description=" " />
                                            <Field name="in_inspection_at" type="text" as={QuickFormDate} label="At" />

                                            {process.env.REACT_APP_COLOUR === "UC" ? (
                                                <>
                                                    <Field name="first_month_paid" as={SettingsSwitch} label="First Month Paid" description=" " />
                                                    <Field name="first_month_paid_at" type="text" as={QuickFormDate} label="At" />

                                                    <Field name="key_issued" as={SettingsSwitch} label="Key issued" description=" " />
                                                    <Field name="key_issued_at" type="text" as={QuickFormDate} label="At" />

                                                    <Field name="biometrics_done" as={SettingsSwitch} label="Biometrics done" description=" " />
                                                    <Field name="biometrics_done_at" type="text" as={QuickFormDate} label="At" />
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>

                                        {/* {!current_staff.building_manager && process.env.REACT_APP_COLOUR === "UC" ? 
                                            <>
                                                <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Update" style={{ width: "100%" }}/>
                                                <br/><br/>
                                            </>
                                        : "" } */}
                                    </>
                                ) : (
                                    ""
                                )}

                                {process.env.REACT_APP_COLOUR === "QL" ? (
                                    <>
                                        <br />
                                        <hr />
                                        <p className="text-xl">Pre Move Out</p>

                                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2 mb-5">
                                            <Field name="pre_move_out" as={SettingsSwitch} label="Pre out inspection" description=" " />
                                            <Field name="pre_move_out_at" type="text" as={QuickFormDate} label="At" />
                                        </div>

                                        {process.env.REACT_APP_COLOUR === "UC" ? (
                                            <>
                                                {!current_staff.building_manager ? (
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={faSave}
                                                        text="Update"
                                                        style={{ width: "100%" }}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <CustomButton
                                            text="Pre Move out Form"
                                            styling="w-full mt-2"
                                            icon={faHouseCircleXmark}
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: process.env.REACT_APP_COLOUR === "QL" ? "ModalBMCustom" : "ModalBMCustomUC",
                                                        modal_props: {
                                                            pre_move_out: true,
                                                        },
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    ""
                                )}

                                {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "UC" ? (
                                    <>
                                        <br />
                                        <hr />
                                        <p className="text-xl">Move Out</p>

                                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2 mb-5">
                                            <Field name="move_out" as={SettingsSwitch} label="Out-inspection" description=" " />
                                            <Field name="move_out_at" type="text" as={QuickFormDate} label="At" />
                                        </div>

                                        {process.env.REACT_APP_COLOUR === "UC" ? (
                                            <>
                                                {!current_staff.building_manager ? (
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={faSave}
                                                        text="Update"
                                                        style={{ width: "100%" }}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        <CustomButton
                                            text="Move out Form"
                                            styling="w-full mt-2"
                                            icon={faHouseCircleXmark}
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: process.env.REACT_APP_COLOUR === "QL" ? "ModalBMCustom" : "ModalBMCustomUC",
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    ""
                                )}

                                {process.env.REACT_APP_COLOUR === "QL" ? (
                                    <>
                                        <br />
                                        <hr />
                                        <p className="text-xl">Cleaning</p>
                                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2 mb-5">
                                            <Field name="cleaned" as={SettingsSwitch} label="Cleaned" description=" " />
                                            <Field name="cleaned_at" type="text" as={QuickFormDate} label="At" />
                                        </div>

                                        <CustomButton
                                            text="Cleaning Form"
                                            styling="w-full mb-2"
                                            icon={faVacuum}
                                            onClick={() =>
                                                dispatch(
                                                    openModal({
                                                        modal_type: "ModalCleaning",
                                                    })
                                                )
                                            }
                                        />

                                        {!current_staff.building_manager ? (
                                            <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Update" style={{ width: "100%" }} />
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </Form>
                        )}
                    </Formik>
                </>
            ) : (
                <>{process.env.REACT_APP_COLOUR === "UC" ? "No bed chosen!" : ""}</>
            )}
        </div>
    );
}
