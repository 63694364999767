import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ReportEmailLogs() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    useEffect(async () => {
        setLoading(true);

        if (!loading) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}reports/email_logs`,
            })
                .then((response) => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    alert("report failed");
                });
        }

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, [data]);

    function MessageColour(id, platform) {
        if (platform === "note") {
            return "bg-yellow-200";
        } else if (platform === "audit") {
            return "bg-red-200";
        } else if (platform === "website") {
            return "bg-green-200";
        } else if (staff.find((e) => e.id === id)) {
            return "bg-blue-200";
        } else {
            return "bg-gray-200";
        }
    }

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Email Logs</h1>

                <div className="overflow-y-auto flex-col-reverse">
                    {data?.map((d) => (
                        <div key={d.id} className={classNames(staff.find((e) => e.id === d.from) ? "" : "flex-row-reverse", "flex")}>
                            <div className={classNames(MessageColour(d.from, d.platform), "p-4 rounded-md text-sm break-words mb-5 w-1/2")}>
                                <div>
                                    <strong>Received: </strong>
                                    {d.created_at}
                                    <br />
                                    <strong>From: </strong>
                                    {d.from}
                                    <br />
                                    <strong>To: </strong>
                                    {d.to}
                                    <br />
                                </div>
                                <div
                                    className="dangerousPFormat"
                                    dangerouslySetInnerHTML={{
                                        __html: d.message_html && d.message_html !== "" ? d.message_html : d.message,
                                    }}
                                />
                                {staff.find((e) => e.id === d.from) && d.files.length > 0 && (
                                    <>
                                        <p className="text-xs text-gray-800 mt-2">Uploaded the following file(s)</p>

                                        {d.files instanceof Array ? (
                                            d.files.map((file) => (
                                                <li key={file} className="text-xs text-gray-800">
                                                    {file}
                                                </li>
                                            ))
                                        ) : (
                                            <li className="text-xs text-gray-800">{d.files}</li>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
