import React, { useState } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import moment from "moment";

export default function ReportChatAudit() {
    const [loading, setLoading] = useState(false);
    const [auditLogs, setAuditLogs] = useState([]);

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Chat Audit</h1>

                <Formik
                    initialValues={{
                        booking_reference: "",
                        audit_type: "chat",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/get_audit_log`,
                            data: { booking_reference: values.booking_reference, audit_type: values.audit_type },
                        })
                            .then((response) => {
                                setAuditLogs(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid md:lg:grid-cols-3 gap-12 mt-5 mb-5 ">
                                    <div>
                                        <SettingsSelect
                                            label="What sort of report would you like to run?"
                                            name="audit_type"
                                            onChange={setFieldValue}
                                            value={values.audit_type}
                                            error={errors.audit_type}
                                            options={[
                                                { value: "chat", label: "Chat Audit" },
                                                { value: "user", label: "User Audit" },
                                            ]}
                                        />
                                    </div>
                                    <div>
                                        <SettingsTextInput
                                            label="Booking Reference"
                                            name="booking_reference"
                                            placeholder="Booking Reference"
                                            errors={errors.booking_reference}
                                            value={values.booking_reference}
                                        />
                                    </div>
                                </div>
                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="View Audit" />
                            </div>
                        </Form>
                    )}
                </Formik>

                <div className="mt-5">
                    <table className="w-full">
                        <thead>
                            <tr>
                                {/* <th className="text-left">ID</th> */}
                                <th width="200" className="text-left">
                                    Date
                                </th>
                                <th width="200" className="text-left">
                                    Romi User
                                </th>
                                <th width="200" className="text-left">
                                    Chat User
                                </th>
                                <th className="text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditLogs?.map((log, index) => (
                                <tr key={index}>
                                    {/* <td>{log.id}</td> */}
                                    <td>{moment(log.created_at).format("YYYY-MM-DD HH:MM:ss")}</td>
                                    <td>{log.created_by}</td>
                                    <td>{log.chat_by}</td>
                                    <td>
                                        {log.value_old ? (
                                            <>
                                                {log.key} changed from <strong>{log.value_old}</strong> to <strong>{log.value}</strong>
                                            </>
                                        ) : (
                                            <>
                                                {log.key} set as <strong>{log.value}</strong>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
