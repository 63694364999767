import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faExclamationCircle, faTimes, faSync } from "@fortawesome/pro-regular-svg-icons";
import { faLoader } from "@fortawesome/pro-solid-svg-icons";

export default function NotificationToast({ closeToast, toastProps, title, message, list, type }) {
    let modalIcon = faCheckCircle;
    let modalIconClass = "my-4 text-2xl ";

    // console.log( message );
    var error_message = list ? message.split(",") : message;

    switch (type) {
        case "error":
            modalIcon = faTimesCircle;
            modalIconClass += "text-red-500";
            break;

        case "warning":
            modalIcon = faExclamationCircle;
            modalIconClass += "text-yellow-500";
            break;

        case "loading":
            modalIcon = faLoader;
            modalIconClass += "animate-spin text-gray-400";
            break;

        default:
            modalIconClass += "text-green-500";
            break;
    }
    return (
        <div className="flex items-start">
            <div className="flex-shrink-0">
                <FontAwesomeIcon className={modalIconClass} aria-hidden="true" icon={modalIcon} />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900 capitalize">{title}</p>
                <div className="mt-1 text-sm font-sans text-gray-500 w-sm">
                    {Array.isArray(error_message) ? (
                        <>
                            <p>We require the following:</p>
                            <ul role="list" className="list-disc p-2 mx-auto w-full">
                                {Object.keys(error_message).map((key) => (
                                    <li key={key}>{error_message[key]}</li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <p>{error_message}</p>
                    )}
                </div>
            </div>
        </div>
    );
}
