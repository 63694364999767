import {
    GET_TAGS_STARTED,
    GET_TAGS_SUCCESS,
    GET_TAGS_FAILED,
} from "./tagConstants";

const initialState = {
    tags: {},
    tags_total: 0,
    loading: false,
    error: null,
};

export default function tagReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_TAGS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                tags: payload.tags.tags,
                tags_total: payload.tags.total,
            };
        case GET_TAGS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
