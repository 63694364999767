import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";

export default function ReportVendor() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    const vendor = [
        { value: 0, label: "All" },
        { value: -1, label: "No Vendor" },
        { value: 1, label: "Fundi" },
        { value: 2, label: "AE switch" },
        { value: 3, label: "Embizo" },
    ];

    const years = [
        { label: "All", value: 0 },
        { label: "2023", value: 1 },
        { label: "2024", value: 2 },
    ];

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                let responseData = [
                    { id: 0, value: 0, label: "All" },
                    { id: -1, value: -1, label: "No Building" },
                ];
                setProperties(responseData.concat(response.data));
            })
            .catch((error) => {
                alert("properties failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>NSFAS UJ Vendor Report</h1>

                <Formik
                    initialValues={{
                        year_id: 0,
                        property_id: 0,
                        vendor: 0,
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        console.log(values);

                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/vendor`,
                            responseType: "blob",
                            data: { year_id: values.year_id, property_id: values.property_id, vendor: values.vendor },
                        })
                            .then((response) => {
                                console.log(response.data);

                                const href = URL.createObjectURL(response.data);

                                const link = document.createElement("a");
                                link.href = href;
                                link.setAttribute("download", "NSFAS UJ Vendor.xlsx");
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect label="Year" name="year_id" onChange={setFieldValue} value={values.year_id} options={years} />
                                    <SettingsSelect label="What is your desired Building?" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />
                                    <SettingsSelect label="Vendor Status" name="vendor" onChange={setFieldValue} value={values.vendor} options={vendor} />
                                </div>

                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
