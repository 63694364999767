import { Field } from "formik";
import EmojiPicker from "./EmojiPicker";
import { Editor } from "react-draft-wysiwyg";
import { DraftTextEditor } from "./DraftTextEditor";

export default function SettingsTextArea({ emojis, wysiwyg, ...props }) {
    var textarea_class;
    const formik = (textarea_class = emojis ? "rounded-t-lg" : "rounded-lg");

    return (
        <div>
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.placeholder}
            </label>

            {wysiwyg === true ? (
                <div className="border border-gray-300 rounded-md shadow-sm z-20">
                    <Field {...props}>
                        {({ field }) => (
                            <div className="mt-1 block w-full py-2 pl-4 focus:outline-none !outline-none resize-none sm:text-sm bg-white">
                                <DraftTextEditor
                                    className="mt-1 block w-full py-2 pl-4 focus:outline-none !outline-none resize-none sm:text-sm bg-white"
                                    setFieldValue={(val) => props.setFieldValue(props.name == "message_email" ? "message_email" : props.name, val)}
                                    value={props.value}
                                />
                            </div>
                        )}
                    </Field>
                </div>
            ) : (
                <div className="border border-gray-300 rounded-md shadow-sm z-20">
                    <Field as="textarea" {...props} className={textarea_class + " mt-1 block w-full py-2 pl-4 focus:outline-none !outline-none resize-none sm:text-sm"} />
                    {emojis && <EmojiPicker source="slideover" value={props.value} setFieldValue={props.setFieldValue} />}
                </div>
            )}

            <p className="text-red-400 text-sm">{props.errors}</p>
        </div>
    );
}
