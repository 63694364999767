import { GET_FOLDER_STARTED, GET_FOLDER_SUCCESS, GET_FOLDER_FAILED } from "./folderConstants";
import axios from "axios";

export const getFolders = () => {
    return (dispatch) => {
        dispatch(getFoldersStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}folders`,
        })
            .then((response) => {
                dispatch(getFoldersSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getFoldersFailed(error.response));
            });
    };
};

const getFoldersStarted = () => {
    return {
        type: GET_FOLDER_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getFoldersSuccess = (folders) => {
    return {
        type: GET_FOLDER_SUCCESS,
        payload: {
            folders,
        },
    };
};

const getFoldersFailed = (error) => {
    return {
        type: GET_FOLDER_FAILED,
        payload: {
            error,
        },
    };
};
