import {
    GET_PROPERTIES_STARTED,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_FAILED,
} from "./propertyConstants";

const initialState = {
    properties: [],
    loading: false,
    error: "",
};

export default function propertyReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case GET_PROPERTIES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_PROPERTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                properties: payload.properties,
            };
        case GET_PROPERTIES_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}
