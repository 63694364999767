import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import CustomButton from "../../components/Helpers/CustomButton";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { toast } from "react-toastify";

export default function OrdersPricing() {
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);

    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [categoriesOptions, setCategoriesOptions] = useState([]);

    const [showAddCategory, setShowAddCategory] = useState(false);
    const [showAddItem, setShowAddItem] = useState(false);

    useEffect(async () => {
        getSelection();
    }, []);

    async function getSelection() {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_selection`,
        })
            .then((response) => {
                setCategories(response.data[0]);
                setItems(response.data[1]);

                let categories = response.data[0];
                let categoryList = [];

                Object.keys(categories).forEach((key) => {
                    categoryList.push({ key: categories[key].id, value: categories[key].id, label: categories[key].name });
                });

                setCategoriesOptions(categoryList);
            })
            .catch((error) => {
                alert("order load failed");
            });
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Pricing</h1>

                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    text="Add Category"
                    onClick={() => setShowAddCategory(showAddCategory === false ? true : false)}
                />

                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                    text="Add Item"
                    onClick={() => setShowAddItem(showAddItem === false ? true : false)}
                />

                {showAddCategory && showAddCategory === true ? (
                    <div className="mt-2">
                        <p>Add A New Category:</p>
                        <Formik
                            initialValues={{ name: "" }}
                            enableReinitialize="true"
                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                setSubmitting(true);

                                await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}create_category`,
                                    data: values,
                                })
                                    .then((response) => {
                                        setSubmitting(false);
                                        toast(<NotificationToast title={"Category Added"} message="Added Successfully" type="success" />);
                                        setShowAddCategory(false);
                                        getSelection();
                                    })
                                    .catch((error) => {
                                        toast(<NotificationToast title={"Something went wrong"} message={error.response.data.message} type="error" />);
                                    });
                            }}
                        >
                            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                    <div className="sm:p-2 xl:pb-8">
                                        <div className="grid grid-cols-12 gap-2">
                                            <Field name="name" type="text" as={SettingsTextInput} label="Category Name" />
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                icon={faSave}
                                                text="Save"
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    ""
                )}

                {showAddItem && showAddItem === true ? (
                    <div className="mt-2">
                        <p>Add A New Item:</p>
                        <Formik
                            initialValues={{ name: "", price: "", category_id: "" }}
                            enableReinitialize="true"
                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                setSubmitting(true);

                                values.price = values.price ? formatToFloat(values.price) : null;

                                await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}create_item`,
                                    data: values,
                                })
                                    .then((response) => {
                                        setSubmitting(false);
                                        toast(<NotificationToast title={"Item Added"} message="Added Successfully" type="success" />);
                                        setShowAddItem(false);
                                        getSelection();
                                    })
                                    .catch((error) => {
                                        toast(<NotificationToast title={"Something went wrong"} message={error.response.data.message} type="error" />);
                                    });
                            }}
                        >
                            {({ handleSubmit, isSubmitting, isValid, dirty, setFieldValue, values }) => (
                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                    <div className="sm:p-2 xl:pb-8">
                                        <div className="grid grid-cols-12 gap-2">
                                            <SettingsSelect label="Type" name="category_id" onChange={setFieldValue} value={values.order_type} options={categoriesOptions} />
                                            <Field name="name" type="text" as={SettingsTextInput} label="Item Name" />
                                            <Field name="price" prepend="R" currency="true" as={SettingsTextInput} label="Price" />
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                icon={faSave}
                                                text="Save"
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    ""
                )}

                <p className="text-lg mt-5 font-bold">Categories</p>

                {categories &&
                    categories.length > 0 &&
                    categories.map((category) => {
                        return (
                            <>
                                <Formik
                                    initialValues={{ name: category.name, value_id: category.id }}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_API_URL}update_category`,
                                            data: values,
                                        })
                                            .then((response) => {
                                                setSubmitting(false);
                                                toast(<NotificationToast title={"Category Updated"} message="Updated Successfully" type="success" />);
                                                getSelection();
                                            })
                                            .catch((error) => {
                                                toast(<NotificationToast title={"Something went wrong"} message={error.response.data.message} type="error" />);
                                            });
                                    }}
                                >
                                    {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                                            <div className="sm:p-2">
                                                <div className="grid grid-cols-12 gap-2">
                                                    <Field name="name" type="text" as={SettingsTextInput} label="Category Name" />
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={faSave}
                                                        text="Update"
                                                        style={{
                                                            width: "100%",
                                                            height: "55%",
                                                            marginTop: "28px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        );
                    })}

                <p className="text-lg mt-5 font-bold">Items</p>

                {items &&
                    items.length > 0 &&
                    items.map((item) => {
                        return (
                            <>
                                <Formik
                                    initialValues={{ name: item.name, price: item.price.toString(), value_id: item.id, category_id: item.category_id }}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        console.log(values);

                                        values.price = values.price ? formatToFloat(values.price) : null;

                                        await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_API_URL}update_item`,
                                            data: values,
                                        })
                                            .then((response) => {
                                                setSubmitting(false);
                                                toast(<NotificationToast title={"Item Updated"} message="Updated Successfully" type="success" />);
                                                getSelection();
                                            })
                                            .catch((error) => {
                                                toast(<NotificationToast title={"Something went wrong"} message={error.response.data.message} type="error" />);
                                            });
                                    }}
                                >
                                    {({ handleSubmit, isSubmitting, isValid, dirty, setFieldValue, values }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                                            <div className="sm:p-2">
                                                <div className="grid grid-cols-12 gap-2">
                                                    <SettingsSelect label="Type" name="category_id" onChange={setFieldValue} value={values.order_type} options={categoriesOptions} />
                                                    <Field name="name" type="text" as={SettingsTextInput} label="Item Name" />
                                                    <Field name="price" prepend="R" currency="true" as={SettingsTextInput} label="Price" />

                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={faSave}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                            height: "55%",
                                                            marginTop: "28px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        );
                    })}
            </div>
        </>
    );
}
