import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import SettingsSelect from "../Forms/SettingsSelect";
import { closeModal } from "../../store/modal/modalReducer";

import SettingsTimePicker from "../Forms/SettingsTimePicker";
import { getManagers } from "../../store/managers/managersActions";
import TimePicker from "react-time-picker";

export default function SlideoverManagementForm(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { properties } = useSelector((state) => state.properties);
  const [propertiesOption, setPropertiesOption] = useState([]);
  const [setProp, setSetProp] = useState(false);

  const [monActive, setMonActive] = useState(true);
  const [tueActive, setTueActive] = useState(true);
  const [wedActive, setWedActive] = useState(true);
  const [thuActive, setThuActive] = useState(true);
  const [friActive, setFriActive] = useState(true);
  const [satActive, setSatActive] = useState(true);
  const [sunActive, setSunActive] = useState(true);

  let propList = [];
  properties.forEach(function (property) {
    if (property.value != "3" && property.value != "7") {
      if (property.value == "6") {
        property.label = property.label.split(" - ")[0];
        propList.push(property);
      } else {
        propList.push(property);
      }
    }
  });

  if (setProp === false) {
    setSetProp(true);
    setPropertiesOption(propList);
  }

  const [iniSet, setIniSet] = useState(true);

  var initial_values,
    type,
    api_method,
    button = "";

  if (props.action == "edit") {
    initial_values = {
      id: props.data.data.id,
      status: props.data.data.status,
      is_plumber: props.data.data.is_plumber,
      is_electrician: props.data.data.is_electrician,
      schedule_id: props.schedule?.id,
      property_id: props.schedule?.property_id,
      mon_hours: props.schedule?.mon_hours,
      mon_start:
        props.schedule && props.schedule?.mon_start !== null
          ? props.schedule.mon_start
          : "08:00",
      mon_end:
        props.schedule && props.schedule?.mon_end !== null
          ? props.schedule.mon_end
          : "17:00",
      tue_hours: props.schedule?.tue_hours,
      tue_start:
        props.schedule && props.schedule?.tue_start !== null
          ? props.schedule.tue_start
          : "08:00",
      tue_end:
        props.schedule && props.schedule?.tue_end !== null
          ? props.schedule.tue_end
          : "17:00",
      wed_hours: props.schedule?.wed_hours,
      wed_start:
        props.schedule && props.schedule?.wed_start !== null
          ? props.schedule.wed_start
          : "08:00",
      wed_end:
        props.schedule && props.schedule?.wed_end !== null
          ? props.schedule.wed_end
          : "17:00",
      thu_hours: props.schedule?.thu_hours,
      thu_start:
        props.schedule && props.schedule?.thu_start !== null
          ? props.schedule.thu_start
          : "08:00",
      thu_end:
        props.schedule && props.schedule?.thu_end !== null
          ? props.schedule.thu_end
          : "17:00",
      fri_hours: props.schedule?.fri_hours,
      fri_start:
        props.schedule && props.schedule?.fri_start !== null
          ? props.schedule.fri_start
          : "08:00",
      fri_end:
        props.schedule && props.schedule?.fri_end !== null
          ? props.schedule.fri_end
          : "17:00",
      sat_hours: props.schedule?.sat_hours,
      sat_start:
        props.schedule && props.schedule?.sat_start !== null
          ? props.schedule.sat_start
          : "08:00",
      sat_end:
        props.schedule && props.schedule?.sat_end !== null
          ? props.schedule.sat_end
          : "17:00",
      sun_hours: props.schedule?.sun_hours,
      sun_start:
        props.schedule && props.schedule?.sun_start !== null
          ? props.schedule.sun_start
          : "08:00",
      sun_end:
        props.schedule && props.schedule?.sun_end !== null
          ? props.schedule.sun_end
          : "17:00",
    };

    if (iniSet === true) {
      setIniSet(false);
      setMonActive(props.schedule?.mon_hours === 1 ? true : false);
      setTueActive(props.schedule?.tue_hours === 1 ? true : false);
      setWedActive(props.schedule?.wed_hours === 1 ? true : false);
      setThuActive(props.schedule?.thu_hours === 1 ? true : false);
      setFriActive(props.schedule?.fri_hours === 1 ? true : false);
      setSatActive(props.schedule?.sat_hours === 1 ? true : false);
      setSunActive(props.schedule?.sun_hours === 1 ? true : false);
    }

    type = "Editing " + props.data.data.name + " " + props.data.data.surname;
    api_method = "manager_update";
    button = "Update";
  } else {
    initial_values = {
      id: props.data.data.id,
      status: props.data.data.status,
      is_plumber: props.data.data.is_plumber,
      is_electrician: props.data.data.is_electrician,
      property_id: "",
      mon_hours: 1,
      mon_start: "08:00",
      mon_end: "17:00",
      tue_hours: 1,
      tue_start: "08:00",
      tue_end: "17:00",
      wed_hours: 1,
      wed_start: "08:00",
      wed_end: "17:00",
      thu_hours: 1,
      thu_start: "08:00",
      thu_end: "17:00",
      fri_hours: 1,
      fri_start: "08:00",
      fri_end: "17:00",
      sat_hours: 1,
      sat_start: "08:00",
      sat_end: "17:00",
      sun_hours: 1,
      sun_start: "08:00",
      sun_end: "17:00",
    };

    if (iniSet === true) {
      setIniSet(false);
      setMonActive(true);
      setTueActive(true);
      setWedActive(true);
      setThuActive(true);
      setFriActive(true);
      setSatActive(true);
      setSunActive(true);
    }

    type = "Adding " + props.data.data.name + " " + props.data.data.surname;
    api_method = "manager_create";
    button = "Add";
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => dispatch(closeModal())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <ErrorBoundary>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
                        <div className="bg-dark_background py-6">
                          <div className=" px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-xl leading-6 font-light text-white uppercase">
                                {type}{" "}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-white hover:text-gray-200"
                                  onClick={() => dispatch(closeModal())}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                    icon={faTimes}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {console.log(initial_values.status)}
                        <div className="relative mt-6 flex-1 px-4 sm:px-6 ">
                          <Formik
                            initialValues={initial_values}
                            validationSchema={Yup.object({
                              property_id: Yup.string().when("status", {
                                is: "Staff",
                                then: Yup.string().required(
                                  "Please choose a Property"
                                ),
                                otherwise: Yup.string().nullable(),
                              }),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, setErrors, resetForm }
                            ) => {
                              setSubmitting(true);

                              await axios
                                .post(
                                  process.env.REACT_APP_API_URL + api_method,
                                  values
                                )
                                .then(() => {
                                  toast(
                                    <NotificationToast
                                      title={values.name + " saved"}
                                      message=""
                                    />
                                  );
                                })
                                .catch((error) => {
                                  toast(
                                    <NotificationToast
                                      title="Something went wrong"
                                      message={error.response}
                                      type="error"
                                    />
                                  );
                                })
                                .finally(() => {
                                  dispatch(getManagers());
                                  dispatch(closeModal());
                                  resetForm();
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              onChange,
                              isValid,
                              dirty,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                              handleChange,
                              setValues,
                            }) => (
                              <Form
                                onSubmit={handleSubmit}
                                className="space-y-8"
                              >
                                {values.status != "Dual" ||
                                (values.status == "Dual" &&
                                  values.is_plumber === 0 &&
                                  values.is_electrician === 0) ? (
                                  <SettingsSelect
                                    label="Property"
                                    name="property_id"
                                    errors={errors.property_id}
                                    options={propertiesOption}
                                  />
                                ) : (
                                  ""
                                )}

                                <br />
                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                  <span>Hours</span>
                                </label>
                                <div className="divide-y divide-slate-200">
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="mon_hours"
                                        checked={monActive}
                                        onChange={(v) => {
                                          setMonActive(v.target.checked);
                                          setFieldValue(
                                            "mon_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      MON
                                    </div>{" "}
                                    {monActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="mon_start"
                                          selected={values.mon_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="mon_end"
                                          selected={values.mon_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  {/* <TimePicker onkeyUp={(v) => console.log(v)} onChange={(value) => {setFieldValue(value); console.log(value)}} value={props.selected} clockIcon={false} disableClock={true} format="hh:mm a" className="bg-white" name={props.name}/> */}
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="tue_hours"
                                        checked={tueActive}
                                        onChange={(v) => {
                                          setTueActive(v.target.checked);
                                          setFieldValue(
                                            "tue_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      TUE
                                    </div>{" "}
                                    {tueActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="tue_start"
                                          selected={values.tue_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="tue_end"
                                          selected={values.tue_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="wed_hours"
                                        checked={wedActive}
                                        onChange={(v) => {
                                          setWedActive(v.target.checked);
                                          setFieldValue(
                                            "wed_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      WED
                                    </div>{" "}
                                    {wedActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="wed_start"
                                          selected={values.wed_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="wed_end"
                                          selected={values.wed_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="thu_hours"
                                        checked={thuActive}
                                        onChange={(v) => {
                                          setThuActive(v.target.checked);
                                          setFieldValue(
                                            "thu_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      THU
                                    </div>{" "}
                                    {thuActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="thu_start"
                                          selected={values.thu_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="thu_end"
                                          selected={values.thu_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="fri_hours"
                                        checked={friActive}
                                        onChange={(v) => {
                                          setFriActive(v.target.checked);
                                          setFieldValue(
                                            "fri_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      FRI
                                    </div>{" "}
                                    {friActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="fri_start"
                                          selected={values.fri_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="fri_end"
                                          selected={values.fri_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="sat_hours"
                                        checked={satActive}
                                        onChange={(v) => {
                                          setSatActive(v.target.checked);
                                          setFieldValue(
                                            "sat_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      SAT
                                    </div>{" "}
                                    {satActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="sat_start"
                                          selected={values.sat_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="sat_end"
                                          selected={values.sat_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                  <div className="py-5 flex">
                                    <div className="w-20">
                                      <input
                                        type="checkbox"
                                        className="mr-2"
                                        name="sun_hours"
                                        checked={sunActive}
                                        onChange={(v) => {
                                          setSunActive(v.target.checked);
                                          setFieldValue(
                                            "sun_hours",
                                            v.target.checked === true ? 1 : 0
                                          );
                                        }}
                                      />{" "}
                                      SUN
                                    </div>{" "}
                                    {sunActive === true ? (
                                      <>
                                        <SettingsTimePicker
                                          name="sun_start"
                                          selected={values.sun_start}
                                        />{" "}
                                        -{" "}
                                        <SettingsTimePicker
                                          name="sun_end"
                                          selected={values.sun_end}
                                        />
                                      </>
                                    ) : (
                                      "Unavailable"
                                    )}
                                  </div>
                                </div>
                                <br />

                                <div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
                                  <CustomButton
                                    type="button"
                                    onClick={() => dispatch(closeModal())}
                                    text="Cancel"
                                  />
                                  <SubmitButton
                                    name="Submit"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                    text={button}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
