import { GET_TAGS_STARTED, GET_TAGS_SUCCESS, GET_TAGS_FAILED } from "./tagConstants";
import axios from "axios";

export const getTags = () => {
    return (dispatch) => {
        dispatch(getTagsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}tags`,
        })
            .then((response) => {
                dispatch(getTagsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getTagsFailed(error.response));
            });
    };
};

const getTagsStarted = () => {
    return {
        type: GET_TAGS_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getTagsSuccess = (tags) => {
    return {
        type: GET_TAGS_SUCCESS,
        payload: {
            tags,
        },
    };
};

const getTagsFailed = (error) => {
    return {
        type: GET_TAGS_FAILED,
        payload: {
            error,
        },
    };
};
