import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { signInUser, signInToken, verifyAuth } from "../../store/auth/authActions";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import CustomButton from "../../components/Helpers/CustomButton";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { toast } from "react-toastify";

export default function ResetPassword() {
    const [show, setShow] = useState(false);

    const { token } = useParams();
    return (
        <div className="min-h-screen flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 xl:flex-none xl:px-24 ">
                <div className="mx-auto w-full max-w-sm xl:w-96">
                    <div>
                        <img
                            className="h-24 w-auto"
                            src={process.env.REACT_APP_COLOUR === "UC" ? "/images/logo_uc.svg" : process.env.REACT_APP_COLOUR === "QL" ? "/images/logo.svg" : "/images/logo-qholdings-v2.png"}
                            alt="Quorom Properties"
                        />
                        <h2 className="mt-6 text-2xl font-bold text-gray-900">Reset your password</h2>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <Formik
                                initialValues={{
                                    password: "",
                                    password_confirm: "",
                                    show_password: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string()
                                        .required("Password is required")
                                        .matches(
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
                                        ),
                                    password_confirm: Yup.string()
                                        .oneOf([Yup.ref("password"), null], "Passwords don't match")
                                        .required("Confirm Password is required"),
                                })}
                                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                    await axios({
                                        method: "post",
                                        url: `${process.env.REACT_APP_API_URL}reset-password`,
                                        data: {
                                            token: token,
                                            password: values.password,
                                            password_confirm: values.password_confirm,
                                        },
                                    })
                                        .then(() => {
                                            toast(<NotificationToast title={"Password reset succesfull"} message={"You can use your new password to login"} />);
                                            setSubmitting(false);
                                            resetForm();
                                            window.location("/adminlogin");
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            console.log(error.response);
                                        });
                                }}
                            >
                                {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Field name="password" label="Password" as={SettingsTextInput} type={show ? "text" : "password"} />
                                        <Field name="password_confirm" label="Confirm Password" as={SettingsTextInput} type={show ? "text" : "password"} />
                                        <button type="button" onClick={() => (show ? setShow(false) : setShow(true))} className="text-xs text-gray-800">
                                            Show / Hide Password
                                        </button>
                                        {Object.keys(errors).length > 0 &&
                                            Object.keys(errors).map((key) => (
                                                <p key={key} className="col-span-1 text-sm  text-red-400 font-light">
                                                    <span>{errors[key]}</span>
                                                </p>
                                            ))}
                                        <div className="py-8 flex space-x-2">
                                            <SubmitButton disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} type="submit" name="submit" text="Reset Password" />
                                            <Link to={"/adminlogin"}>
                                                <CustomButton text="Back to login" />
                                            </Link>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden xl:block relative w-0 flex-1">
                <img className="absolute inset-0 h-full w-full object-cover" src={process.env.REACT_APP_COLOUR === "UC" ? "/images/login_bg_uc.jpg" : "/images/login_bg.jpg"} alt="Login Background" />
            </div>
        </div>
    );
}
