import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form, Field, useFormikContext } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";

export default function ModalProprUnitType(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allPricing, setAllPricing] = useState([]);
  const [activePricing, setActivePricing] = useState(0);
  const [allYear, setAllYear] = useState(false);
  const [years, setYears] = useState(0);
  const [yearName, setYearName] = useState("");

  const { settings } = useSelector((state) => state.settings);
  const [unitType, setUnitType] = useState({
    id: 0,
    property_id: props.property_id,
    name: "",
    name_admin: "",
    name_nsfas: "",
    features: "",
    included: "",
    amenities: "",
    nsfas_price: 0,
    nsfas_description: "",
    nsfas_deposit: 0,
    nsfas_deposit_desc: "",
    nsfas_booking_fee: 0,
    nsfas_booking_fee_desc: "",
    private_price: 0,
    private_description: "",
    private_deposit: 0,
    private_deposit_desc: "",
    private_booking_fee: 0,
    private_booking_desc: "",
    bursary_price: 0,
    bursary_description: "",
    bursary_deposit: 0,
    bursary_deposit_desc: "",
    bursary_booking_fee: 0,
    bursary_booking_fee_desc: "",
    pricing_year: 0,
  });

  useEffect(async () => {
    setLoading(true);

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_years`,
    })
      .then((response) => {
        let dbYears = response.data;
        dbYears.push({ label: "All years", value: 0 });
        dbYears.sort((a, b) => a.value - b.value);
        setYears(dbYears);
      })
      .catch((error) => {
        alert("year load failed");
      });

    if (!loading && props.id > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_unit_type/${props.id}`,
      })
        .then((response) => {
          // setUnitType( response.data[0]);
          setAllPricing(response.data[0].full_price);
          let dbYears = response.data[1];
          dbYears.push({ label: "All years", value: 0 });
          dbYears.sort((a, b) => a.value - b.value);
          setYears(dbYears);

          let price_all = true;
          let pricings = response.data[0].full_price;
          let indexForYear = 0;

          let nsfas_price = 0;
          let nsfas_deposit = 0;
          let nsfas_booking_fee = 0;

          let private_price = 0;
          let private_deposit = 0;
          let private_booking_fee = 0;

          let bursary_price = 0;
          let bursary_deposit = 0;
          let bursary_booking_fee = 0;

          if (pricings.length > 0) {
            pricings.map((price, index) => {
              if (price.year_id == settings.year_id) {
                price_all = false;
                setActivePricing(index);
                indexForYear = price.year_id;

                nsfas_price = price.nsfas_price;
                nsfas_deposit = price.nsfas_deposit;
                nsfas_booking_fee = price.nsfas_booking_fee;

                private_price = price.private_price;
                private_deposit = price.private_deposit;
                private_booking_fee = price.private_booking_fee;

                bursary_price = price.bursary_price;
                bursary_deposit = price.bursary_deposit;
                bursary_booking_fee = price.bursary_booking_fee;
              }
            });

            if (price_all === true) {
              pricings.map((price, index) => {
                if (price.year_id == 0) {
                  setActivePricing(index);
                  indexForYear = price.year_id;

                  nsfas_price = price.nsfas_price;
                  nsfas_deposit = price.nsfas_deposit;
                  nsfas_booking_fee = price.nsfas_booking_fee;

                  private_price = price.private_price;
                  private_deposit = price.private_deposit;
                  private_booking_fee = price.private_booking_fee;

                  bursary_price = price.bursary_price;
                  bursary_deposit = price.bursary_deposit;
                  bursary_booking_fee = price.bursary_booking_fee;
                }
              });
            }
          }

          let unitTypeData = response.data[0];
          unitTypeData.pricing_year = indexForYear;

          unitTypeData.nsfas_price = nsfas_price;
          unitTypeData.nsfas_deposit = nsfas_deposit;
          unitTypeData.nsfas_booking_fee = nsfas_booking_fee;

          unitTypeData.private_price = private_price;
          unitTypeData.private_deposit = private_deposit;
          unitTypeData.private_booking_fee = private_booking_fee;

          unitTypeData.bursary_price = bursary_price;
          unitTypeData.bursary_deposit = bursary_deposit;
          unitTypeData.bursary_booking_fee = bursary_booking_fee;

          console.log(unitTypeData);

          setUnitType(unitTypeData);
          setAllYear(price_all);

          setLoading(false);
        })
        .catch((error) => {
          alert("unit type load failed");
        });
    }
  }, []);

  const updateValues = (data, fieldUpdate, years) => {
    let year_found = false;

    if (years && years.length > 0) {
      years.map((year) => {
        if (data === year.year_id) {
          year_found = true;

          fieldUpdate("nsfas_price", year.nsfas_price);
          fieldUpdate("nsfas_deposit", year.nsfas_deposit);
          fieldUpdate("nsfas_booking_fee", year.nsfas_booking_fee);

          fieldUpdate("private_price", year.private_price);
          fieldUpdate("private_deposit", year.private_deposit);
          fieldUpdate("private_booking_fee", year.private_booking_fee);

          fieldUpdate("bursary_price", year.bursary_price);
          fieldUpdate("bursary_deposit", year.bursary_deposit);
          fieldUpdate("bursary_booking_fee", year.bursary_booking_fee);
        }
      });
    }

    if (year_found === false) {
      fieldUpdate("nsfas_price", 0);
      fieldUpdate("nsfas_deposit", 0);
      fieldUpdate("nsfas_booking_fee", 0);

      fieldUpdate("private_price", 0);
      fieldUpdate("private_deposit", 0);
      fieldUpdate("private_booking_fee", 0);

      fieldUpdate("bursary_price", 0);
      fieldUpdate("bursary_deposit", 0);
      fieldUpdate("bursary_booking_fee", 0);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      {props.id > 0 ? "Edit" : "Add"} Unit Type
                    </Dialog.Title>

                    <Formik
                      initialValues={unitType}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}save_unit_type`,
                          data: values,
                        })
                          .then((response) => {
                            window.location.reload();
                          })
                          .catch((error) => {
                            alert("unit type save failed");
                          });

                        setLoading(false);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <div className="flex space-x-4">
                            <div className="w-1/3">
                              <Field
                                name="name"
                                type="text"
                                as={SettingsTextInput}
                                label="DESCRIPTION (STUDENT)"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="name_admin"
                                type="text"
                                as={SettingsTextInput}
                                label="DESCRIPTION (ADMIN)"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="name_nsfas"
                                type="text"
                                as={SettingsTextInput}
                                label="NSFAS SPECIAL WORDING"
                              />
                            </div>
                          </div>

                          <div>
                            <Field
                              name="features"
                              type="text"
                              as={SettingsTextarea}
                              placeholder="FEATURES"
                            />

                            <Field
                              name="included"
                              type="text"
                              as={SettingsTextarea}
                              placeholder="INCLUDED"
                            />
                          </div>

                          <div className="flex">
                            <p className="mr-2 mt-4">
                              <b>Below Pricing is Active for </b>
                            </p>
                            <SettingsSelect
                              name="pricing_year"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              updateValues={(data) =>
                                updateValues(data, setFieldValue, allPricing)
                              }
                              value={values.pricing_year}
                              touched={touched.pricing_year}
                              error={errors.pricing_year}
                              options={years}
                            />
                          </div>

                          <div className="flex space-x-4">
                            <div className="w-1/3">
                              <Field
                                name="nsfas_price"
                                type="text"
                                as={SettingsTextInput}
                                label="NSFAS PRICE"
                              />

                              <Field
                                name="nsfas_description"
                                type="text"
                                as={SettingsTextInput}
                                label="NSFAS PRICE EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="nsfas_deposit"
                                type="text"
                                as={SettingsTextInput}
                                label="NSFAS UPFRONT NON-REFUNDABLE FEE"
                              />

                              <Field
                                name="nsfas_deposit_desc"
                                type="text"
                                as={SettingsTextInput}
                                label=" NSFAS UPFRONT NON-REFUNDABLE EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="nsfas_booking_fee"
                                type="text"
                                as={SettingsTextInput}
                                label="NSFAS BOOKING FEE"
                              />

                              <Field
                                name="nsfas_booking_fee_desc"
                                type="text"
                                as={SettingsTextInput}
                                label=" NSFAS BOOKING FEE EXTRA"
                              />
                            </div>
                          </div>

                          <div className="flex space-x-4">
                            <div className="w-1/3">
                              <Field
                                name="bursary_price"
                                type="text"
                                as={SettingsTextInput}
                                label="BURSARY PRICE"
                              />

                              <Field
                                name="bursary_description"
                                type="text"
                                as={SettingsTextInput}
                                label="BURSARY PRICE EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="bursary_deposit"
                                type="text"
                                as={SettingsTextInput}
                                label="BURSARY DEPOSIT"
                              />

                              <Field
                                name="bursary_deposit_desc"
                                type="text"
                                as={SettingsTextInput}
                                label=" BURSARY DEPOSIT EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="bursary_booking_fee"
                                type="text"
                                as={SettingsTextInput}
                                label="BURSARY BOOKING FEE"
                              />

                              <Field
                                name="bursary_booking_fee_desc"
                                type="text"
                                as={SettingsTextInput}
                                label="BOOKING FEE EXTRA"
                              />
                            </div>
                          </div>

                          <div className="flex space-x-4">
                            <div className="w-1/3">
                              <Field
                                name="private_price"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE PRICE"
                              />

                              <Field
                                name="private_description"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE PRICE EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="private_deposit"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE DEPOSIT"
                              />

                              <Field
                                name="private_deposit_desc"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE DEPOSIT EXTRA"
                              />
                            </div>

                            <div className="w-1/3">
                              <Field
                                name="private_booking_fee"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE BOOKING FEE"
                              />

                              <Field
                                name="private_booking_fee_desc"
                                type="text"
                                as={SettingsTextInput}
                                label="PRIVATE BOOKING FEE extra"
                              />
                            </div>
                          </div>

                          <div className="flex space-x-6">
                            <SubmitButton
                              name="Save"
                              disabled={!isValid || isSubmitting || !dirty}
                              isSubmitting={isSubmitting}
                              text="Save"
                            />
                            {/* <CustomButton
                              type="alert"
                              text="Delete"
                              onClick={() => alert("delete...")}
                            /> */}
                            <CustomButton
                              type="secondary"
                              text="Close"
                              onClick={() => dispatch(closeModal())}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
