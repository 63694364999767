import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment/moment";

export default function AtInfo({ setDoc, db }) {
    const { current_staff, staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const [allAts, setAllAts] = useState([]);

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_ats/${current_booking.id}`,
        }).then((response) => {
            let ats = response.data;

            Object.keys(ats).forEach((key) => {
                let staffMemberTagger = staff.find((e) => e.id === parseFloat(ats[key].from));

                if (staffMemberTagger === undefined) {
                    staffMemberTagger = -1;
                }

                ats[key].tagger = staffMemberTagger;
                let messageToCheck = ats[key].message;
                let staffNames = staff.map(({ name }) => name);
                let staffMemberTagged = "";

                if (staffNames.some((substring) => messageToCheck.includes(substring))) {
                    let taggedCount = messageToCheck.match(/<\/a>/g).length || [].length;

                    if (taggedCount > 1) {
                        let multitag = messageToCheck.split("</a>");
                        let allTagged = [];

                        Object.keys(multitag).forEach((index) => {
                            let fulltag = multitag[index] + "</a>";
                            let cleanText = fulltag.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");

                            if (cleanText.includes("@")) {
                                let tagged_name = cleanText.match(/<a[^>]*>([^<]+)<\/a>/)[1];
                                let staffMemberTaggedData = staff.find((e) => e.name === tagged_name.replace("@", ""));
                                allTagged.push(staffMemberTaggedData);
                            }
                        });

                        staffMemberTagged = allTagged;
                    } else {
                        let tagged_name = messageToCheck.match(/<a[^>]*>([^<]+)<\/a>/)[1];
                        staffMemberTagged = [staff.find((e) => e.name === tagged_name.replace("@", ""))];
                    }
                }

                ats[key].tagged = staffMemberTagged;
            });

            ats.sort((a, b) => (moment(a.created_at) > moment(b.created_at) ? -1 : 1));

            setAllAts(response.data);
        });
    }, []);

    // console.log(current_booking)

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            <p className="font-bold text-green-500">Green Text = Currently Tagged</p>
            <p className="font-bold text-red-500">Red Text = Tag Removed</p>
            <hr />

            {allAts &&
                allAts.length > 0 &&
                allAts.map((ats) => {
                    return (
                        <div key={ats.id}>
                            <div className="border-2 w-full rounded px-1 py-1 my-2 ">
                                <p className="text-center mb-1">
                                    {ats.tagger.name} {ats.tagger.surname} Tagged <br />
                                    {ats.tagged &&
                                        ats.tagged.length > 0 &&
                                        ats.tagged.map((tag) => {
                                            return (
                                                <>
                                                    {console.log("Tag", tag.id.toString())}
                                                    <span className={current_booking.assigned_to.includes(tag.id.toString()) ? "font-bold text-green-500" : "font-bold text-red-500"}>
                                                        {tag.name} {tag.surname}
                                                    </span>
                                                    <br />
                                                </>
                                            );
                                        })}
                                </p>
                                <hr />
                                <div className="text-center mt-2" dangerouslySetInnerHTML={{ __html: ats.message }}></div>
                                <p className="text-right mt-2">
                                    <small>{ats.created_at}</small>
                                </p>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
}
