import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment/moment";
import CustomButton from "../Helpers/CustomButton";
import { openModal } from "../../store/modal/modalReducer";
import { format } from "date-fns";

export default function CustomOrders({ setDoc, db }) {
    const { current_staff, staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const [allAts, setAllAts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [reNewList, setReNewList] = useState(true);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (reNewList === true) {
            setReNewList(false);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}get_orders`,
                data: { unit_id: current_booking.unit_id },
            })
                .then((response) => {
                    setOrders(response.data);
                })
                .catch((error) => {
                    alert("order load failed");
                });
        }
    }, [reNewList]);

    // console.log(current_booking)

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            {current_staff.create_order === 1 ? (
                <CustomButton
                    className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5 w-full"
                    text="Create an Order"
                    onClick={() =>
                        dispatch(
                            openModal({
                                modal_type: "OrderModal",
                                modal_props: { setReNewList: setReNewList },
                            })
                        )
                    }
                />
            ) : (
                ""
            )}
            <br />

            {orders &&
                orders.length > 0 &&
                orders.map((order) => {
                    return (
                        <>
                            <div
                                className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "ModalOrderInfo",
                                            modal_props: {
                                                current_booking: current_booking,
                                                order: order,
                                                setReNewList: setReNewList,
                                            },
                                        })
                                    )
                                }
                            >
                                <p className={order.cancelled === 1 ? "text-center text-red-600" : "text-center"}>
                                    {order.order_type == 1 ? "General" : "Unit Specific"} {order.order_reason == 1 ? " - R & M" : order.order_reason == 2 ? " - Reinstatement" : " - Tenant Cost"}{" "}
                                    {order.order_reason === 1 ? (order.status === 0 ? " (Pending)" : " (Approved)") : ""} @ {format(new Date(order.created_at), "ccc, dd MMMM yyyy")} by{" "}
                                    {order.added.name} {order.added.surname} {order.cancelled === 1 ? " | ORDER CANCELLED" : ""}
                                </p>
                            </div>
                        </>
                    );
                })}
        </div>
    );
}
