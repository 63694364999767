import React from "react";
import moment from "moment";
import "moment/min/locales";

export default function TimeSince({ ...props }) {
    moment.updateLocale("en", {
        relativeTime: {
            s: "now",
            m: "now",
            mm: "%d m",
            hh: "%d h",
            dd: "%d d",
            w: "1 week ago",
            ww: "%d w",
            M: "1 month ago",
            MM: "%d mo",
            y: "1 year ago",
            yy: "%d y",
        },
    });

    return (
        <span title={props.time} {...props}>
            {moment(new Date(props.time)).locale("en").fromNow(true)}
        </span>
    );
}
