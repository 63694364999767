import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function BookingPlatformButton({ ...props }) {
    return (
        <button
            className={classNames(
                props.valuePlatform === props.buttonPlatform ? "text-dark-background bg-gray-200 border-primary" : "text-white border-primary bg-primary",
                "rounded-t-xl pb-1 pt-2 border-b-4 font-medium flex justify-center items-center uppercase h-8 px-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 hover:scale-110"
            )}
            style={{ fontSize: "9px" }}
            type="button"
            onClick={() => {
                props.setFieldValue("platform", props.buttonPlatform);
                props.setFieldValue("from", props.from);
                props.setFieldValue("to", props.to);
            }}
        >
            <FontAwesomeIcon className="h-4 w-4 pr-1" aria-hidden="true" icon={props.icon} />
            <div className="hidden">{props.message}</div>
        </button>
    );
}
