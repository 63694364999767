import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TimeoutWarningModal } from "./TimeoutWarningModal"
import { addEventListeners, removeEventListeners } from './eventListenerUtil'
import axios from "axios";
import { signOutUser } from "../../store/auth/authActions";

export const TimeoutLogic = () =>
{
    const { staff, current_staff } = useSelector( ( state ) => state.auth );
    const [ isWarningModalOpen, setWarningModalOpen ] = useState( false );
    const dispatch = useDispatch();
    useEffect( () =>
    {
        // fire modal after one hour
        const createTimeout1 = () => setTimeout( () =>
        {
            setWarningModalOpen( true );
        }, 1000 * 60 * 60 )

        const createTimeout2 = () => setTimeout( () =>
        {
            axios( {
                method: "post",
                url: `${ process.env.REACT_APP_API_URL }logout`,
                headers: {
                    Authorization: `Bearer ${ current_staff?.token }`,
                },
            } )
                .then( ( res ) =>
                {
                    dispatch( signOutUser() );
                    window.location.replace( "/login" );
                } )
                .catch( ( error ) =>
                {
                    //Error, user probably already logged out
                    console.log( error );
                    dispatch( signOutUser() );

                    window.location.replace( "/login" );
                } );
        }, 10000 )

        const listener = () =>
        {
            if ( !isWarningModalOpen )
            {
                clearTimeout( timeout )
                timeout = createTimeout1();
            }
        }

        // Initialization
        let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1()
        addEventListeners( listener );

        // Cleanup
        return () =>
        {
            removeEventListeners( listener );
            clearTimeout( timeout );
        }
    }, [ isWarningModalOpen ] )
    return (
        <div>
            { isWarningModalOpen && (
                <TimeoutWarningModal
                    isOpen={ isWarningModalOpen }
                    onRequestClose={ () => setWarningModalOpen( false ) }
                />
            )
            }
        </div>
    )
}