import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import Select from "react-select";
import CustomButton from "../../components/Helpers/CustomButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { openModal } from "../../store/modal/modalReducer";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import QuickFormSelect from "../../components/Forms/QuickFormSelect";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function OrdersPage() {
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);
    const { properties } = useSelector((state) => state.properties);

    const [propertiesOption, setPropertiesOption] = useState([]);
    const [propertiesFilterOption, setPropertiesFilterOption] = useState([]);
    const [getPropList, setGetPropList] = useState(false);
    const [reNewList, setReNewList] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState("");
    const [selectedProperty, setSelectedProperty] = useState("");

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [fromDateMove, setFromDateMove] = useState(new Date());
    const [toDateMove, setToDateMove] = useState(new Date());

    const [generalOrders, setGeneralOrders] = useState([]);
    const [unitOrders, setUnitOrders] = useState([]);
    const [propertyFilter, setPropertyFilter] = useState(0);
    const filterProp = useRef();

    const dispatch = useDispatch();

    useEffect(async () => {
        if (getPropList === false) {
            setGetPropList(true);

            let propList = [];
            let propList2 = [];
            properties.forEach(function (property) {
                propList2.push({ label: property.label, value: property.value });
                if (property.value != "3" && property.value != "7") {
                    if (property.value == "6") {
                        property.label = property.label.split(" - ")[0];
                        propList.push(property);
                    } else {
                        propList.push(property);
                    }
                }
            });
            setPropertiesOption(propList);
            setPropertiesFilterOption(propList2);
        }

        if (reNewList === true) {
            setReNewList(false);
            if (selectedProperty !== "") {
                getOrders(selectedProperty);
            }
        }
    }, [reNewList]);

    async function getOrders(property) {
        setSelectedProperty(property);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get_all_orders`,
            data: { property: property },
        })
            .then((response) => {
                setGeneralOrders(response.data[0]);
                setUnitOrders(response.data[1]);
            })
            .catch((error) => {
                alert("order load failed");
            });
    }

    async function downloadDaily(from, to, property, type) {
        if (type === "order") {
            await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}order/export_daily_order`, responseType: "blob", data: { from: from, to: to } })
                .then((response) => {
                    const href = URL.createObjectURL(response.data);

                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", "Daily Orders.xlsx");
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            await axios({ method: "post", url: `${process.env.REACT_APP_API_URL}order/export_pre_move_item`, responseType: "blob", data: { from: from, to: to, property: property } })
                .then((response) => {
                    const href = URL.createObjectURL(response.data);

                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", "Pre Move Order Items.xlsx");
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    async function updateTime(type, value) {
        if (type == "from") {
            setFromDate(new Date(value));
        } else if (type == "to") {
            setToDate(new Date(value));
        } else if (type == "toMove") {
            setToDateMove(new Date(value));
        } else if (type == "fromMove") {
            setFromDateMove(new Date(value));
        }
    }

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Orders</h1>

                {current_staff.status === "Admin" ? (
                    <>
                        <Link to="/orders/pricing">
                            <CustomButton
                                className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                                text="Update Pricing & Manage Categories"
                            />
                        </Link>
                    </>
                ) : (
                    ""
                )}

                <div className="mt-5">
                    {propertiesOption &&
                        propertiesOption.length > 0 &&
                        propertiesOption.map((property) => (
                            <>
                                {current_staff.building_manager === 0 || (current_staff.building_manager === 1 && current_staff.bm_property_id === property.value) ? (
                                    <p
                                        className={classNames(selectedOrders === property.value ? "bg-gray-300" : "hover:bg-gray-300", "border p-2 cursor-pointer")}
                                        onClick={() => {
                                            setSelectedOrders(property.value);
                                            getOrders(property.value);
                                        }}
                                    >
                                        {property.label}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </>
                        ))}
                </div>

                <div className="mt-5">
                    <p className="text-xl">Order List</p>
                    <div className="flex">
                        <div className="flex">
                            <label className="mt-2">From: &nbsp;</label>
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => updateTime("from", date)}
                                dateFormat={"d MMMM yyyy"}
                                isClearable={false}
                                showYearDropdown
                                showMonthDropdown
                                className="w-40 font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-1"
                            />
                        </div>
                        <div className="flex">
                            <label className="mt-2">To: &nbsp;</label>
                            <DatePicker
                                selected={toDate}
                                onChange={(update) => updateTime("to", update)}
                                dateFormat={"d MMMM yyyy"}
                                isClearable={false}
                                showYearDropdown
                                showMonthDropdown
                                className="z-20 block w-40 font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-1"
                            />
                        </div>
                        <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold ml-2"
                            text="Download Orders"
                            onClick={() => downloadDaily(fromDate, toDate, 0, "order")}
                        />
                    </div>
                    <p className="text-xl">Pre Move Out List</p>
                    <div className="flex">
                        {/* propertiesFilterOption */}
                        <div className="flex">
                            <label className="mt-2">Property: &nbsp;</label>
                            <select
                                name="duration_hours"
                                ref={filterProp}
                                className="w-40 font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-1"
                                onChange={(v) => setPropertyFilter(v.target.value)}
                            >
                                <option value="0" selected="0">
                                    All properties
                                </option>
                                {propertiesFilterOption &&
                                    propertiesFilterOption.length > 0 &&
                                    propertiesFilterOption.map((u) => (
                                        <option key={u.value} value={u.value}>
                                            {u.label}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="flex">
                            <label className="mt-2">From: &nbsp;</label>
                            <DatePicker
                                selected={fromDateMove}
                                onChange={(date) => updateTime("fromMove", date)}
                                dateFormat={"d MMMM yyyy"}
                                isClearable={false}
                                showYearDropdown
                                showMonthDropdown
                                className="w-40 font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-1"
                            />
                        </div>
                        <div className="flex">
                            <label className="mt-2">To: &nbsp;</label>
                            <DatePicker
                                selected={toDateMove}
                                onChange={(update) => updateTime("toMove", update)}
                                dateFormat={"d MMMM yyyy"}
                                isClearable={false}
                                showYearDropdown
                                showMonthDropdown
                                className="z-20 block w-40 font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary ml-1"
                            />
                        </div>
                        <CustomButton
                            className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold ml-2"
                            text="Download Pre Move Out"
                            onClick={() => downloadDaily(fromDateMove, toDateMove, propertyFilter, "move")}
                        />
                    </div>
                    {current_staff.create_order === 1 ? (
                        <div className="flex flex-row-reverse -mt-10">
                            <CustomButton
                                className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-5"
                                text="Create an Order"
                                onClick={() =>
                                    dispatch(
                                        openModal({
                                            modal_type: "OrderModal",
                                            modal_props: { setReNewList: setReNewList, type: "general" },
                                        })
                                    )
                                }
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="flex mt-5">
                        <div className="grow">
                            <p>
                                <b>General Orders</b>
                            </p>
                            <br />
                            <div className="h-80 overflow-y-scroll">
                                {generalOrders &&
                                    generalOrders.length > 0 &&
                                    generalOrders.map((generalOrder) => (
                                        <>
                                            <div
                                                className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "ModalOrderInfo",
                                                            modal_props: {
                                                                order: generalOrder,
                                                                setReNewList: setReNewList,
                                                            },
                                                        })
                                                    )
                                                }
                                            >
                                                <p className={generalOrder.cancelled === 1 ? "text-center text-red-600" : "text-center"}>
                                                    {generalOrder.order_reason == 1 ? " R & M" : generalOrder.order_reason == 2 ? " Reinstatement" : " Tenant Cost"}{" "}
                                                    {generalOrder.order_reason === 1 ? (generalOrder.status === 0 ? " (Pending)" : " (Approved)") : ""} @{" "}
                                                    {format(new Date(generalOrder.created_at), "ccc, dd MMMM yyyy")} {generalOrder.cancelled === 1 ? " | ORDER CANCELLED" : ""}
                                                </p>
                                            </div>
                                        </>
                                    ))}
                            </div>
                        </div>

                        <div className="grow">
                            <p>
                                <b>Unit Specific</b>
                            </p>
                            <br />
                            <div className="h-80 overflow-y-scroll">
                                {unitOrders &&
                                    unitOrders.length > 0 &&
                                    unitOrders.map((unitOrder) => (
                                        <>
                                            <div
                                                className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                                                onClick={() =>
                                                    dispatch(
                                                        openModal({
                                                            modal_type: "ModalOrderInfo",
                                                            modal_props: {
                                                                order: unitOrder,
                                                                setReNewList: setReNewList,
                                                            },
                                                        })
                                                    )
                                                }
                                            >
                                                <p className={unitOrder.cancelled === 1 ? "text-center text-red-600" : "text-center"}>
                                                    {unitOrder.unit_id} {unitOrder.order_reason == 1 ? " - R & M" : unitOrder.order_reason == 2 ? " - Reinstatement" : " - Tenant Cost"}{" "}
                                                    {unitOrder.order_reason === 1 ? (unitOrder.status === 0 ? " (Pending)" : " (Approved)") : ""} @{" "}
                                                    {format(new Date(unitOrder.created_at), "ccc, dd MMMM yyyy")} {unitOrder.cancelled === 1 ? " | ORDER CANCELLED" : ""}
                                                </p>
                                            </div>
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
