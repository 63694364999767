import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../../../components/Forms/SettingsTextInput";
import SubmitButton from "../../../components/Forms/SubmitButton";
import axios from "axios";
import { useSelector } from "react-redux";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function EmailFixPage() {
    const [email, setEmail] = useState("");
    const [bookinguser, setBookingUser] = useState({});
    const [messageID, setmessageID] = useState("");

    const [message, setMessage] = useState({});

    const { staff } = useSelector((state) => state.auth);

    function MessageColour(id, platform) {
        if (platform === "note") {
            return "bg-yellow-200";
        } else if (platform === "audit") {
            return "bg-red-200";
        } else if (platform === "website") {
            return "bg-green-200";
        } else if (staff.find((e) => e.id === id)) {
            return "bg-blue-200";
        } else {
            return "bg-gray-200";
        }
    }

    return (
        <div className="container py-5 flex space-x-5 justify-center">
            <Formik
                initialValues={{ message_id: "" }}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    setSubmitting(true);
                    setMessage({});

                    setmessageID(values.message_id);

                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}roger/get_email_by_id`,
                        data: {
                            message_id: values.message_id,
                        },
                    })
                        .then((response) => {
                            setMessage(response.data.db[0]);

                            setSubmitting(false);
                        })
                        .catch((error) => {
                            alert("Error!");

                            setSubmitting(false);
                        });
                }}
            >
                {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="w-96">
                            <div className="mb-4">
                                <Field name="message_id" type="text" as={SettingsTextInput} label="Message ID" required />
                            </div>

                            <SubmitButton
                                name="Save"
                                disabled={!isValid || isSubmitting || !dirty}
                                isSubmitting={isSubmitting}
                                text="Search"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>

                        {message?.id ? (
                            <div key={message.id} className="mt-5">
                                <div className={classNames(MessageColour(message.from, message.platform), "p-4 rounded-md text-sm break-words mb-5 max-w-lg m-auto")}>
                                    <div>
                                        <strong>Received: </strong>
                                        {message.created_at}
                                        <br />
                                        <strong>From: </strong>
                                        {message.from}
                                        <br />
                                        <strong>To: </strong>
                                        {message.to}
                                        <br />
                                    </div>
                                    <div
                                        className="dangerousPFormat"
                                        dangerouslySetInnerHTML={{
                                            __html: message.message_html && message.message_html !== "" ? message.message_html : message.message,
                                        }}
                                    />
                                    {staff.find((e) => e.id === message.from) && message.files.length > 0 && (
                                        <>
                                            <p className="text-xs text-gray-800 mt-2">Uploaded the following file(s)</p>

                                            {message.files instanceof Array ? (
                                                message.files.map((file) => (
                                                    <li key={file} className="text-xs text-gray-800">
                                                        {file}
                                                    </li>
                                                ))
                                            ) : (
                                                <li className="text-xs text-gray-800">{message.files}</li>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            "Message not found"
                        )}
                    </Form>
                )}
            </Formik>

            <Formik
                initialValues={{ email: "" }}
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    setSubmitting(true);

                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}roger/get_user_by_email`,
                        data: {
                            email: values.email,
                        },
                    })
                        .then((response) => {
                            console.log(response.data);

                            setEmail(values.email);
                            setBookingUser(response.data);

                            setSubmitting(false);
                        })
                        .catch((error) => {
                            alert("Error!");

                            setSubmitting(false);
                        });
                }}
            >
                {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="w-52">
                            <div className="mb-4">
                                <Field name="email" type="text" as={SettingsTextInput} label="New Email" required />
                            </div>

                            <SubmitButton
                                name="Save"
                                disabled={!isValid || isSubmitting || !dirty}
                                isSubmitting={isSubmitting}
                                text="Find Booking"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>
                    </Form>
                )}
            </Formik>

            <Formik
                initialValues={{ message_id: messageID, email: bookinguser.email, booking_id: bookinguser.booking_id, user_id: bookinguser.user_id }}
                enableReinitialize="true"
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    setSubmitting(true);

                    console.log(values);

                    axios({
                        method: "post",
                        url: `${process.env.REACT_APP_API_URL}roger/move_email_chat`,
                        data: {
                            email: values.email,
                            message_id: values.message_id,
                            booking_id: values.booking_id,
                            user_id: values.user_id,
                        },
                    })
                        .then((response) => {
                            if (response.data === "done") {
                                alert("Done");

                                setEmail("");
                                setBookingUser({});
                                setmessageID("");
                                setMessage({});
                            }

                            setSubmitting(false);
                        })
                        .catch((error) => {
                            alert("Error!");

                            setSubmitting(false);
                        });
                }}
            >
                {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="w-52">
                            <div className="mb-4">
                                <Field name="email" type="text" as={SettingsTextInput} label="New Email" required value={bookinguser.email} />
                                <Field name="message_id" type="text" as={SettingsTextInput} label="Message ID" required value={messageID} />
                                <Field name="booking_id" type="text" as={SettingsTextInput} label="Booking ID" required value={bookinguser.booking_id} />
                                <Field name="user_id" type="text" as={SettingsTextInput} label="User ID" required value={bookinguser.user_id} />
                            </div>

                            <SubmitButton
                                name="Save"
                                disabled={!isValid || isSubmitting || !dirty}
                                isSubmitting={isSubmitting}
                                text="Move Email"
                                style={{
                                    width: "100%",
                                }}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
