import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";

// import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";

export default function ReportViewings() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    const [dateRangeReport, setDateRangeReport] = useState([null, null]);
    const [startDateReport, endDateReport] = dateRangeReport;

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                // let responseData = [{id: 0, value:0, label: "All"}];
                // setProperties(responseData.concat(response.data));
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Export All</h1>

                <Formik
                    initialValues={{
                        property_id: 0,
                        startDateReport: "",
                        endDateReport: "",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        if (values.property_id == 0) {
                            alert("Please choose a property");
                        } else {
                            //
                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}reports/export_viewings`,
                                responseType: "blob",
                                data: { property_id: values.property_id, start_date: startDateReport, end_date: endDateReport },
                            })
                                .then((response) => {
                                    const href = URL.createObjectURL(response.data);

                                    const link = document.createElement("a");
                                    link.href = href;
                                    link.setAttribute("download", "ViewingsReport.xlsx");
                                    document.body.appendChild(link);
                                    link.click();

                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(href);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect label="What is your desired Building?" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />

                                    <div className="col-span-3">
                                        <label className="text-sm font-medium mt-5 text-gray-700">
                                            <span>Choose a date range</span>
                                        </label>
                                        <DatePicker
                                            selectsRange={true}
                                            startDate={startDateReport}
                                            endDate={endDateReport}
                                            onChange={(update) => {
                                                setDateRangeReport(update);
                                            }}
                                            label={"Date Range"}
                                            dateFormat={"d MMMM yyyy"}
                                            isClearable={true}
                                            showYearDropdown
                                            showMonthDropdown
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                        />
                                    </div>

                                    {/* <SettingsSelect label="Year" name="year_id" onChange={setFieldValue} value={values.year_id} options={years} /> */}
                                </div>

                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
