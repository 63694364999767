import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { getBooking } from "../../store/booking/bookingActions";

export default function TemplateFileUpload({ ...props }) {
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        props.setFieldValue("files", acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div {...getRootProps()} className="bg-light_background mt-1 sm:mt-0 sm:col-span-2">
            <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-dark_background border-dashed rounded-md">
                <div className="space-y-1 text-center">
                    <input {...getInputProps()} />
                    <p>Upload Files</p>
                </div>
            </div>
        </div>
    );
}
