import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function SettingsIconButton({ ...props }) {
    // console.log(props)
    return (
        <button
            type="button"
            {...props}
            className={classNames(props.disabled ? "text-gray-400" : "text-primary hover:text-primary_hover", "bg-white rounded-md font-medium  focus:outline-none bg-transparent")}
        >
            <FontAwesomeIcon icon={props.icon} />
        </button>
    );
}
