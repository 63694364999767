export const GET_TEMPLATES_STARTED = "GET_TEMPLATES_STARTED";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILED = "GET_TEMPLATES_FAILED";

export const GET_WHATSAPP_TEMPLATES_STARTED = "GET_WHATSAPP_TEMPLATES_STARTED";
export const GET_WHATSAPP_TEMPLATES_SUCCESS = "GET_WHATSAPP_TEMPLATES_SUCCESS";
export const GET_WHATSAPP_TEMPLATES_FAILED = "GET_WHATSAPP_TEMPLATES_FAILED";

export const GET_TEMPLATE_CATEGORIES_STARTED =
    "GET_TEMPLATE_CATEGORIES_STARTED";
export const GET_TEMPLATE_CATEGORIES_SUCCESS =
    "GET_TEMPLATE_CATEGORIES_SUCCESS";
export const GET_TEMPLATE_CATEGORIES_FAILED = "GET_TEMPLATE_CATEGORIES_FAILED";
