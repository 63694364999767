import React, { Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import SettingsTextInput from "../Forms/SettingsTextInput";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import PhoneInput from "react-phone-number-input";
import { getManagers } from "../../store/managers/managersActions";

export default function ModalNewManager(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Create a Manager
                    </Dialog.Title>

                    <Formik
                      initialValues={{
                        name: "",
                        cell: "",
                        email: "",
                      }}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        values.cell = values.cell.replace("+", "");

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}manager_quick`,
                          data: values,
                        })
                          .then((response) => {
                            setSubmitting(false);
                            resetForm();
                            dispatch(getManagers());
                            dispatch(closeModal());
                          })
                          .catch((error) => {
                            console.log(error.response.data.message);
                            toast(
                              <NotificationToast
                                title={"Something went wrong"}
                                message={error.response.data.message}
                                type="error"
                              />
                            );
                          });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200 m-4"
                        >
                          <div className="py-6 px-4 sm:p-6 xl:pb-8">
                            <div className="grid grid-cols-6 gap-6 mt-6">
                              <Field
                                name="name"
                                type="text"
                                as={SettingsTextInput}
                                label="Name"
                              />
                              <Field
                                name="surname"
                                type="text"
                                as={SettingsTextInput}
                                label="Surname"
                              />
                              <Field
                                name="email"
                                type="email"
                                as={SettingsTextInput}
                                label="Email"
                              />
                              <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                <label
                                  htmlFor="cell"
                                  className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between"
                                >
                                  <span>Cellphone Number</span>
                                </label>
                                <div className="mt-1">
                                  <PhoneInput
                                    defaultCountry="ZA"
                                    countryCallingCodeEditable={false}
                                    onChange={(val) => {
                                      setFieldValue("cell", val);
                                    }}
                                    onBlur={setFieldTouched}
                                    name="cell"
                                    value={values.cell}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-8">
                              <SubmitButton
                                name="Save"
                                disabled={!isValid || isSubmitting || !dirty}
                                isSubmitting={isSubmitting}
                                icon={faSave}
                                text="Save"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>

                          {Object.keys(errors).length > 0 && (
                            <ul
                              role="lis"
                              className="pt-4 px-8 mx-auto w-full text-orange-500 grid grid-cols-2 gap-2 sm:grid-cols-2 lg:grid-cols-3"
                            >
                              {Object.keys(errors).map((key) => (
                                <li
                                  key={key}
                                  className="col-span-1 text-sm font-light"
                                >
                                  <span>{errors[key]}</span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
