import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

export default function SettingsAddButton({ ...props }) {
    return (
        <button
            type="button"
            className="inline-flex items-center p-4 border border-transparent rounded-full shadow-sm text-white bg-primary hover:bg-primary_hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            {...props}
        >
            <FontAwesomeIcon aria-hidden="true" icon={faPlus} />
        </button>
    );
}
