import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import CustomButton from "../Helpers/CustomButton";
import SubmitButton from "../Forms/SubmitButton";
import { getTemplates } from "../../store/template/templateActions";
import { getTemplateCategories } from "../../store/template/templateActions";
import { getFolders } from "../../store/tag/folderActions";

export default function ModalNotification(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [open] = useState(true);

  let modalIcon = faCheckCircle;
  let modalIconClass = "my-4 text-2xl ";

  switch (props.type) {
    case "error":
      modalIcon = faTimesCircle;
      modalIconClass += "text-red-500";
      break;

    case "warning":
      modalIcon = faExclamationCircle;
      modalIconClass += "text-yellow-500";
      break;

    default:
      modalIconClass += "text-green-500";
      break;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <FontAwesomeIcon
                      className={modalIconClass}
                      aria-hidden="true"
                      icon={modalIcon}
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      {props.heading}
                    </Dialog.Title>
                    <div className="mt-2 text-gray-500">{props.body}</div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:justify-end sm:space-x-4">
                  <CustomButton
                    type="button"
                    onClick={() => dispatch(closeModal())}
                    text="Cancel"
                  />
                  {props.data.link && (
                    <CustomButton
                      type="danger"
                      onClick={() => {
                        axios({
                          method: props.confirmMethod,
                          url: `${process.env.REACT_APP_API_URL}${props.data.link}`,
                        })
                          .then(() => {
                            toast(
                              <NotificationToast
                                title={
                                  props.dataType + " " + props.confirmTitle
                                }
                                message={props.confirmMessage}
                              />
                            );
                          })
                          .catch((error) => {
                            toast(
                              <NotificationToast
                                title="Something went wrong"
                                message=""
                                type="error"
                              />
                            );
                          })
                          .finally(() => {
                            //TODO: Figure out how to make this dynamic
                            if (props.data.callback == "getTemplates") {
                              dispatch(getTemplates());
                            } else if (
                              props.data.callback == "getTemplateCategories"
                            ) {
                              dispatch(getTemplateCategories());
                            } else if (props.data.callback == "getFolders") {
                              dispatch(getFolders());
                            } else {
                              dispatch(props.data.callback);
                            }

                            dispatch(closeModal());
                          });
                      }}
                      text={props.buttonText}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
