import { GET_MANAGERS_STARTED, GET_MANAGERS_SUCCESS, GET_MANAGERS_FAILED } from "./managersConstants";
import axios from "axios";

export const getManagers = () => {
    return (dispatch) => {
        dispatch(getManagersStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}managers`,
        })
            .then((response) => {
                dispatch(getManagersSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getManagersFailed(error.response));
            });
    };
};

const getManagersStarted = () => {
    return {
        type: GET_MANAGERS_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getManagersSuccess = (managers) => {
    return {
        type: GET_MANAGERS_SUCCESS,
        payload: {
            managers,
        },
    };
};

const getManagersFailed = (error) => {
    return {
        type: GET_MANAGERS_FAILED,
        payload: {
            error,
        },
    };
};
