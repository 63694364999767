import React, { Fragment, useState, useEffect } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import { getBooking } from "../../store/booking/bookingActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ModalATLASReport(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_booking } = useSelector((state) => state.booking);
  const [queueData, setQueueData] = useState([]);
  const [bookingYear, setBookingYear] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchAtlasReport = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}atlas-report`,
          {
            booking_id: props.booking_id,
          }
        );

        // Set the queue data
        setQueueData(response.data.queueData);

        // Set the booking year
        setBookingYear(response.data.bookingYear);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching ATLAS report:", error);
        setLoading(false);
      }
    };

    fetchAtlasReport();
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>

                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    ></Dialog.Title>

                    <div>
                      <h1 className="text-center font-bold">
                        ATLAS Report {bookingYear && `(Year: ${bookingYear})`}
                      </h1>

                      {loading === true ? (
                        <p className="text-center">Loading report...</p>
                      ) : (
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                              ></th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                No Beds
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Waiting Stage 1
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                All Docs Loaded
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Vetting
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Lease Sent
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Check In
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                %
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {queueData?.map((queue, index) => (
                              <tr key={index}>
                                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-left">
                                  {queue.property_name}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.no_beds}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.waiting_stage_1}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.all_docs_loaded}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.vetting}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.lease_sent}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.check_in}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {queue.percent?.toFixed(2)}%
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
