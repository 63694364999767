import {
    GET_TEMPLATES_STARTED,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILED,
    GET_WHATSAPP_TEMPLATES_STARTED,
    GET_WHATSAPP_TEMPLATES_SUCCESS,
    GET_WHATSAPP_TEMPLATES_FAILED,
    GET_TEMPLATE_CATEGORIES_STARTED,
    GET_TEMPLATE_CATEGORIES_SUCCESS,
    GET_TEMPLATE_CATEGORIES_FAILED,
} from "./templateConstants";

const initialState = {
    templates: {},
    whatsapp_templates: {},
    loading: false,
    error: null,
    template_categories: {},
};

export default function templateReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case GET_TEMPLATES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                templates: payload.templates,
            };
        case GET_TEMPLATES_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case GET_WHATSAPP_TEMPLATES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_WHATSAPP_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                whatsapp_templates: payload.templates,
            };
        case GET_WHATSAPP_TEMPLATES_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case GET_TEMPLATE_CATEGORIES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_TEMPLATE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                template_categories: payload.template_categories,
            };
        case GET_TEMPLATE_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
