import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsDateTimePicker from "../Forms/SettingsDateTimePicker";
import { getBooking } from "../../store/booking/bookingActions";
import { getStaff } from "../../store/auth/authActions";
import ProfilePicture from "../Auth/ProfilePicture";
import SettingsSwitch from "../Forms/SettingsSwitch";

import QuickFormText from "../Forms/QuickFormText";
import QuickFormSelect from "../Forms/QuickFormSelect";
import QuickFormDate from "../Forms/QuickFormDate";

export default function ModalChangeUnit({
  setDoc,
  db,
  units,
  unitTypes,
  current_staff,
  default_values,
  getUnits,
  getUnitTypes,
}) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { properties } = useSelector((state) => state.properties);
  const { current_booking } = useSelector((state) => state.booking);

  // console.log(unitTypes);

  function formatToFloat(value) {
    if (value) {
      return parseFloat(value.replace(/,/g, ""));
    } else {
      return value;
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    ></Dialog.Title>
                    <Formik
                      initialValues={{
                        property_id: current_booking.property_id,
                        unit_type: current_booking.unit_type,
                        unit_id: current_booking.unit_id,
                        joint_application: current_booking.joint_application,

                        invoice_monthly_total: current_booking?.rates[
                          current_booking?.rates?.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].invoice_monthly_total
                          : "",
                        carport: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].carport
                          : "",
                        invoice_monthly_hide: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].invoice_monthly_hide
                          : "",
                        parking_fee: default_values?.parking_fee,
                        monthly_rental: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].monthly_rental
                          : "",
                        monthly_rental_wording: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].monthly_rental_wording
                          : "",
                        admin_fee: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].admin_fee
                          : "",
                        deposit_price: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].deposit_price
                          : "",
                        booking_fee_price: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].booking_fee_price
                          : "",
                        key_deposit: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].key_deposit
                          : "",
                        carport: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].carport
                          : "",
                        special_wording: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].special_wording
                          : "",
                        effluent: default_values?.effluent,
                        refuse: default_values?.refuse,
                        special_deduction: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].special_deduction
                          : "",
                        lease_start: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].lease_start
                          : "",
                        lease_end: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].lease_end
                          : "",

                        paid: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].paid
                          : "",
                        paid_at: current_booking.rates[
                          current_booking.rates.length - 1
                        ]
                          ? current_booking.rates[
                              current_booking.rates.length - 1
                            ].paid_at
                          : "",
                        signed_at: "",
                        signed_lease_at: "",
                        signed_lease_joint_at: "",
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        let submitData = {
                          user: current_staff,
                          url:
                            current_booking?.edit_link !== null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url,
                          property_id: values.property_id,
                          unit_type: values.unit_type,
                          unit_id: values.unit_id,
                          parking: values.parking,
                          parking_fee: values.carport
                            ? values.parking_fee
                            : null,
                          monthly_rental: formatToFloat(values.monthly_rental),
                          deposit_price: formatToFloat(values.deposit_price),
                          booking_fee_price: formatToFloat(
                            values.booking_fee_price
                          ),
                          admin_fee: formatToFloat(values.admin_fee),
                          key_deposit: formatToFloat(values.key_deposit),
                          effluent: default_values?.effluent,
                          refuse: default_values?.refuse,
                          special_deduction: formatToFloat(
                            values.special_deduction
                          ),

                          carport: values.carport,
                          invoice_monthly_hide: values.invoice_monthly_hide,
                          invoice_monthly_total: values.invoice_monthly_total,
                          monthly_rental_wording: values.monthly_rental_wording,
                          special_wording: values.special_wording,

                          lease_start: values.lease_start,
                          lease_end: values.lease_end,

                          paid: values.paid,
                          paid_at: values.paid_at,
                          signed_at: values.signed_at,
                          signed_lease_at: values.signed_lease_at,
                          signed_lease_joint_at: values.signed_lease_joint_at,

                          new_rate: 1,
                        };

                        await axios({
                          method: "put",
                          url: `${process.env.REACT_APP_API_URL}user`,
                          data: submitData,
                        })
                          .then(() => {
                            // setDoc(
                            //   doc(db, "Bookings", String(current_booking.id)),
                            //   {
                            //     property_id: values.property_id,
                            //     unit_type: values.unit_type,
                            //     unit_id: values.unit_id,
                            //   },
                            //   { merge: true }
                            // );
                            dispatch(getBooking(current_booking.id));
                            dispatch(closeModal());
                            toast(
                              <NotificationToast
                                title="Success"
                                message="Data has been updated sucessfully"
                              />
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                            toast(
                              <NotificationToast
                                title="Something went wrong"
                                message=""
                                type="error"
                              />
                            );
                          });

                        setSubmitting(false);
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isValid,
                        isSubmitting,
                        dirty,
                      }) => (
                        <Form onSubmit={handleSubmit} className="rounded-3xl">
                          <h2>Change Booking</h2>

                          <div className="pb-6 grid gap-x-2 gap-y-8 grid-cols-2 mt-5">
                            <QuickFormSelect
                              label="Building"
                              name="property_id"
                              getUnits={getUnits}
                              getUnitTypes={getUnitTypes}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              value={values.property_id}
                              touched={touched.property_id}
                              error={errors.property_id}
                              options={properties}
                            />
                            <QuickFormSelect
                              label="Unit Type"
                              name="unit_type"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              value={values.unit_type}
                              touched={touched.unit_type}
                              error={errors.unit_type}
                              options={
                                unitTypes?.length > 0 ? unitTypes : "none"
                              }
                            />
                            <QuickFormSelect
                              label="Unit"
                              name="unit_id"
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              value={values.unit_id}
                              touched={touched.unit_id}
                              error={errors.unit_id}
                              options={units?.length > 0 ? units : "none"}
                            />
                            <Field
                              name="carport"
                              as={QuickFormText}
                              label="Parking / Carport"
                              tooltip="Enter the Carport / Garage Number here, otherwise leave blank if not applicable"
                            />
                            {values.carport && (
                              <Field
                                name="parking_fee"
                                as={QuickFormText}
                                label="Parking Fee"
                                prepend="R"
                                currency="true"
                                disabled
                              />
                            )}
                            <Field
                              name="monthly_rental_wording"
                              as={QuickFormText}
                              label="Monthly Wording"
                            />
                            <Field
                              name="special_wording"
                              as={QuickFormText}
                              label="Special Wording"
                            />
                            <Field
                              name="monthly_rental"
                              as={QuickFormText}
                              label="Monthly Rental"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="deposit_price"
                              as={QuickFormText}
                              label="Deposit"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="booking_fee_price"
                              as={QuickFormText}
                              label="Booking Fee"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="admin_fee"
                              as={QuickFormText}
                              label="Admin Fee"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="refuse"
                              as={QuickFormText}
                              label="Refuse"
                              prepend="R"
                              currency="true"
                              disabled
                            />
                            <Field
                              name="effluent"
                              as={QuickFormText}
                              label="Effluent"
                              prepend="R"
                              currency="true"
                              disabled
                            />
                            <Field
                              name="key_deposit"
                              as={QuickFormText}
                              label="Key Deposit"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="special_deduction"
                              as={QuickFormText}
                              label="Deduction"
                              prepend="R"
                              currency="true"
                            />
                            <Field
                              name="lease_start"
                              type="text"
                              as={QuickFormDate}
                              label="Lease Start Date"
                            />
                            <Field
                              name="lease_end"
                              type="text"
                              as={QuickFormDate}
                              label="Lease End Date"
                            />
                          </div>
                          <Field
                            name="invoice_monthly_total"
                            as={SettingsSwitch}
                            label="Ignore monthly rental towards total"
                          />
                          <Field
                            name="invoice_monthly_hide"
                            as={SettingsSwitch}
                            label="Hide Monthly Rental"
                          />

                          <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2">
                            <Field
                              name="paid"
                              as={SettingsSwitch}
                              label="Invoice Paid"
                              description=" "
                            />
                            <Field
                              name="paid_at"
                              type="text"
                              as={QuickFormDate}
                              label="At"
                            />
                            <Field
                              name="signed_at"
                              as={SettingsSwitch}
                              label="Lease Signed"
                              description=" "
                            />
                            <Field
                              name="signed_lease_at"
                              type="text"
                              as={QuickFormDate}
                              label="At"
                            />
                          </div>
                          {values.joint_application !== 0 && (
                            <Field
                              name="signed_lease_joint_at"
                              type="text"
                              as={QuickFormDate}
                              label="Joint Signed Lease At"
                            />
                          )}
                          <br />
                          <SubmitButton
                            name="Save"
                            disabled={!isValid || isSubmitting || !dirty}
                            isSubmitting={isSubmitting}
                            icon={faSave}
                            text="Change Booking"
                            style={{ width: "100%" }}
                          />
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
