import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus } from "@fortawesome/pro-regular-svg-icons";

import { openModal } from "../../store/modal/modalReducer";
import CustomButton from "../Helpers/CustomButton";

export default function GridAdditionalInvoice({ setDoc, db }) {
    const dispatch = useDispatch();
    // const history = useHistory();
    // const [ loading, setLoading ] = useState( true );
    // const [ units, setUnits ] = useState( [] );
    const [invoices, setInvoices] = useState([]);
    const { current_booking } = useSelector((state) => state.booking);
    const { current_staff } = useSelector((state) => state.auth);

    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}get_invoices/${current_booking.id}`,
        })
            .then((response) => {
                setInvoices(response.data);
            })
            .catch((error) => {
                alert("custom invoice load failed");
            });
    }, []);

    return (
        <div className="max-h-96 overflow-y-scroll scroll-smooth">
            {!current_staff.building_manager ? (
                <>
                    {(process.env.REACT_APP_COLOUR === "UC" && current_booking.bed_id > 0) || process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" ? (
                        <CustomButton
                            text={"Add Custom Invoice"}
                            styling="w-full mt-2 mb-2"
                            icon={faFileCirclePlus}
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modal_type: "ModalCustomInvoice",
                                        modal_props: {
                                            current_booking: current_booking,
                                            invoice_id: 0,
                                            setInvoices: setInvoices,
                                            invoices: invoices,
                                        },
                                    })
                                )
                            }
                            loadingtext="Loading..."
                        />
                    ) : (
                        "No Bed assigned"
                    )}
                </>
            ) : (
                ""
            )}

            {invoices &&
                invoices.length > 0 &&
                invoices.map((invoice) => {
                    return (
                        <div
                            className="border-2 w-full rounded px-1 py-1 my-2 cursor-pointer hover:-translate-y-0.5 hover:bg-dark_background hover:text-white"
                            onClick={() =>
                                dispatch(
                                    openModal({
                                        modal_type: "ModalCustomInvoice",
                                        modal_props: {
                                            current_booking: current_booking,
                                            invoice_id: invoice.id,
                                            setInvoices: setInvoices,
                                            invoices: invoices,
                                        },
                                    })
                                )
                            }
                        >
                            <p className="text-center">{invoice?.file?.name}</p>
                        </div>
                    );
                })}
        </div>
    );
}
