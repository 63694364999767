import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import ErrorBoundary from "../../containers/ErrorBoundary";

import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import CustomButton from "../Helpers/CustomButton";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsTextArea from "../Forms/SettingsTextarea";
import { closeModal } from "../../store/modal/modalReducer";
import { getTemplates } from "../../store/template/templateActions";
import { getTemplateCategories } from "../../store/template/templateActions";
import { getTags } from "../../store/tag/tagActions";
import { getCollections } from "../../store/collection/collectionActions";
import { getAppointments } from "../../store/appointments/appointmentActions";
import { getRenewals } from "../../store/renewal/renewalActions";
import { getFolders } from "../../store/tag/folderActions";

export default function SlideoverLookupForm(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_staff } = useSelector((state) => state.auth);

  var initial_value,
    type,
    api_method,
    button = "";

  if (props.action == "edit") {
    initial_value = {
      name: props.data.value,
      parent_folder: props.data.type == "tag" ? props.data.parent_folder : "",
    };
    type = "Editing " + props.data.value;
    api_method = "PUT";
    button = "Update";
  } else {
    initial_value = {
      name: "",
      parent_folder: "",
    };
    type = "Create a " + props.data.type;
    api_method = "POST";
    button = "Create";
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => dispatch(closeModal())}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <ErrorBoundary>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll bg-light_background">
                        <div className="bg-dark_background py-6">
                          <div className=" px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {type}{" "}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md text-white hover:text-gray-200"
                                  onClick={() => dispatch(closeModal())}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <FontAwesomeIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                    icon={faTimes}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                          <Formik
                            initialValues={initial_value}
                            validationSchema={Yup.object({
                              name: Yup.string().required(
                                "You need to type in a name"
                              ),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, setErrors, resetForm }
                            ) => {
                              setSubmitting(true);
                              await axios({
                                method: api_method,
                                url:
                                  process.env.REACT_APP_API_URL +
                                  props.data.link,
                                data: {
                                  name: values.name,
                                  parent_folder: values.parent_folder,
                                  added_by: current_staff?.id,
                                },
                              })
                                .then(() => {
                                  toast(
                                    <NotificationToast
                                      title={values.name + " saved"}
                                      message={
                                        "This " +
                                        props.data.type +
                                        " will be usable by all users."
                                      }
                                    />
                                  );
                                })
                                .catch((error) => {
                                  toast(
                                    <NotificationToast
                                      title="Something went wrong"
                                      message={error.response}
                                      type="error"
                                    />
                                  );
                                })
                                .finally(() => {
                                  if (props.callback == "getTemplates") {
                                    dispatch(getTemplates());
                                  } else if (
                                    props.callback == "getTemplateCategories"
                                  ) {
                                    dispatch(getTemplateCategories());
                                  } else if (props.callback == "getTags") {
                                    dispatch(getTags());
                                  } else if (props.callback == "getFolders") {
                                    dispatch(getFolders());
                                  } else if (
                                    props.callback == "getCollections"
                                  ) {
                                    dispatch(getCollections());
                                  } else if (
                                    props.callback == "getAppointments"
                                  ) {
                                    dispatch(getAppointments());
                                  } else if (props.callback == "getRenewals") {
                                    dispatch(getRenewals());
                                  } else {
                                    dispatch(props.callback);
                                  }

                                  dispatch(closeModal());

                                  resetForm();
                                });
                            }}
                          >
                            {({
                              values,
                              errors,
                              onChange,
                              isValid,
                              dirty,
                              handleSubmit,
                              setFieldValue,
                              isSubmitting,
                            }) => (
                              <Form
                                onSubmit={handleSubmit}
                                className="space-y-8"
                              >
                                <SettingsTextInput
                                  name="name"
                                  placeholder="Name"
                                  errors={errors.name}
                                  value={values.name}
                                />

                                {(props.data.type == "tag" ||
                                  props.data.type == "Tag") && (
                                  <SettingsTextInput
                                    name="parent_folder"
                                    placeholder="Residential or Historical"
                                    errors={errors.parent_folder}
                                    value={values.parent_folder}
                                  />
                                )}

                                <div className="flex flex-shrink-0 space-x-4 justify-end px-4 py-4">
                                  <CustomButton
                                    type="button"
                                    onClick={() => dispatch(closeModal())}
                                    text="Cancel"
                                  />
                                  <SubmitButton
                                    name="Submit"
                                    disabled={
                                      !isValid || isSubmitting || !dirty
                                    }
                                    isSubmitting={isSubmitting}
                                    text={button}
                                  />
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
