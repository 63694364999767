import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default function ReportsAdditionalInvoices() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const { tags } = useSelector((state) => state.tags);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [tagOptions, setTagOptions] = useState([]);

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Additional Invoices</h1>

                <Formik
                    initialValues={{
                        property_id: 0,
                        fromDate: "",
                        toDate: "",
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/additional_invoices`,
                            responseType: "blob",
                            data: { property_id: values.property_id, fromDate: fromDate, toDate: toDate },
                        })
                            .then((response) => {
                                console.log(response.data);

                                const href = URL.createObjectURL(response.data);

                                const link = document.createElement("a");
                                link.href = href;
                                link.setAttribute("download", "Additional Invoices.xlsx");
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid md:lg:grid-cols-3 gap-12 mt-5 mb-5 ">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">From Date</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">To Date</label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            required
                                        />
                                    </div>
                                </div>
                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Generate Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
