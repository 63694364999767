import React from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { signOutUser } from "../../store/auth/authActions";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "500px",
        width: "100%",
        padding: "50px",
    },
};
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ( { isOpen, onRequestClose } ) =>
{
    const { current_staff } = useSelector( ( state ) => state.auth );
    const dispatch = useDispatch();
    const onLogOffCall = () =>
    {
        axios( {
            method: "post",
            url: `${ process.env.REACT_APP_API_URL }logout`,
            headers: {
                Authorization: `Bearer ${ current_staff?.token }`,
            },
        } )
            .then( ( res ) =>
            {
                dispatch( signOutUser() );
                window.location.replace( "/login" );
            } )
            .catch( ( error ) =>
            {
                //Error, user probably already logged out
                console.log( error );
                dispatch( signOutUser() );

                window.location.replace( "/login" );
            } );
    }

    return (
        <div>
            <Modal
                isOpen={ isOpen }
                style={ customStyles }
                contentLabel="TimedOut"
                ariaHideApp={ false }
            >
                <h1 className='text-2xl text-primary mb-4'>Session Timeout</h1>
                <p className='text-sm text-gray-800'>You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you will be logged off automatically</p>
                <div className='mt-8 flex justify-end space-x-4'>
                    <button onClick={ onLogOffCall } className="bg-gray-600 px-6 py-2 rounded-md text-white hover:text-gray-100 hover:bg-gray-800 focus:ring-bg-gray-800 ">Log off</button>
                    <button onClick={ onRequestClose } className="bg-primary px-6 py-2 rounded-md text-white hover:text-gray-100 hover:bg-primary_hover focus:ring-primary">Stay Logged In</button>
                </div>
            </Modal>
        </div>
    );
}
