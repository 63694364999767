import {
    GET_FLOORS_STARTED,
    GET_FLOORS_SUCCESS,
    GET_FLOORS_FAILED,
} from "./floorConstants";

const initialState = {
    floors: [],
    loading: false,
    error: "",
};

export default function floorReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_FLOORS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_FLOORS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                floors: payload.floors,
            };
        case GET_FLOORS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}
