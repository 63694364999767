import { GET_RENEWAL_STARTED, GET_RENEWAL_SUCCESS, GET_RENEWAL_FAILED } from "./renewalConstants";
import axios from "axios";

export const getRenewals = () => {
    return (dispatch) => {
        dispatch(getRenewalsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}renewals`,
        })
            .then((response) => {
                dispatch(getRenewalsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getRenewalsFailed(error.response));
            });
    };
};

const getRenewalsStarted = () => {
    return {
        type: GET_RENEWAL_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getRenewalsSuccess = (renewals) => {
    return {
        type: GET_RENEWAL_SUCCESS,
        payload: {
            renewals,
        },
    };
};

const getRenewalsFailed = (error) => {
    return {
        type: GET_RENEWAL_FAILED,
        payload: {
            error,
        },
    };
};
