import {
    GET_MANAGERS_STARTED,
    GET_MANAGERS_SUCCESS,
    GET_MANAGERS_FAILED,
} from "./managersConstants";

const initialState = {
    managers: {},
    loading: false,
    error: null,
};

export default function managersReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_MANAGERS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_MANAGERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                managers: payload.managers.managers,
            };
        case GET_MANAGERS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
