import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import DatePicker from "react-datepicker";

const getDefaultDates = () => {
    const today = new Date();

    // Create a new date object representing the first day of the current month
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    return {
        preFromDate: firstDayOfMonth,
        preToDate: today,
    };
};

export default function ReportsLiveDashboard() {
    const { current_staff } = useSelector((state) => state.auth);
    const history = useHistory();
    const { properties } = useSelector((state) => state.properties);
    const [chosenProp, setChosenProp] = useState("");

    const [loading, setLoading] = useState(true);
    const { preFromDate, preToDate } = getDefaultDates();
    const [fromDate, setFromDate] = useState(preFromDate);
    const [toDate, setToDate] = useState(preToDate);
    const [incomingLeads, setIncomingLeads] = useState([]);

    useEffect(() => {
        setLoading(true);

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/live_dashboard`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setIncomingLeads(response.data);
                setLoading(false);
            })
            .catch((error) => {
                alert("report failed");
            });
    }, [fromDate, toDate]);

    const download_stats = (type) => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/download_live_stats`,
            responseType: "blob",
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Stats.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const download_detail = (type) => {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/download_live_detail`,
            responseType: "blob",
            data: { fromDate: fromDate, toDate: toDate, type: type },
        })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", "Detail.xlsx");
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5 overflow-auto">
                <h1 className="text-center text-xl font-bold">Live Dashboard</h1>

                {loading ? (
                    <p>Loading</p>
                ) : (
                    <>
                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <h2>Filter</h2>

                            <div className="flex justify-start items-center space-x-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">From Date</label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(value) => {
                                            setFromDate(value);
                                        }}
                                        className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                        dateFormat={"d MMMM yyyy"}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">To Date</label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(value) => {
                                            setToDate(value);
                                        }}
                                        className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                        dateFormat={"d MMMM yyyy"}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <div className="flex justify-between items-center">
                                <h2>Number of incoming leads</h2>

                                <div className="flex space-x-2">
                                    <button className="bg-primary text-white px-4 py-2 rounded-md" onClick={() => download_stats(1)}>
                                        Download Stats
                                    </button>
                                    <button className="bg-primary text-white px-4 py-2 rounded-md" onClick={() => download_detail(1)}>
                                        Download Detail
                                    </button>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                        Property
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        No of Incoming Leads
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Average Response Time (Mins)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {incomingLeads &&
                                                    incomingLeads.length > 0 &&
                                                    incomingLeads.map((lead) => {
                                                        return (
                                                            <tr>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{lead?.property?.name}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.incoming_leads}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.average_response_time}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <BarChart width={700} height={400} data={incomingLeads}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="property.name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="incoming_leads" fill="#8884d8" />
                                </BarChart>
                            </div>
                        </div>

                        <hr />

                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <div className="flex justify-between items-center">
                                <h2>Number of Applications</h2>

                                <div className="flex space-x-2">
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Stats</button>
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Detail</button>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                        Property
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        No of Applications
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {incomingLeads &&
                                                    incomingLeads.length > 0 &&
                                                    incomingLeads.map((lead) => {
                                                        return (
                                                            <tr>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{lead?.property?.name}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.applications}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <BarChart width={700} height={400} data={incomingLeads}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="property.name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="applications" fill="#8884d8" />
                                </BarChart>
                            </div>
                        </div>

                        <hr />

                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <div className="flex justify-between items-center">
                                <h2>Number of apps sent to Vetting</h2>

                                <div className="flex space-x-2">
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Stats</button>
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Detail</button>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                        Property
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        No of Applications
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Do we require a time of sorts?
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {incomingLeads &&
                                                    incomingLeads.length > 0 &&
                                                    incomingLeads.map((lead) => {
                                                        return (
                                                            <tr>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{lead?.property?.name}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.vetting}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <BarChart width={700} height={400} data={incomingLeads}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="property.name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="vetting" fill="#8884d8" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <div className="flex justify-between items-center">
                                <h2>Number of approved apps</h2>

                                <div className="flex space-x-2">
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Stats</button>
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Detail</button>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                        Property
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        No of Applications
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {incomingLeads &&
                                                    incomingLeads.length > 0 &&
                                                    incomingLeads.map((lead) => {
                                                        return (
                                                            <tr>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{lead?.property?.name}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.approved_apps}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <BarChart width={700} height={400} data={incomingLeads}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="property.name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="approved_apps" fill="#8884d8" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="my-8 bg-white rounded-3xl p-5 shadow-md">
                            <div className="flex justify-between items-center">
                                <h2>Number of declined apps</h2>

                                <div className="flex space-x-2">
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Stats</button>
                                    <button className="bg-primary text-white px-4 py-2 rounded-md">Download Detail</button>
                                </div>
                            </div>

                            <div className="mt-8">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                        Property
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        No of Applications
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200">
                                                {incomingLeads &&
                                                    incomingLeads.length > 0 &&
                                                    incomingLeads.map((lead) => {
                                                        return (
                                                            <tr>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{lead?.property?.name}</td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{lead.declined_apps}</td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <BarChart width={700} height={400} data={incomingLeads}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="property.name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="declined_apps" fill="#8884d8" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
