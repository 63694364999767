import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faLoader } from "@fortawesome/pro-regular-svg-icons";
import { Formik, Form, Field, FieldArray } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextarea from "../Forms/SettingsTextarea";
import CustomButton from "../Helpers/CustomButton";
import Select from "react-select";
import SettingsDatePicker from "../Forms/SettingsDatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import AppLoader from "../Helpers/AppLoader";

export default function ModalCustomInvoice(props) {
  let current_booking = props.current_booking;
  let invoice_id = props.invoice_id;
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { current_staff } = useSelector((state) => state.auth);
  const [loadingBtns, setLoadingBtns] = useState(false);
  const [fileId, setFileId] = useState(0);

  const [initialValues, setInitialValues] = useState([]);

  useEffect(async () => {
    setLoading(true);

    if (!loading && invoice_id > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_invoice/${invoice_id}`,
      })
        .then((response) => {
          let allInvoiceItems = [];
          let invoiceItems = response.data[1];

          {
            Object.keys(invoiceItems).map((key) =>
              allInvoiceItems.push({
                date: invoiceItems[key].date,
                allocation: invoiceItems[key].allocation,
                exclusive: invoiceItems[key].exclusive,
                vat: invoiceItems[key].vat,
                inclusive: invoiceItems[key].inclusive,
                id: invoiceItems[key].id,
              })
            );
          }

          let iniValues = {
            invoice_paid_at: response.data[0].invoice_paid_at,
            invoice: allInvoiceItems,
          };

          setInitialValues(iniValues);

          if (response.data[0].invoice_paid_at != null) {
            setDisabled(true);
          }
          setFileId(response.data[2]);

          setLoading(false);
        })
        .catch((error) => {
          alert("invoice load failed");
        });
    } else {
      let iniInvoiceInfo = [
        {
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Monthly Rental",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .monthly_rental,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .monthly_rental,
        },
      ];

      if (
        process.env.REACT_APP_COLOUR === "UC" ||
        process.env.REACT_APP_COLOUR === "UC"
      ) {
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Deposit",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .deposit_price,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .deposit_price,
        });
      }

      if (process.env.REACT_APP_COLOUR === "UC") {
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Booking Fee",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .booking_fee_price,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1]
              .booking_fee_price,
        });
      } else if (process.env.REACT_APP_COLOUR === "QL") {
        if (
          current_booking.rates[current_booking.rates.length - 1]
            .lease_surance == 1
        ) {
          iniInvoiceInfo.push({
            date:
              moment().format("Y") +
              "-" +
              moment().format("MM") +
              "-" +
              moment().format("D"),
            allocation: "Deposit Waiver Fee",
            vat: "",
            exclusive:
              current_booking.rates[current_booking.rates.length - 1]
                .surance_amount,
            inclusive:
              current_booking.rates[current_booking.rates.length - 1]
                .surance_amount,
          });
        }
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Parking Fee",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1].carport,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1].carport,
        });
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Admin Fee",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1].admin_fee,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1].admin_fee,
        });
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Key Deposit",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1].key_deposit,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1].key_deposit,
        });
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Effluent",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1].effluent,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1].effluent,
        });
        iniInvoiceInfo.push({
          date:
            moment().format("Y") +
            "-" +
            moment().format("MM") +
            "-" +
            moment().format("D"),
          allocation: "Refuse",
          vat: "",
          exclusive:
            current_booking.rates[current_booking.rates.length - 1].refuse,
          inclusive:
            current_booking.rates[current_booking.rates.length - 1].refuse,
        });
      }

      let initial = { invoice_paid_at: null, invoice: iniInvoiceInfo };

      setInitialValues(initial);
      setLoading(false);
    }
  }, []);

  async function downloadFile(file) {
    setLoadingBtns(true);
    await axios({
      method: "get",
      responseType: "blob",
      url: `${process.env.REACT_APP_API_URL}download_file/${file.id}`,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name + "." + file.extension);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setLoading(false));

    setLoadingBtns(false);
  }

  async function email_documents(file) {
    setLoadingBtns(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}email_invoice/${current_booking.id}`,
      data: {
        current_staff: current_staff,
        file: file.id,
        current_booking: current_booking,
      },
    })
      .then((response) => {
        toast(
          <NotificationToast
            title={"Invoice emailed"}
            message={"The email has sent succesfully."}
          />
        );
      })
      .catch((response) => {
        toast(
          <NotificationToast
            title={"Email failed"}
            message={response.response.data}
            type="error"
          />
        );
      });

    setLoadingBtns(false);
    dispatch(closeModal());
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <CustomButton
                    type="secondary"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    Close
                  </CustomButton>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900 text-center"
                    >
                      Invoice
                    </Dialog.Title>

                    {loading === true ? <AppLoader /> : ""}

                    <Formik
                      initialValues={initialValues}
                      enableReinitialize="true"
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        values.booking_id = current_booking.id;
                        values.added_by = current_staff.id;

                        if (invoice_id === 0) {
                          await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}new_invoice`,
                            data: values,
                          })
                            .then((response) => {
                              toast(
                                <NotificationToast
                                  title={"Invoice Created"}
                                  message={
                                    "A new custom Invoice has been created"
                                  }
                                />
                              );
                            })
                            .catch((error) => {
                              toast(
                                <NotificationToast
                                  title={"Invoice Issue"}
                                  message={"Failed to Save this invoice"}
                                  list={true}
                                  type="error"
                                />
                              );
                            });
                        } else {
                          values.invoice_id = invoice_id;

                          await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}edit_invoice`,
                            data: values,
                          })
                            .then((response) => {
                              toast(
                                <NotificationToast
                                  title={"Invoice Updated"}
                                  message={"Custom Invoice has been updated"}
                                />
                              );
                            })
                            .catch((error) => {
                              toast(
                                <NotificationToast
                                  title={"Invoice Issue"}
                                  message={"Failed to update this invoice"}
                                  list={true}
                                  type="error"
                                />
                              );
                            });
                        }

                        axios({
                          method: "get",
                          url: `${process.env.REACT_APP_API_URL}get_invoices/${current_booking.id}`,
                        }).then((response) => {
                          props.setInvoices(response.data);
                        });

                        setLoading(false);
                        dispatch(closeModal());
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setValues,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 m-4 w-full "
                        >
                          <div>
                            <div className="mb-5 flex">
                              <div>
                                <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                  {" "}
                                  Invoice Paid at
                                </label>
                                <Field
                                  name="invoice_paid_at"
                                  type="date"
                                  className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                  disabled={disabled}
                                />
                              </div>
                              {invoice_id && invoice_id > 0 ? (
                                <div className="flex grow flex-row-reverse">
                                  <div classname="grow">
                                    {!current_staff.building_manager ? (
                                      <>
                                        <button
                                          type="button"
                                          className="bg-primary w-full text-white rounded px-4 py-4 mb-2"
                                          disabled={loadingBtns}
                                          onClick={() => downloadFile(fileId)}
                                        >
                                          {" "}
                                          {loadingBtns === false ? (
                                            "DOWNLOAD"
                                          ) : (
                                            <>
                                              <FontAwesomeIcon
                                                className="w-4 h-4 animate-spin"
                                                icon={faLoader}
                                              />
                                              <span className="w-full">
                                                {"Loading"}
                                              </span>
                                            </>
                                          )}{" "}
                                        </button>

                                        <button
                                          type="button"
                                          className="bg-primary w-full text-white rounded px-4 py-4 mb-2"
                                          disabled={loadingBtns}
                                          onClick={() =>
                                            email_documents(fileId)
                                          }
                                        >
                                          {" "}
                                          {loadingBtns === false ? (
                                            "EMAIL"
                                          ) : (
                                            <>
                                              <FontAwesomeIcon
                                                className="w-4 h-4 animate-spin"
                                                icon={faLoader}
                                              />
                                              <span className="w-full">
                                                {"Loading"}
                                              </span>
                                            </>
                                          )}{" "}
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <hr className="mb-5" />

                            {initialValues.invoice_paid_at == null &&
                            !current_staff.building_manager ? (
                              <button
                                type="button"
                                className="bg-primary w-full text-white py-1 rounded"
                                onClick={() => {
                                  values.invoice.push({
                                    date:
                                      moment().format("Y") +
                                      "-" +
                                      moment().format("MM") +
                                      "-" +
                                      moment().format("D"),
                                    allocation: "",
                                    exclusive: "",
                                    vat: "",
                                    inclusive: "",
                                  });
                                  setValues(values);
                                }}
                                disabled={loadingBtns}
                              >
                                {" "}
                                ADD{" "}
                              </button>
                            ) : (
                              ""
                            )}

                            <FieldArray
                              name="invoice"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.invoice && values.invoice.length > 0
                                    ? values.invoice.map((friend, index) => (
                                        <div key={index} className="flex my-2">
                                          <Field
                                            name={`invoice.${index}.date`}
                                            type="date"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Date"
                                            disabled={disabled}
                                          />
                                          <Field
                                            name={`invoice.${index}.allocation`}
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Allocation"
                                            disabled={disabled}
                                          />
                                          <Field
                                            name={`invoice.${index}.exclusive`}
                                            type="number"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Exclusive"
                                            disabled={disabled}
                                          />
                                          <Field
                                            name={`invoice.${index}.vat`}
                                            type="number"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Vat"
                                            disabled={disabled}
                                          />
                                          <Field
                                            name={`invoice.${index}.inclusive`}
                                            type="number"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Inclusive"
                                            disabled={disabled}
                                          />

                                          {initialValues.invoice_paid_at ==
                                            null &&
                                          !current_staff.building_manager ? (
                                            <button
                                              type="button"
                                              className="bg-dark_background text-white py-2 px-5 rounded"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                              disabled={loadingBtns}
                                            >
                                              {" "}
                                              DELETE{" "}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      ))
                                    : ""}
                                  <div></div>
                                </div>
                              )}
                            />
                          </div>

                          <div className="flex space-x-6">
                            <CustomButton
                              type="secondary"
                              text="Close"
                              onClick={() => dispatch(closeModal())}
                            />
                            {initialValues.invoice_paid_at == null &&
                            !current_staff.building_manager ? (
                              <SubmitButton
                                name="Save"
                                disabled={!isValid || isSubmitting || !dirty}
                                isSubmitting={isSubmitting}
                                text="Save"
                                disasbled={loadingBtns}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
