import React, { Fragment, useState, useRef } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

export default function ModalMailRawInfo(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title
                  as="h1"
                  className="text-lg font-medium text-gray-900 capitalize text-center"
                >
                  Email Raw information
                </Dialog.Title>

                <Dialog.Panel>
                  <ErrorBoundary>
                    <div className="flex space-x-2 flex-col text-left">
                      <p className="text-lg font-medium text-gray-900 capitalize pl-2">
                        Message:
                      </p>
                      <div
                        className="mb-5 max-w-3xl dangerousPFormat"
                        dangerouslySetInnerHTML={{
                          __html:
                            props.d.message_html && props.d.message_html !== ""
                              ? props.d.message_html
                              : props.d.message,
                        }}
                      ></div>

                      <p className="text-lg font-medium text-gray-900 capitalize">
                        Time Stamp:
                      </p>
                      <div className="mb-5">
                        <p>{props.d.timestamp}</p>
                      </div>

                      <button
                        className="relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                        onClick={() => dispatch(closeModal())}
                      >
                        Close
                      </button>
                    </div>
                  </ErrorBoundary>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
