import {
    GET_APPOINTMENT_STARTED,
    GET_APPOINTMENT_SUCCESS,
    GET_APPOINTMENT_FAILED,
} from "./appointmentConstants";

const initialState = {
    appointments: {},
    loading: false,
    error: null,
};

export default function appointmentReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_APPOINTMENT_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                appointments: payload.appointments.appointments,
            };
        case GET_APPOINTMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
