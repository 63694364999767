import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default function ReportsCancellation() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);
    const [properties, setProperties] = useState([]);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const { tags } = useSelector((state) => state.tags);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [tagOptions, setTagOptions] = useState([]);


    useEffect(async () => {
        let tagsList = [];
        Object.keys(tags).forEach((key) => {
            tagsList.push({ label: tags[key].name, value: tags[key].id });
        });

        setTagOptions(tagsList);

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                let responseData = [{ id: 0, value: 0, label: "All" }];
                setProperties(responseData.concat(response.data));
            })
            .catch((error) => {
                alert("properties failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>All Signed Cancellation Forms</h1>

                <Formik
                    initialValues={{
                        property_id: 0,
                        folder: 0,
                        tag: 0,
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        await axios({
                            method: "post",
                            url: `${process.env.REACT_APP_API_URL}reports/export_cancellation`,
                            responseType: "blob",
                            data: { property_id: values.property_id, fromDate: fromDate, toDate: toDate},
                        })
                            .then((response) => {
                                console.log(response.data);

                                const href = URL.createObjectURL(response.data);

                                const link = document.createElement("a");
                                link.href = href;
                                link.setAttribute("download", "Cancellations.xlsx");
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect label="What is your desired Building?" name="property_id" onChange={setFieldValue} value={values.property_id} options={properties} />

                                    <div className="col-span-6 sm:col-span-3">
                                        <label className="block text-sm font-medium text-gray-700">From Date</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(value) => {
                                                setFromDate(value);
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                    <div className="col-span-6 sm:col-span-3">
                                        <label className="block text-sm font-medium text-gray-700">To Date</label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(value) => {
                                                setToDate(value);
                                            }}
                                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                            dateFormat={"d MMMM yyyy"}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />
                                    </div>
                                </div>
                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
