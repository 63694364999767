import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";

export default function CustomButton({ text, ...props }) {
    var buttonClass = `${props.styling} relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5`;

    switch (props.type) {
        case "danger":
            buttonClass += "border-red-600 bg-red-600 text-white hover:bg-red-500 focus:ring-red-500 focus:border-red-500";
            break;
        case "secondary":
            buttonClass += " bg-dark_background hover:bg-dark_background focus:ring-dark_background";
            break;
        case "secondary":
            buttonClass += " bg-dark_background hover:bg-dark_background focus:ring-dark_background";
            break;
        default:
            buttonClass += " bg-primary hover:bg-primary_hover focus:ring-primary";
            break;
    }

    if (props.loading)
        return (
            <button type="button" className={buttonClass} {...props}>
                <FontAwesomeIcon className="w-4 h-4 animate-spin" icon={faLoader} />
                <span className="w-full">{props.loadingtext ? props.loadingtext : "Loading"}</span>
            </button>
        );
    return (
        <button type="button" className={buttonClass} {...props}>
            {props.icon && (
                <span className="w-4">
                    <FontAwesomeIcon className="w-4 h-4" icon={props.icon} />
                </span>
            )}

            <span className="w-full">{text}</span>
        </button>
    );
}
