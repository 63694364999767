import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { getBooking } from "../../store/booking/bookingActions";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import reactSelect from "react-select";
import Select from "react-select";
import CustomButton from "../Helpers/CustomButton";
import NotificationToast from "../../components/Helpers/NotificationToast";
import { toast } from "react-toastify";
import AppLoader from "../Helpers/AppLoader";

export default function ModalMDAChooseUnit(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [allUnits, setAllUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState();
  const [creating, setCreating] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  const current_booking = props.current_booking;

  useEffect(async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}mda/get_property_units/${current_booking.id}`,
    })
      .then((res) => {
        let units_1 = res.data;
        let new_units = [];
        Object.keys(units_1).forEach((key) => {
          new_units.push({
            value: units_1[key].Id,
            label: units_1[key].Description,
          });
        });
        setAllUnits(new_units);
        setPageLoading(false);
      })
      .catch((error) => {
        alert("Error loading Units");
        setPageLoading(false);
      });
  }, []);

  async function createLease() {
    setCreating(true);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}mda/create_manual_lease/${current_booking.user.id}/${current_booking.id}`,
      data: {
        selectedUnit: selectedUnit,
      },
    })
      .then((res) => {
        if (res.data === "done") {
          dispatch(getBooking(current_booking.id));
          toast(
            <NotificationToast
              title={"Lease Created"}
              message={"A lease has been created on MDA"}
            />
          );
          dispatch(closeModal());
        } else {
          toast(
            <NotificationToast
              title={"MDA Issue"}
              message={res.data}
              type="error"
            />,
            {
              autoClose: false,
            }
          );
        }
      })
      .catch((error) => {
        toast(
          <NotificationToast
            title={"MDA Issue"}
            message={error.data}
            type="error"
          />
        );
      });
    setCreating(false);
  }

  if (pageLoading === true) return <AppLoader />;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-2"
                style={{ height: "470px" }}
              >
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h1"
                    className="text-lg font-medium text-gray-900"
                  >
                    <div>MDA Unit not Found, Please map it manually</div>

                    <div className="mt-5">
                      {/*  */}
                      <div className="col-span-6 sm:col-span-3">
                        <label className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                          Units:
                        </label>
                        <Select
                          options={allUnits}
                          onChange={(value) => setSelectedUnit(value.id)}
                          className="mt-1 block font-light rounded-lg py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 z-50"
                        />
                        <br />
                        <CustomButton
                          text={"Close"}
                          type="secondary"
                          onClick={() => dispatch(closeModal())}
                          style={{
                            float: "right",
                            marginTop: "230px",
                            marginRight: "10px",
                          }}
                          loading={creating ? 1 : 0}
                          loadingtext="Creating Lease..."
                        />
                        <CustomButton
                          text={"Create Lease"}
                          onClick={() => createLease()}
                          style={{
                            float: "right",
                            marginTop: "230px",
                            marginRight: "10px",
                          }}
                          loading={creating ? 1 : 0}
                          loadingtext="Creating Lease..."
                        />
                      </div>
                    </div>
                  </Dialog.Title>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
