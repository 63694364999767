import {
    GET_TEMPLATES_STARTED,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILED,
    GET_WHATSAPP_TEMPLATES_STARTED,
    GET_WHATSAPP_TEMPLATES_SUCCESS,
    GET_WHATSAPP_TEMPLATES_FAILED,
    GET_TEMPLATE_CATEGORIES_STARTED,
    GET_TEMPLATE_CATEGORIES_SUCCESS,
    GET_TEMPLATE_CATEGORIES_FAILED,
} from "./templateConstants";
import axios from "axios";

export const getTemplates = () => {
    return (dispatch) => {
        dispatch(getTemplatesStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}templates`,
        })
            .then((response) => {
                dispatch(getTemplatesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getTemplatesFailed(error.data));
            });
    };
};

export const getWhatsappTemplates = () => {
    return (dispatch) => {
        dispatch(getWhatsappTemplatesStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}whatsapp_templates`,
        })
            .then((response) => {
                dispatch(getWhatsappTemplatesSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getWhatsappTemplatesFailed(error.data));
            });
    };
};

export const getTemplateCategories = () => {
    return (dispatch) => {
        dispatch(getTemplateCategoriesStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}template_categories`,
        })
            .then((response) => {                
                dispatch(getTemplateCategoriesSuccess(response.data));
            })
            .catch((error) => {                
                dispatch(getTemplateCategoriesFailed(error.message));
            });
    };
};

const getTemplatesStarted = () => {
    return {
        type: GET_TEMPLATES_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getWhatsappTemplatesStarted = () => {
    return {
        type: GET_WHATSAPP_TEMPLATES_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getTemplatesSuccess = (templates) => {
    return {
        type: GET_TEMPLATES_SUCCESS,
        payload: {
            templates,
        },
    };
};

const getTemplatesFailed = (error) => {
    return {
        type: GET_TEMPLATES_FAILED,
        payload: {
            error,
        },
    };
};

const getWhatsappTemplatesSuccess = (templates) => {
    return {
        type: GET_WHATSAPP_TEMPLATES_SUCCESS,
        payload: {
            templates,
        },
    };
};

const getWhatsappTemplatesFailed = (error) => {
    return {
        type: GET_WHATSAPP_TEMPLATES_FAILED,
        payload: {
            error,
        },
    };
};

const getTemplateCategoriesStarted = () => {
    return {
        type: GET_TEMPLATE_CATEGORIES_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getTemplateCategoriesSuccess = (template_categories) => {
    return {
        type: GET_TEMPLATE_CATEGORIES_SUCCESS,
        payload: {
            template_categories,
        },
    };
};

const getTemplateCategoriesFailed = (error) => {
    return {
        type: GET_TEMPLATE_CATEGORIES_FAILED,
        payload: {
            error,
        },
    };
};
