import { GET_SETTINGS_STARTED, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILED } from "./settingsConstants";
import axios from "axios";

export const getSettings = () => {
    return (dispatch) => {
        dispatch(getSettingsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}system_settings`,
        })
            .then((response) => {
                dispatch(getSettingsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getSettingsFailed(error.response));
            });
    };
};

const getSettingsStarted = () => {
    return {
        type: GET_SETTINGS_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getSettingsSuccess = (settings) => {
    return {
        type: GET_SETTINGS_SUCCESS,
        payload: {
            settings,
        },
    };
};

const getSettingsFailed = (error) => {
    return {
        type: GET_SETTINGS_FAILED,
        payload: {
            error,
        },
    };
};
