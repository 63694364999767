import { SIGN_IN_USER_STARTED, SIGN_IN_USER_SUCCESS, SIGN_IN_USER_FAILED, SIGN_OUT_USER, SIGN_IN_TOKEN, GET_STAFF_STARTED, GET_STAFF_SUCCESS, GET_STAFF_FAILED } from "./authConstants";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
    authenticated: false,
    current_staff: null,
    prevLocation: null,
    currentLocation: null,
    token: null,
    staff: {},
};

export default function authReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SIGN_IN_USER_STARTED:
            return {
                ...state,
                loading: true,
            };
        case SIGN_IN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: true,
                current_staff: {
                    id: payload.user.user.id,
                    name: payload.user.user.name,
                    surname: payload.user.user.surname,
                    email: payload.user.user.email,
                    profile_picture: payload.user.user.profile_picture,
                    preferred_folder: payload.user.user.preferred_folder,
                    preferred_tags: payload.user.user.preferred_tags,
                    status: payload.user.user.status,
                    building_manager: payload.user.user.building_manager,
                    bm_property_id: payload.user.user.bm_property_id,
                    restrict_views: payload.user.user.restrict_views,
                    is_broker: payload.user.user.is_broker,
                    create_order: payload.user.user.create_order,
                    is_plumber: payload.user.user.is_plumber,
                    is_electrician: payload.user.user.is_electrician,
                    isSuper: payload.user.user.isSuper,
                    canEditApplicantFields: payload.user.user.canEditApplicantFields,
                    hideLeasing: payload.user.user.hideLeasing,
                },
                token: payload.user.token,
            };
        case SIGN_IN_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case SIGN_IN_TOKEN:
            return {
                ...state,
                authenticated: true,
                current_staff: {
                    token: payload.token,
                },
            };
        case SIGN_OUT_USER:
            return {
                ...state,
                authenticated: false,
                current_staff: null,
                token: null,
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                prevLocation: state.currentLocation,
                currentLocation: payload.location,
            };
        case GET_STAFF_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_STAFF_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                staff: payload.staff,
            };
        case GET_STAFF_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}
