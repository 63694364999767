import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFileSignature,
  faNote,
} from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";

import BookingPlatformButton from "../Booking/BookingPlatformButton";
import BookingTemplate from "../Booking/BookingTemplate";
import BookingWysiwyg from "../Booking/BookingWysiwyg";
import CustomButton from "../Helpers/CustomButton";
import { Formik, Form } from "formik";
import EmojiPicker from "../Forms/EmojiPicker";
import * as Yup from "yup";
import BookingPlaceholder from "../Booking/BookingPlaceholder";
import SubmitButton from "../Forms/SubmitButton";
import NotificationToast from "../Helpers/NotificationToast";
import Select from "react-select";
import SettingsSelect from "../Forms/SettingsSelect";

export default function ModalBMCustomUC(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { staff, current_staff } = useSelector((state) => state.auth);
  const { current_booking } = useSelector((state) => state.booking);
  const { properties } = useSelector((state) => state.properties);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [selectedBM, setSelectedBM] = useState("");
  const [selectedBMDetails, setSelectedBMDetails] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [allowGenerate, setAllowGenerate] = useState(false);
  const [moveOutUrl, setMoveOutUrl] = useState("");
  const [units, setUnits] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [beds, setBeds] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [floors, setFloors] = useState([]);
  const [historicBookings, setHistoricBookings] = useState([]);
  const [historicBookingsData, setHistoricBookingsData] = useState([]);

  const [propName, setPropName] = useState("");
  const [propUnitTypes, setPropUnitTypes] = useState("");
  const [propUnit, setPropUnit] = useState("");
  const [propFloor, setPropFloor] = useState("");
  const [propBed, setPropBed] = useState("");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border:
        state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
      cursor: "pointer",
      "&:hover": {
        border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
        cursor: "pointer",
      },
      borderRadius: "10px",
      padding: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      padding: 0,
      border: 0,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#e7ebe5",
    }),
  };

  useEffect(async () => {
    if (buildingManagers.length === 0) {
      let staffList = [];
      staff.forEach(function (value) {
        if (value.building_manager == 1) {
          staffList.push({
            value: value.id,
            label: value.name + " " + value.surname,
          });
        }
      });
      setBuildingManagers(staffList);
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}booking_history`,
      data: { user_id: current_booking?.user?.id },
    })
      .then((response) => {
        setHistoricBookingsData(response.data);
        let list = [];
        let bookingsList = response.data;

        bookingsList.map((booking) => {
          list.push({
            value: booking.id,
            label: booking.booking_reference + " | " + booking?.year?.year,
          });
        });
        setHistoricBookings(list);
      })
      .catch((error) => {
        console.log(error);
      });

    // if(selectedBM !== ""){
    //     staff.forEach(function(value){
    //         if(value.id == selectedBM){
    //             setSelectedBMDetails(value)
    //         }
    //     })
    // }

    // if(allowGenerate === true){
    //   setAllowGenerate(false);
    //     let move_out = await axios( {
    //           method: "post",
    //           url: `${ process.env.REACT_APP_API_URL }move_out_link_custom`,
    //           data: {booking_id: current_booking.id}
    //       });

    //       setMoveOutUrl(move_out.data)
    // }

    if (process.env.REACT_APP_COLOUR === "UC") {
      getUnitTypes(current_booking.property_id);
      getUnits(current_booking.property_id);
      getBeds(current_booking.unit_id);
      getFloors(current_booking.property_id);
    }
  }, []);

  async function getUnits(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}units/${id}`,
    }).then((response) => {
      setUnits(response.data);
    });
  }

  async function getUnitTypes(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
    }).then((response) => {
      setUnitTypes(response.data);
    });
  }

  async function getBeds(id) {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}bed_list/${id}`,
    }).then((response) => {
      setBeds(response.data);
    });
  }

  async function getFloors(id) {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_property_floors/${id}/${current_booking?.user?.profile?.gender}`,
    })
      .then((response) => {
        setFloors(response.data.floors);
        setUnitTypes(response.data.unit_types);
      })
      .catch((error) => {
        alert("properties failed");
      });
  }

  async function generateLink(values) {
    console.log(values);

    Object.keys(properties).forEach((key) => {
      if (properties[key].value === values.property_id) {
        setPropName(properties[key].label);
      }
    });

    Object.keys(unitTypes).forEach((key) => {
      if (unitTypes[key].value === values.room_type) {
        setPropUnitTypes(unitTypes[key].label);
      }
    });

    Object.keys(units).forEach((key) => {
      if (units[key].value === values.unit_id) {
        setPropUnit(units[key].label);
      }
    });

    Object.keys(floors).forEach((key) => {
      if (floors[key].value === values.floor_id) {
        setPropFloor(floors[key].label);
      }
    });

    Object.keys(beds).forEach((key) => {
      if (beds[key].value === values.bed_id) {
        setPropBed(beds[key].label);
      }
    });

    let move_out = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}move_out_link_custom`,
      data: {
        property_id: values.property_id,
        unit_type: values.room_type,
        unit_id: values.unit_id,
        booking_id: values.active_booking,
        floor_id: values.floor_id,
        bed_id: values.bed_id,
      },
    });

    setMoveOutUrl(move_out.data);
    setShowMsg(true);
  }

  const updateBookingForm = (data, fieldUpdate) => {
    historicBookingsData.map((booking) => {
      if (booking.id === data) {
        getUnitTypes(booking.property_id);
        getUnits(booking.property_id);
        getFloors(booking.property_id);
        getBeds(booking.unit_id);

        fieldUpdate("property_id", booking.property_id);
        fieldUpdate("room_type", booking.unit_type?.id);
        fieldUpdate("unit_id", booking.unit_id);
        fieldUpdate("floor_id", booking.floor_id);
        fieldUpdate("bed_id", booking.bed_id);
        fieldUpdate("booking", booking.id);
        fieldUpdate("active_booking", booking.id);

        fieldUpdate(
          "message",
          "<b>Move out form</b><br/><br/>Link for: " +
            propName +
            ", " +
            propUnitTypes +
            ", " +
            propFloor +
            ", " +
            propUnit +
            ", " +
            propBed +
            " <br/><br/><p><a href='" +
            process.env.REACT_APP_LARAVEL_URL +
            "move-out-form/" +
            moveOutUrl +
            "'>" +
            process.env.REACT_APP_LARAVEL_URL +
            "move-out-form/" +
            moveOutUrl +
            "</a></p>"
        );
      }
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Post Custom move out form
                      {props.heading}
                    </Dialog.Title>
                    <div className="mt-2 text-gray-500">{props.body}</div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <div className="ml-2">
                    <br />

                    <Formik
                      enableReinitialize
                      initialValues={{
                        property_id: current_booking.property_id
                          ? current_booking.property_id
                          : "",
                        room_type: current_booking?.unit_type?.id
                          ? current_booking?.unit_type?.id
                          : "",
                        unit_id: current_booking.unit_id
                          ? current_booking.unit_id
                          : "",
                        floor_id: current_booking.floor_id
                          ? current_booking.floor_id
                          : "",
                        bed_id: current_booking.bed_id
                          ? current_booking.bed_id
                          : "",
                        message:
                          "<b>Move out form</b><br/><br/>Link for: " +
                          propName +
                          ", " +
                          propUnitTypes +
                          ", " +
                          propFloor +
                          ", " +
                          propUnit +
                          ", " +
                          propBed +
                          " <br/><br/><p><a href='" +
                          process.env.REACT_APP_LARAVEL_URL +
                          "move-out-form/" +
                          moveOutUrl +
                          "'>" +
                          process.env.REACT_APP_LARAVEL_URL +
                          "move-out-form/" +
                          moveOutUrl +
                          "</a></p>",
                        platform: "note",
                        from: current_staff?.id,
                        to: "",
                        files: [],
                        template: 0,
                        booking: current_booking.id,
                        active_booking: current_booking.id,
                      }}
                      validateOnChange={false}
                      validateOnBlur={false}
                      validationSchema={Yup.object().shape({
                        platform: Yup.string().required(
                          "Message platform is not selected"
                        ),
                        message: Yup.string().when("platform", {
                          is: (value) => value && value != "whatsapp",
                          then: Yup.string().required("Message is empty"),
                        }),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        const the_message = values.message;
                        var template = values.template;
                        let replaced_message = values.message;

                        let regards = "The Qliving Team";
                        if (process.env.REACT_APP_COLOUR === "UC") {
                          regards = "The Urban Circle Team";
                        } else if (process.env.REACT_APP_COLOUR === "QP") {
                          regards = "The Quorum Properties Team";
                        }

                        replaced_message = the_message.replace(
                          "[name]",
                          current_booking?.user?.name
                        );
                        replaced_message = replaced_message.replace(
                          "[me]",
                          current_staff?.name
                        );
                        replaced_message = replaced_message.replace(
                          "[regards]",
                          "Kind Regards, " + regards
                        );
                        replaced_message = replaced_message.replace(
                          "[surname]",
                          current_booking?.user?.surname
                        );
                        replaced_message = replaced_message.replace(
                          "[email]",
                          current_booking?.user?.email
                        );
                        replaced_message = replaced_message.replace(
                          "[booking_reference]",
                          current_booking?.booking_reference
                        );
                        replaced_message = replaced_message.replace(
                          "[edit_profile]",
                          window.location.origin +
                            "/edit_application/" +
                            current_booking?.edit_link !==
                            null
                            ? current_booking?.edit_link?.edit_url
                            : current_booking.user.edit_url
                        );
                        replaced_message = replaced_message.replace(
                          "[building name]",
                          current_booking?.property?.name
                        );

                        replaced_message = replaced_message.replace(
                          "[bank_details]",
                          window.location.origin + "/bank-details/"
                        );
                        replaced_message = replaced_message.replace(
                          "[payfast_link]",
                          window.location.origin +
                            "/payfast/" +
                            current_booking?.edit_link !==
                            null
                            ? current_booking?.edit_link?.edit_url
                            : current_booking.user.edit_url
                        );

                        replaced_message = replaced_message.replace(
                          "[Move_In_Inspection]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/17/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/16/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/15/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Move_In_Inspection/18/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[Move_Out_Inspection]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/13/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/12/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/11/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Move_Out_Inspection/14/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[Viewing]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/Viewing/7/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/Viewing/9/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/Viewing/8/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/Viewing/10/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        replaced_message = replaced_message.replace(
                          "[MaintenanceCall]",
                          current_booking?.property_id == 1
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/6/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 2
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/5/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 4
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/4/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : current_booking?.property_id == 3 ||
                              current_booking?.property_id == 6 ||
                              current_booking?.property_id == 7
                            ? window.location.origin +
                                "/appointment/MaintenanceCall/3/0/0/" +
                                current_booking?.edit_link !==
                              null
                              ? current_booking?.edit_link?.edit_url
                              : current_booking.user.edit_url
                            : ""
                        );

                        // replaced_message = replaced_message.replace("[move_in_inspection]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-in-inspection" : current_booking?.property_id == 2  ? "https://calendly.com/forestviews/30min-1" : current_booking?.property_id == 4  ? "https://calendly.com/delvedeeper/move-in-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/move-in-inspection" : "");

                        // replaced_message = replaced_message.replace("[move_out_inspection]",  current_booking?.property_id == 1 ? "https://calendly.com/theox-1/move-out-inspection" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/move-out-inspection" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/move-out-inspection" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? " https://calendly.com/the-unison/move-out" : "");

                        // replaced_message = replaced_message.replace("[book_a_viewing]", current_booking?.property_id == 1 ? "https://calendly.com/theox-1/viewing-at-the-ox" : current_booking?.property_id == 2 ? "https://calendly.com/forestviews/book-a-viewing" : current_booking?.property_id == 4 ? "https://calendly.com/delvedeeper/viewings" : current_booking?.property_id == 3 || current_booking?.property_id == 6 || current_booking?.property_id == 7 ? "https://calendly.com/the-unison/30min" : "");

                        var form_data = new FormData();

                        var timestamp = new Date();
                        var platform = values.platform;
                        var from = current_staff?.id;
                        var to = values.to;
                        var user_id = current_booking.user.id;

                        form_data.append("timestamp", timestamp);
                        form_data.append("message", replaced_message);
                        form_data.append(
                          "conversation_id",
                          current_booking.user.conversation_id
                        );
                        form_data.append("platform", platform);
                        form_data.append("from", from);
                        form_data.append("booking", values.active_booking);
                        form_data.append("to", to);
                        form_data.append("user_id", user_id);
                        form_data.append("staff_id", current_staff.id);
                        form_data.append("template", template);
                        form_data.append("to_bm", 1);
                        // form_data.append("bm_id", selectedBM);

                        await axios
                          .post(
                            `${process.env.REACT_APP_API_URL}send`,
                            form_data
                          )
                          .then((response) => {
                            if (template) {
                              let regards = "The Qliving Team";
                              if (process.env.REACT_APP_COLOUR === "UC") {
                                regards = "The Urban Circle Team";
                              } else if (
                                process.env.REACT_APP_COLOUR === "QP"
                              ) {
                                regards = "The Quorum Properties Team";
                              }

                              replaced_message = the_message.replace(
                                "[name]",
                                current_booking.user.name
                              );
                              replaced_message = replaced_message.replace(
                                "[me]",
                                current_staff.name
                              );
                              replaced_message = replaced_message.replace(
                                "[regards]",
                                "Kind Regards, " + regards
                              );
                              replaced_message = replaced_message.replace(
                                "[surname]",
                                current_booking.user.surname
                              );
                              replaced_message = replaced_message.replace(
                                "[email]",
                                current_booking.user.email
                              );
                              replaced_message = replaced_message.replace(
                                "[booking_reference]",
                                current_booking?.booking_reference
                              );
                              replaced_message = replaced_message.replace(
                                "[edit_profile]",
                                window.location.origin +
                                  "/edit_application/" +
                                  current_booking?.edit_link !==
                                  null
                                  ? current_booking?.edit_link?.edit_url
                                  : current_booking.user.edit_url
                              );
                            }

                            toast(
                              <NotificationToast
                                title={values.platform + " successful"}
                                message={"Your message has been sent."}
                              />
                            );
                            resetForm();
                          })
                          .catch((error) => {
                            console.log(error);
                            toast(
                              <NotificationToast
                                title="Something went wrong"
                                message={JSON.stringify(error.response.data)}
                                type="error"
                              />,
                              {
                                autoClose: false,
                              }
                            );
                          });

                        setSubmitting(false);
                        setShowMsg(false);
                        dispatch(closeModal());
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        handleSubmit,
                        isValid,
                        isSubmitting,
                        dirty,
                        setFieldTouched,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="rounded-3xl w-full bottom-0"
                        >
                          {showMsg === false ? (
                            <>
                              <SettingsSelect
                                label="BOOKING"
                                name="active_booking"
                                required
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                updateBookingForm={(data) =>
                                  updateBookingForm(data, setFieldValue)
                                }
                                value={values.active_booking}
                                touched={touched.active_booking}
                                error={errors.active_booking}
                                options={historicBookings}
                                disabled={true}
                              />

                              <label
                                htmlFor="property_id"
                                className="text-sm font-medium text-gray-700"
                              >
                                PROPERTY
                              </label>

                              <Select
                                id="property_id"
                                name="property_id"
                                options={properties}
                                onChange={(val) => {
                                  setFieldValue("property_id", val.value);

                                  getUnitTypes(val.value);
                                  getUnits(val.value);
                                  getFloors(val.value);

                                  setFieldValue("floor_id", 0);
                                  setFieldValue("room_type", 0);
                                }}
                                onBlur={setFieldTouched}
                                value={
                                  properties && properties.length > 0
                                    ? properties.find(
                                        (option) =>
                                          option.value === values.property_id
                                      )
                                    : ""
                                }
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                styles={customStyles}
                                className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                isDisabled={true}
                              />
                              <SettingsSelect
                                label="ROOM TYPE"
                                name="room_type"
                                required
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.room_type}
                                touched={touched.room_type}
                                error={errors.room_type}
                                options={unitTypes}
                                disabled={true}
                              />
                              <SettingsSelect
                                label="FLOOR"
                                name="floor_id"
                                required
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.floor_id}
                                touched={touched.floor_id}
                                error={errors.floor_id}
                                options={floors}
                                disabled={true}
                              />
                              <SettingsSelect
                                label="UNIT"
                                name="unit_id"
                                required
                                getBeds={getBeds}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.unit_id}
                                touched={touched.unit_id}
                                error={errors.unit_id}
                                options={units}
                                disabled={true}
                              />
                              <SettingsSelect
                                label="BED"
                                name="bed_id"
                                required
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.bed_id}
                                touched={touched.bed_id}
                                error={errors.bed_id}
                                options={beds}
                                disabled={true}
                              />

                              <CustomButton
                                text="Generate Custom Move Out"
                                styling="w-full mt-2"
                                icon={faFileSignature}
                                onClick={() => {
                                  generateLink(values);
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}

                          {showMsg === true ? (
                            <>
                              <div className="flex justify-between pr-6 bg-dark_background pt-8 mt-5">
                                <div className="flex pl-6 space-x-4 h-8">
                                  <BookingPlatformButton
                                    valuePlatform={values.platform}
                                    buttonPlatform={"note"}
                                    icon={faNote}
                                    message={"Note"}
                                    from={current_staff?.id}
                                    to={""}
                                    setFieldValue={setFieldValue}
                                  />
                                </div>

                                <BookingTemplate
                                  platform={values.platform}
                                  setFieldValue={setFieldValue}
                                  handleSubmit={handleSubmit}
                                />
                              </div>
                              {values.platform === "" && (
                                <div className="px-6 py-4 block w-full sm:text-sm !outline-none resize-none bg-light_background h-full">
                                  Please choose a Platform to send from
                                </div>
                              )}

                              {values.platform === "note" && (
                                <BookingWysiwyg
                                  name="message"
                                  id="message"
                                  initialValues={values}
                                  setFieldValue={setFieldValue}
                                  staff={staff}
                                  value={values.message}
                                />
                              )}

                              {errors.message && (
                                <div className="px-6 inline-flex bg-light_background w-full">
                                  <p>&nbsp;</p>
                                  {errors.message && (
                                    <p className="text-sm text-red-400 mr-4">
                                      {errors.message}
                                    </p>
                                  )}
                                </div>
                              )}

                              <div className="px-6 py-4 ml-auto flex justify-between items-center space-x-5 bg-dark_background rounded-b-3xl">
                                <div className="flex">
                                  <EmojiPicker
                                    value={values.message}
                                    source="booking"
                                    setFieldValue={setFieldValue}
                                  />
                                  <BookingPlaceholder
                                    setFieldValue={setFieldValue}
                                    value={values.message}
                                    platform={values.platform}
                                  />
                                </div>
                                <div className="pr-4 ">
                                  {values.platform !== "" && (
                                    <SubmitButton
                                      name="Submit"
                                      disabled={
                                        !isValid || isSubmitting || !dirty
                                      }
                                      isSubmitting={isSubmitting}
                                      text="Post"
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
