import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { signInUser, signInToken, verifyAuth } from "../../store/auth/authActions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import CustomButton from "../../components/Helpers/CustomButton";

export default function LoginForm({ history }) {
    const [forgot, setForgot] = useState(false);
    const dispatch = useDispatch();
    // let location = useLocation();
    const { current_staff, authenticated } = useSelector((state) => state.auth);

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminSet, setAdminSet] = useState(false);

    useEffect(() => {
        // if (location.search) {
        //     let token = location.search.replace("?token=", "");

        //     dispatch(signInToken({ token: token }));

        //     history.push("/booking/unassigned");
        // }

        if (current_staff?.id !== "" && authenticated === true) {
            // console.log(current_staff);
            // history.push("/booking/all");
            // history.go();
            if (current_staff?.restrict_views === 1) {
                window.location.replace(process.env.REACT_APP_COLOUR === "UC" ? "/booking/mentions/0" : "/booking/mentions/0");
            } else {
                window.location.replace(process.env.REACT_APP_COLOUR === "UC" ? "/booking/newleads" : "/booking/unassigned");
            }
        }
    }, [current_staff, authenticated]);

    if (adminSet === false) {
        setAdminSet(true);
        if (process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP") {
            setIsAdmin(true);
        }
    }

    return (
        <div className="min-h-screen flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 xl:flex-none xl:px-24 ">
                <div className="mx-auto w-full max-w-sm xl:w-96">
                    <div>
                        <img
                            className="h-24 w-auto"
                            src={process.env.REACT_APP_COLOUR === "UC" ? "/images/logo_uc.svg" : process.env.REACT_APP_COLOUR === "QL" ? "/images/logo.svg" : "/images/logo-qholdings-v2.png"}
                            alt="Quorom Properties"
                        />
                        {isAdmin === false && <h2 className="mt-6 text-2xl font-bold text-gray-900">Are you a student?</h2>}
                        {isAdmin === true && <h2 className="mt-6 text-2xl font-bold text-gray-900">Sign in to your admin account</h2>}
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            {isAdmin === false && (
                                <>
                                    <Link to={"/"}>
                                        <button
                                            type="button"
                                            className="bg-primary hover:bg-primary_hover focus:ring-primary relative text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 w-full text-center"
                                        >
                                            {" "}
                                            Yes{" "}
                                        </button>
                                    </Link>

                                    <button
                                        type="button"
                                        className="bg-primary hover:bg-primary_hover focus:ring-primary relative text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 w-full text-center mt-5"
                                        onClick={() => setIsAdmin(true)}
                                    >
                                        {" "}
                                        No{" "}
                                    </button>
                                </>
                            )}

                            {isAdmin === true && (
                                <Formik
                                    hidden={forgot === true ? true : false}
                                    initialValues={{
                                        email: window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "roger@whitehart.co.za" : "",
                                        password: window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "adv-adh*xvt_ece7EVR" : "",
                                    }}
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.email) {
                                            errors.email = "Required";
                                        }
                                        if (forgot === false) {
                                            if (!values.password) {
                                                errors.password = "Required";
                                            }
                                        }
                                        return errors;
                                    }}
                                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                                        if (forgot === true) {
                                            console.log("password reset");
                                        } else {
                                            try {
                                                await dispatch(
                                                    signInUser({
                                                        email: values.email,
                                                        password: values.password,
                                                    })
                                                ).then((res) => {
                                                    // console.log(res);
                                                    if (res === "ok") {
                                                        // history.push("/booking/all");
                                                        // history.go();
                                                    } else {
                                                        setErrors({
                                                            auth: res,
                                                        });
                                                    }
                                                });
                                            } catch (error) {
                                                console.log("error.response", error.respones.data.message);

                                                if (error.response.data.message) {
                                                    setErrors({
                                                        auth: error.response.data.message,
                                                    });
                                                } else {
                                                    setErrors({
                                                        auth: "Something went wrong",
                                                    });
                                                }
                                            }
                                        }
                                    }}
                                >
                                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Field name="email" as={SettingsTextInput} type="email" label="Email Address" />
                                            <Field name="password" label="Password" as={SettingsTextInput} type="password" hidden={forgot === true ? true : false} />

                                            {errors.auth ? <p className="my-4 text-medium text-red-500">{errors.auth}</p> : ""}

                                            <div className="py-8 flex space-x-2">
                                                <SubmitButton disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} type="submit" name="submit" text="Sign in" />
                                                <Link to={"/forget-password"}>
                                                    <CustomButton text="Forgotten your Password?" />
                                                </Link>
                                            </div>
                                            <p className="text-sm uppercase">V 4.14.3</p>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden xl:block relative w-0 flex-1">
                <img className="absolute inset-0 h-full w-full object-cover" src={process.env.REACT_APP_COLOUR === "UC" ? "/images/login_bg_uc.jpg" : "/images/login_bg.jpg"} alt="Login Background" />
            </div>
        </div>
    );
}
