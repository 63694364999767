import { combineReducers } from "redux";
import modalReducer from "./modal/modalReducer";
import authReducer from "./auth/authReducer";
import asyncReducer from "./async/asyncReducer";
import bookingReducer from "./booking/bookingReducer";
import { connectRouter } from "connected-react-router";
import templateReducer from "./template/templateReducer";
import tagReducer from "./tag/tagReducer";
import propertyReducer from "./property/propertyReducer";
import floorReducer from "./floor/floorReducer";
import folderReducer from "./tag/folderReducer";
import collectionReducer from "./collection/collectionReducer";
import appointmentReducer from "./appointments/appointmentReducer";
import managersReducer from "./managers/managersReducer";
import settingsReducer from "./settings/settingsReducer";
import renewalReducer from "./renewal/renewalReducer";

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        modals: modalReducer,
        auth: authReducer,
        async: asyncReducer,
        booking: bookingReducer,
        templates: templateReducer,
        properties: propertyReducer,
        floors: floorReducer,
        tags: tagReducer,
        folders: folderReducer,
        collections: collectionReducer,
        appointments: appointmentReducer,
        managers: managersReducer,
        settings: settingsReducer,
        renewals: renewalReducer,
    });

export default rootReducer;
