import { GET_COLLECTION_STARTED, GET_COLLECTION_SUCCESS, GET_COLLECTION_FAILED } from "./collectionConstants";
import axios from "axios";

export const getCollections = () => {
    return (dispatch) => {
        dispatch(getCollectionsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}collections`,
        })
            .then((response) => {
                dispatch(getCollectionsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getCollectionsFailed(error.response));
            });
    };
};

const getCollectionsStarted = () => {
    return {
        type: GET_COLLECTION_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getCollectionsSuccess = (collections) => {
    return {
        type: GET_COLLECTION_SUCCESS,
        payload: {
            collections,
        },
    };
};

const getCollectionsFailed = (error) => {
    return {
        type: GET_COLLECTION_FAILED,
        payload: {
            error,
        },
    };
};
