import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Tab } from "@headlessui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faClock } from "@fortawesome/pro-regular-svg-icons";
import LoadingComponent from "../Helpers/LoadingComponent";
import { format } from "date-fns";
import CustomButton from "../Helpers/CustomButton";

export default function MoveInConfirm() {
    const [appointment, setAppointment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [appointmentNotFound, setAppointmentNotFound] = useState(false);
    const [managerNotFound, setManagerNotFound] = useState(false);

    const [activeAppointment, setActiveAppointment] = useState([]);
    const [doubleBooking, setDoubleBooking] = useState(false);

    const { appointment_id, manager_id } = useParams();

    useEffect(async () => {
        //get manager info
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}manager_details/${manager_id}/${appointment_id}`,
        }).then((response) => {
            if (response.data.length === 0) {
                setManagerNotFound(true);
            } else {
                setAppointment(response.data[1]);
                if (response.data[2].length > 0) {
                    setDoubleBooking(true);
                }
            }
        });

        //get appointment info
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}appointment_details/${appointment_id}`,
        }).then((response) => {
            if (response.data.length === 0) {
                setAppointmentNotFound(true);
            } else {
                setActiveAppointment(response.data);
            }
        });

        setLoading(false);
    }, []);

    async function confirmBooking() {
        setBtnLoading(true);

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}confirm_appointment`,
            data: { appointment_id: appointment_id, manager_id: manager_id },
        }).then((response) => {
            window.location.reload();
        });
    }

    if (loading === true) {
        return <LoadingComponent />;
    }

    return (
        <>
            {process.env.REACT_APP_COLOUR === "QL" || process.env.REACT_APP_COLOUR === "QP" ? (
                <>
                    <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="lg:flex">
                                {process.env.REACT_APP_COLOUR === "QL" ? (
                                    <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                                ) : (
                                    <img className="h-24" src="/images/logo-qholdings-v2.png" alt="Quorum Properties" />
                                )}
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                                    <h1 className="text-3xl text-white">Welcome to {process.env.REACT_APP_COLOUR === "QL" ? "QLiving" : "Quorum Properties"}</h1>
                                </div>
                            </div>
                        </header>
                    </div>
                </>
            ) : (
                <>
                    <div className="pb-32 elative bg-dark_background">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="md:flex items-center">
                                <img className="h-24 mx-auto" src="/images/logo_uc.svg" alt="Urban Circle" />
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center text-white text-4xl uppercase font-bold">
                                    <h1>APPOINTMENTS</h1>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className="-mt-32">
                        <div>
                            <div className="bg-white h-60 rounded-tl-3xl white_block_left">
                                <div className="h-20 flex">
                                    <div className="flex-1 grow bg-primary z-50 flex items-center px-11 shadow-md" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                        <div className="text-white sm:text-xs md:text-2xl font-bold uppercase">APPOINTMENT APPROVAL</div>
                                    </div>
                                    <div className="w-1/5 colour-uc2 z-40" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc3 z-30" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc4 z-20" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                    <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <main className="-mt-32">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div className="w-full">
                            <div className="xl:grid xl:grid-cols-12">
                                <Tab.Group selectedIndex="0">
                                    <Tab.List className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 rounded-3xl lg:rounded-l-3xl w-full xl:w-72 border-0 xl:rounded-r-none">
                                        <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-3xl xl:rounded-tr-none justify-center bg-dark_background">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize"></h1>
                                        </div>
                                        <Tab
                                            key="0"
                                            className="group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none border-l-primary bg-gray-100 text-primary"
                                        >
                                            <span className="truncate whitespace-normal text-left">
                                                {managerNotFound === false ? (
                                                    <>
                                                        {appointmentNotFound === true ? (
                                                            <>
                                                                <p className="font-semibold p-5">This appointment could not be found!</p>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="font-semibold">Appointment: {appointment_id}</p>
                                                                <p className="text-xl">
                                                                    <b>{appointment.appointment.name}</b>
                                                                </p>
                                                                <p className="font-semibold ">{(appointment?.property.name).split(" - ")[0]}</p>
                                                                <br />
                                                                <p className="font-semibold ">
                                                                    <FontAwesomeIcon icon={faLocationDot} /> {}
                                                                    {appointment.appointment.show_unit === 1 ? (appointment?.unit?.name ? " Unit " + appointment?.unit?.name : " Your Unit") : ""}
                                                                    {appointment.appointment.show_unit === 1 ? (appointment?.bed?.name ? " Unit " + appointment?.bed?.name : " ") + " @ " : ""}
                                                                    {(appointment?.property.name).split(" - ")[0]}: {appointment?.property.address}
                                                                </p>
                                                                <p className="font-semibold ">
                                                                    <FontAwesomeIcon icon={faClock} />{" "}
                                                                    {appointment.appointment.duration === 1
                                                                        ? "15 min"
                                                                        : appointment.appointment.duration === 2
                                                                        ? "30 min"
                                                                        : appointment.appointment.duration === 3
                                                                        ? "45 min"
                                                                        : appointment.appointment.duration === 4
                                                                        ? "1 hour"
                                                                        : appointment.appointment.duration === 5
                                                                        ? " 1 hour 15 min"
                                                                        : appointment.appointment.duration === 6
                                                                        ? "1 hour 30 min"
                                                                        : appointment.appointment.duration === 7
                                                                        ? "1 hour 45 min"
                                                                        : appointment.appointment.duration === 8
                                                                        ? "2 hours"
                                                                        : ""}
                                                                </p>
                                                                <p>{format(new Date(activeAppointment.booked_date), "ccc, dd MMMM yyyy")}</p>
                                                                <p>
                                                                    {format(new Date(activeAppointment.booked_date + " " + activeAppointment.booked_time), "HH:mm")} -{" "}
                                                                    {format(new Date(activeAppointment.booked_date + " " + activeAppointment.time_to), "HH:mm")}
                                                                </p>
                                                                <br />
                                                                <p>{appointment.description}</p>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p>You don't have access to view this page!</p>
                                                )}
                                            </span>
                                        </Tab>
                                    </Tab.List>
                                    <Tab.Panels className="xl:ml-20 my-6 xl:my-0 rounded-3xl xl:rounded-l-none xl:rounded-r-3xl bg-gray-100 xl:col-span-9">
                                        <Tab.Panel className="xl:col-span-9 pb-12">
                                            <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-none xl:rounded-tr-3xl justify-center bg-dark_background">
                                                <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Appointment Status</h1>
                                            </div>

                                            {managerNotFound === true ? (
                                                <>
                                                    <p>You don't have access to view this page!</p>
                                                </>
                                            ) : (
                                                <>
                                                    {appointmentNotFound === true ? (
                                                        <>
                                                            <p className="font-semibold p-5">This appointment could not be found!</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="m-2">
                                                                <p className="font-semibold">
                                                                    Appointment Status:{" "}
                                                                    {appointment.status === 1
                                                                        ? "Requested"
                                                                        : appointment.status === 0
                                                                        ? "Cancelled"
                                                                        : appointment.status === 2
                                                                        ? "Confirmed"
                                                                        : "Unknown"}
                                                                </p>

                                                                {doubleBooking === true ? (
                                                                    <>
                                                                        <br />
                                                                        <p>You already have another booking confirmed at this time.</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {appointment.status === 1 ? (
                                                                            <>
                                                                                <br />
                                                                                <CustomButton
                                                                                    text=" Confirm Appointment"
                                                                                    onClick={() => confirmBooking(false)}
                                                                                    loading={btnLoading}
                                                                                    disabled={btnLoading}
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Tab.Panel>
                                    </Tab.Panels>
                                </Tab.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
