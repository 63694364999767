import NumberFormat from "react-number-format";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function QuickFormText({ ...props }) {
    return (
        <div className="flex flex-col sm:col-span-1">
            <label htmlFor={props.name} className="text-sm text-dark_background">
                {props.label}
                {props.tooltip && <FontAwesomeIcon className="ml-1 cursor-help" title={props.tooltip} icon={faCircleQuestion} />}
            </label>
            {props.prepend ? (
                <div className="mt-1 flex rounded-md">
                    <span className="inline-flex items-center px-1 rounded-l-md border border-r-0 border-gray-200 bg-gray-50  font-light text-sm">{props.prepend}</span>
                    {props.currency ? (
                        <NumberFormat
                            {...props}
                            thousandSeparator={true}
                            allowNegative={true}
                            className="rounded-r-lg py-2 pl-1 truncate w-full xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                        />
                    ) : (
                        <input
                            {...props}
                            className="rounded-r-lg py-2 pl-1 truncate w-full xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                            value={props.value !== null ? props.value : ""}
                        />
                    )}
                    <p className="text-red-400 text-sm font-light">{props.errors}</p>
                </div>
            ) : (
                <>
                    <input
                        {...props}
                        className="rounded-lg py-2 pl-2 truncate xl:w-34 font-light text-sm focus:outline-none focus:ring-primary focus:border-primary border"
                        value={props.value !== null ? props.value : ""}
                    />
                    <p className="text-red-400 text-sm font-light">{props.errors}</p>
                </>
            )}
        </div>
    );
}
