import {
    GET_FOLDER_STARTED,
    GET_FOLDER_SUCCESS,
    GET_FOLDER_FAILED,
} from "./folderConstants";

const initialState = {
    folders: {},
    loading: false,
    error: null,
};

export default function folderReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_FOLDER_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                folders: payload.folders.folders,
            };
        case GET_FOLDER_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
