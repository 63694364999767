import {
    SIGN_IN_USER_STARTED,
    SIGN_IN_USER_SUCCESS,
    SIGN_IN_USER_FAILED,
    SIGN_OUT_USER,
    SIGN_IN_TOKEN,
    GET_STAFF_STARTED,
    GET_STAFF_SUCCESS,
    GET_STAFF_FAILED,
} from "./authConstants";
import { APP_LOADED } from "../async/asyncReducer";
import axios from "axios";

export function signInUser(user) {
    return async (dispatch) => {
        dispatch(signInUserStarted());

        let res = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}login`,
            data: {
                email: user.email,
                password: user.password,
            },
        })
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                dispatch(signInUserSuccess(response.data));

                return "ok";
            })
            .catch((error) => {
                dispatch(signInUserFailed(error.response.data));

                return error.response.data;
            });

        return res;
    };
}

export function signInUserStarted() {
    return {
        type: SIGN_IN_USER_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const signInUserSuccess = (user) => {
    return {
        type: SIGN_IN_USER_SUCCESS,
        payload: {
            user,
        },
    };
};

const signInUserFailed = (error) => {
    return {
        type: SIGN_IN_USER_FAILED,
        payload: {
            error,
        },
    };
};

export function signInToken(user) {
    return (dispatch) => {
        dispatch({
            type: SIGN_IN_TOKEN,
            payload: user,
        });
    };
}

export function verifyAuth() {
    return function (dispatch, getState) {
        dispatch({ type: APP_LOADED });
    };
}

export function signOutUser() {
    return (dispatch) => {
        localStorage.removeItem("token");
        dispatch({
            type: SIGN_OUT_USER,
        });
    };
}

export const getStaff = () => {
    return (dispatch) => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}staff`,
        })
            .then((response) => {
                dispatch(getStaffSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getStaffFailed(error.data));
            });
    };
};

export function getStaffStarted() {
    return {
        type: GET_STAFF_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const getStaffSuccess = (staff) => {
    return {
        type: GET_STAFF_SUCCESS,
        payload: {
            staff,
        },
    };
};

const getStaffFailed = (error) => {
    return {
        type: GET_STAFF_FAILED,
        payload: {
            error,
        },
    };
};
