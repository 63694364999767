import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import SubmitButton from "../../components/Forms/SubmitButton";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";

export default function ReportsMentions() {
    const [loading, setLoading] = useState(false);
    const { staff } = useSelector((state) => state.auth);

    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [allStaff, setAllStaff] = useState([]);

    useEffect(async () => {
        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }

        let staffList = [];

        staff.forEach(function (value) {
            staffList.push({ value: value.id, label: value.name + " " + value.surname });
        });

        setAllStaff(staffList);
    }, []);

    if (
        current_staff.id != "1" &&
        current_staff.id != "291" &&
        current_staff.id != "23399" &&
        current_staff.id != "9503" &&
        current_staff.id != "294" &&
        current_staff.id != "297" &&
        current_staff.id != "24024"
    )
        return "Access Error!";

    return (
        <>
            <div className="w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Mentions Report</h1>

                <Formik
                    initialValues={{
                        export_type: 0,
                        export_person: 0,
                    }}
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        if (values.export_type == 0 || ((values.export_type === 1 || values.export_type === 2) && (fromDate === null || toDate === null))) {
                            alert("Please fill in all filters");
                        } else {
                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}reports/export_user_tracking`,
                                responseType: "blob",
                                data: { export_type: values.export_type, fromDate: fromDate, toDate: toDate, export_person: values.export_person },
                            })
                                .then((response) => {
                                    console.log(response.data);

                                    const href = URL.createObjectURL(response.data);

                                    const link = document.createElement("a");
                                    link.href = href;
                                    link.setAttribute("download", "User Tracking.xlsx");
                                    document.body.appendChild(link);
                                    link.click();

                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(href);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, setFieldValue, handleSubmit, isValid, isSubmitting, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <div>
                                <div className="grid lg:grid-cols-12 md:lg:grid-cols-6 gap-12 mt-5 mb-5 ">
                                    <SettingsSelect
                                        label="What is your desired Export?"
                                        name="export_type"
                                        onChange={setFieldValue}
                                        value={values.export_type}
                                        options={[
                                            { key: 3, label: "@ Mentions Totals", value: 3 },
                                            { key: 4, label: "Mentions Per Person", value: 4 },
                                        ]}
                                    />

                                    {values.export_type === 4 ? (
                                        <SettingsSelect label="Who your desired Export For?" name="export_person" onChange={setFieldValue} value={values.export_person} options={allStaff} />
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <SubmitButton name="Submit" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} text="Download Report" />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
