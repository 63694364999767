export const GET_FLAGGED_IDS_STARTED = "GET_FLAGGED_IDS_STARTED";
export const GET_FLAGGED_IDS_SUCCESS = "GET_FLAGGED_IDS_SUCCESS";
export const GET_FLAGGED_IDS_FAILED = "GET_FLAGGED_IDS_FAILED";
export const SET_BOOKING_LIST = "SET_BOOKING_LIST";
export const GET_BOOKING_FILES_STARTED = "GET_BOOKING_FILES_STARTED";
export const GET_BOOKING_FILES_SUCCESS = "GET_BOOKING_FILES_SUCCESS";
export const GET_BOOKING_FILES_FAILED = "GET_BOOKING_FILES_FAILED";
export const GET_BOOKING_STARTED = "GET_BOOKING_STARTED";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_FAILED = "GET_BOOKING_FAILED";
