import {
    GET_RENEWAL_STARTED,
    GET_RENEWAL_SUCCESS,
    GET_RENEWAL_FAILED,
} from "./renewalConstants";

const initialState = {
    renewals: {},
    loading: false,
    error: null,
};

export default function renewalReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_RENEWAL_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_RENEWAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                renewals: payload.renewals.renewals,
            };
        case GET_RENEWAL_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default: {
            return state;
        }
    }
}
