import {
    GET_PROPERTIES_STARTED,
    GET_PROPERTIES_SUCCESS,
    GET_PROPERTIES_FAILED,
} from "./propertyConstants";

import axios from "axios";

export const getProperties = () => {
    return (dispatch) => {
        dispatch(getPropertiesStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                dispatch(getPropertiesStartedSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getPropertiesStartedFailed(error.data));
            });
    };
};

export function getPropertiesStarted() {
    return {
        type: GET_PROPERTIES_STARTED,
        payload: {
            isLoading: true,
        },
    };
}

const getPropertiesStartedSuccess = (properties) => {
    return {
        type: GET_PROPERTIES_SUCCESS,
        payload: {
            properties,
        },
    };
};

const getPropertiesStartedFailed = (error) => {
    return {
        type: GET_PROPERTIES_FAILED,
        payload: {
            error,
        },
    };
};
