import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import { Formik, Form, Field } from "formik";
import SubmitButton from "../Forms/SubmitButton";
import { getBooking } from "../../store/booking/bookingActions";
import SettingsSwitch from "../Forms/SettingsSwitch";
import QuickFormDate from "../Forms/QuickFormDate";
import SettingsSelect from "../Forms/SettingsSelect";

export default function GridSettings({ setDoc, db }) {
    const [loading, setLoading] = useState(true);
    const { current_staff } = useSelector((state) => state.auth);
    const { current_booking } = useSelector((state) => state.booking);
    const dispatch = useDispatch();

    useEffect(async () => {}, []);

    return (
        <div className="h-96 overflow-y-scroll scroll-smooth">
            <Formik
                initialValues={{
                    pay_annual: current_booking.pay_annual,
                    pay_monthly: current_booking.pay_monthly,
                    pay_other: current_booking.pay_other,
                    pay_other_date: current_booking.pay_other_date,
                    vendor: current_booking.vendor,
                    nsfas_status: current_booking.nsfas_status,
                }}
                enableReinitialize
                onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                    values.booking_id = current_booking.id;
                    values.current_staff = current_staff;

                    let total_ticked = 0;
                    let date_other = 0;
                    if (values.pay_annual === 1) {
                        total_ticked += 1;
                    }
                    if (values.pay_monthly === 1) {
                        total_ticked += 1;
                    }
                    if (values.pay_other === 1) {
                        total_ticked += 1;
                        if (values.pay_other_date === null) {
                            date_other += 1;
                        }
                    }

                    if (current_booking.student_type !== 4) {
                        values.vendor = null;
                    }

                    if (total_ticked === 1) {
                        if (date_other === 0) {
                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}set_user_payment`,
                                data: values,
                            })
                                .then(() => {
                                    dispatch(getBooking(current_booking.id));
                                    toast(<NotificationToast title="Success" message="Data has been updated sucessfully" />);
                                })
                                .catch((error) => {
                                    console.log(error);
                                    toast(<NotificationToast title="Something went wrong" message="" type="error" />);
                                });
                        } else {
                            toast(<NotificationToast title="Something went wrong" message="Please select a date" type="error" />);
                        }
                    } else {
                        if (total_ticked === 0) {
                            toast(<NotificationToast title="Something went wrong" message="Please select at least one" type="error" />);
                        } else {
                            toast(<NotificationToast title="Something went wrong" message="Please select only one" type="error" />);
                        }
                    }

                    setSubmitting(false);
                }}
            >
                {({ values, touched, errors, handleSubmit, setFieldValue, setFieldTouched, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} className="rounded-3xl mt-3">
                        {current_booking.student_type === 4 ? (
                            <>
                                <div className="mb-5">
                                    <SettingsSelect
                                        label="Vendor"
                                        name="vendor"
                                        onChange={setFieldValue}
                                        onBlur={setFieldTouched}
                                        value={values.vendor}
                                        touched={touched.vendor}
                                        error={errors.vendor}
                                        options="vendor"
                                    />
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        <div className="mb-5">
                            <SettingsSelect
                                label="Status"
                                name="nsfas_status"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.nsfas_status}
                                touched={touched.nsfas_status}
                                error={errors.nsfas_status}
                                options="nsfas_status"
                            />
                        </div>

                        <div className="space-y-4 sm:grid sm:grid-cols-6 gap-2 mb-5">
                            <Field name="pay_annual" as={SettingsSwitch} label="Annual" description=" " />
                            <br />
                            <Field name="pay_monthly" as={SettingsSwitch} label="Monthly" description=" " />
                            <br />
                            <Field name="pay_other" as={SettingsSwitch} label="Other" description=" " />
                            <Field name="pay_other_date" type="text" as={QuickFormDate} label="At" />
                        </div>

                        {!current_staff.building_manager ? (
                            <SubmitButton name="Save" disabled={!isValid || isSubmitting || !dirty} isSubmitting={isSubmitting} icon={faSave} text="Update" style={{ width: "100%" }} />
                        ) : (
                            ""
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
}
