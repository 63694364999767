import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log("Log Error!", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
            // return (
            //     <>
            //         <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            //             <div className="max-w-max mx-auto">
            //                 <main className="sm:flex">
            //                     <p className="text-4xl font-extrabold text-primary sm:text-5xl">
            //                         401
            //                     </p>
            //                     <div className="sm:ml-6">
            //                         <div className="sm:border-l sm:border-gray-200 sm:pl-6">
            //                             <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            //                                 You are not logged in
            //                             </h1>
            //                             <p className="mt-1 text-base text-gray-500">
            //                                 We are redirecting you to the login
            //                                 page
            //                             </p>
            //                         </div>
            //                     </div>
            //                 </main>
            //             </div>
            //         </div>
            //     </>
            // );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
