import React, { useEffect, Fragment, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";

import moment from "moment";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsDateTimePicker from "../Forms/SettingsDateTimePicker";
import { getBooking } from "../../store/booking/bookingActions";
import { getStaff } from "../../store/auth/authActions";
import ProfilePicture from "../Auth/ProfilePicture";
import SettingsSwitch from "../Forms/SettingsSwitch";

export default function ModalMoveFiles(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { staff } = useSelector((state) => state.auth);

  async function download_file(file) {
    await axios({
      method: "post",
      responseType: "blob",
      url: `${process.env.REACT_APP_API_URL}download_file_link`,
      data: { file_link: file },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let name = file.split("/").pop();
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        toast(
          <NotificationToast
            title="File Missing"
            message={"Something went wrong, we could not download this file!"}
            type="error"
          />
        );
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>

                  {props.files &&
                    props.files.map((file, index) => (
                      <div
                        key={file}
                        className="py-4 text-center sm:mt-0 sm:ml-4 sm:text-left"
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() => download_file(props.files[index].path)}
                          className="font-medium text-gray-400 hover:text-gray-800 cursor-pointer"
                          title="Download File"
                        />
                        <img
                          src={`data:image/jpg;base64,${props.files[index].content}`}
                          alt="image"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
