import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@fortawesome/pro-regular-svg-icons";

export default function ProfilePictureUpload({ selectedUser }) {
    const dispatch = useDispatch();
    const { current_staff } = useSelector((state) => state.auth);
    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        setUploading(true);

        await Promise.all(
            acceptedFiles.map((file) => {
                uploadFile(file);
            })
        );
        //
    }, []);

    function uploadFile(file) {
        var form_data = new FormData();

        form_data.append("file", file);
        form_data.append("user_id", selectedUser.id ? selectedUser.id : current_staff?.id);

        axios.post(`${process.env.REACT_APP_API_URL}profile_picture`, form_data).finally(() => {
            setUploading(false);
            window.location.reload();
        });
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });

    return (
        <div className="max-w-xs" {...getRootProps()}>
            <div className="relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-xs font-normal focus:z-10 focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 bg-primary hover:bg-primary_hover focus:ring-primary">
                <div className="text-center">
                    {!uploading ? <p></p> : <FontAwesomeIcon icon={faLoader} className="animate-spin h-5 w-5" />}
                    <div className="flex">
                        <label htmlFor="file-upload" className="relative cursor-pointer focus-within:outline-none">
                            {!uploading ? <span>Change</span> : <span>Files uploading</span>}
                            <input {...getInputProps()} />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}
