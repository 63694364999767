import { GET_APPOINTMENT_STARTED, GET_APPOINTMENT_SUCCESS, GET_APPOINTMENT_FAILED } from "./appointmentConstants";
import axios from "axios";

export const getAppointments = () => {
    return (dispatch) => {
        dispatch(getAppointmentsStarted());

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}appointments`,
        })
            .then((response) => {
                dispatch(getAppointmentsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getAppointmentsFailed(error.response));
            });
    };
};

const getAppointmentsStarted = () => {
    return {
        type: GET_APPOINTMENT_STARTED,
        payload: {
            isLoading: true,
        },
    };
};

const getAppointmentsSuccess = (appointments) => {
    return {
        type: GET_APPOINTMENT_SUCCESS,
        payload: {
            appointments,
        },
    };
};

const getAppointmentsFailed = (error) => {
    return {
        type: GET_APPOINTMENT_FAILED,
        payload: {
            error,
        },
    };
};
