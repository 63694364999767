import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

export default function MetersPage() {
    const dispatch = useDispatch();
    const [readings, setReadings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        getReadings();

        setLoading(false);
    }, []);

    async function getReadings() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}meter_readings`,
        }).then((response) => {
            setReadings(response.data);
            setLoading(false);
        });
    }

    console.log(readings);

    const imgurl = `${process.env.REACT_APP_API_URL}meter_picture`;

    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
            <div className="bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1">
                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                    <h1 className="text-xl leading-6 font-light text-white capitalize">Meter Readings</h1>
                </div>

                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                    <div>filters</div>

                    <div>
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Unit
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Created
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Image
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        Readings
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {readings?.map((r) => (
                                    <tr key={r.id}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {r.property}
                                            <br />
                                            {r.unit}
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{r.created_at}</td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            <a href={imgurl + "/" + r.id} target="_blank">
                                                View Image
                                            </a>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {r.items?.map((i) => {
                                                return (
                                                    <div>
                                                        Confidence: {i.confidence}
                                                        <br />
                                                        Identifier: {i.identifier}
                                                        <br />
                                                        Text: {i.text}
                                                        <hr />
                                                    </div>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
