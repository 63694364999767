import React, { useEffect, useState, useRef, Fragment, useCallback } from "react";
import axios from "axios";
import Modal from "react-modal";
import { Link } from "react-router-dom";

export default function AdminLookupsPage() {
    return (
        <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen w-full">
            <div className="flex justify-between mb-8 items-center">
                <h1 className="text-lg font-bold">Lookups</h1>

                <Link to="/bot_flow" className="bg-primary text-white px-4 py-2 rounded-full">
                    Back to Bot Flows
                </Link>
            </div>

            <div className="flex justify-start mb-8 items-center space-x-4">
                <Link to="/admin_lookups/campus" className="bg-primary text-white px-4 py-2 rounded-full">
                    Campus Lookups
                </Link>

                <Link to="/admin_lookups/building_room_types" className="bg-primary text-white px-4 py-2 rounded-full">
                    Building Room Type Filters
                </Link>
            </div>
        </div>
    );
}
