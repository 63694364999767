import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ReportApplications() {
    const { current_staff } = useSelector((state) => state.auth);
    const { settings } = useSelector((state) => state.settings);
    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    useEffect(async () => {
        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    const [totalAppliocations, setTotalAppliocations] = useState([]);
    const [showTotalAppliocations, setShowTotalAppliocations] = useState(false);

    const [totalAppliocationsInfo, setTotalAppliocationsInfo] = useState([]);
    const [showTotalAppliocationsInfo, setShowTotalAppliocationsInfo] = useState(false);

    const [totalAppliocationsSentToVetting, setTotalAppliocationsSentToVetting] = useState([]);
    const [showTotalAppliocationsSentToVetting, setShowTotalAppliocationsSentToVetting] = useState(false);

    const [totalAppliocationsSentToVettingInfo, setTotalAppliocationsSentToVettingInfo] = useState([]);
    const [showTotalAppliocationsSentToVettingInfo, setShowTotalAppliocationsSentToVettingInfo] = useState(false);

    const [totalAppliocationsDeclinedVetting, setTotalAppliocationsDeclinedVetting] = useState([]);
    const [showTotalAppliocationsDeclinedVetting, setShowTotalAppliocationsDeclinedVetting] = useState(false);

    const [totalAppliocationsDeclinedVettingInfo, setTotalAppliocationsDeclinedVettingInfo] = useState([]);
    const [showTotalAppliocationsDeclinedVettingInfo, setShowTotalAppliocationsDeclinedVettingInfo] = useState(false);

    const [totalAppliocationsApprovedVetting, setTotalAppliocationsApprovedVetting] = useState([]);
    const [showTotalAppliocationsApprovedVetting, setShowTotalAppliocationsApprovedVetting] = useState(false);

    const [totalAppliocationsApprovedVettingInfo, setTotalAppliocationsApprovedVettingInfo] = useState([]);
    const [showTotalAppliocationsApprovedVettingInfo, setShowTotalAppliocationsApprovedVettingInfo] = useState(false);

    const [totalApplicationsReceived, setTotalApplicationsReceived] = useState([]);
    const [showTotalApplicationsReceived, setShowTotalApplicationsReceived] = useState(false);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    async function getTotalApplications() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                let arr1 = response.data[0];
                let arr2 = [{ name: "No Building", count: response.data[1][0].count }];
                console.log(arr1.concat(arr2));
                setTotalAppliocations(arr1.concat(arr2));
                setShowTotalAppliocations(true);
            })
            .catch((error) => {
                alert("Total Applications Failed");
            });
    }

    async function getTotalApplicationsInfo() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_info`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsInfo(response.data);
                setShowTotalAppliocationsInfo(true);
            })
            .catch((error) => {
                alert("Total Applications Information Failed");
            });
    }

    async function totalApplicationsSentToVetting() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_sent_to_vetting`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsSentToVetting(response.data);
                setShowTotalAppliocationsSentToVetting(true);
            })
            .catch((error) => {
                alert("Total Applications Sent To Vetting Failed");
            });
    }

    async function totalApplicationsSentToVettingInfo() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_sent_to_vetting_info`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsSentToVettingInfo(response.data);
                setShowTotalAppliocationsSentToVettingInfo(true);
            })
            .catch((error) => {
                alert("Total Applications Sent To Vetting Information Failed");
            });
    }

    async function appsDeclinedAfterVetting() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_declined_after_vetted`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsDeclinedVetting(response.data);
                setShowTotalAppliocationsDeclinedVetting(true);
            })
            .catch((error) => {
                alert("Total Applications Declined After Being Vetted Failed");
            });
    }

    async function appsDeclinedAfterVettingInfo() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_declined_after_vetted_info`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsDeclinedVettingInfo(response.data);
                setShowTotalAppliocationsDeclinedVettingInfo(true);
            })
            .catch((error) => {
                alert("Total Applications Declined After Being Vetted Information Failed");
            });
    }

    async function appsApprovedAfterVetting() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_approved_after_vetted`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsApprovedVetting(response.data);
                setShowTotalAppliocationsApprovedVetting(true);
            })
            .catch((error) => {
                alert("Total Applications Approved After Being Vetted Failed");
            });
    }

    async function appsApprovedAfterVettingInfo() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_approved_after_vetted_info`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalAppliocationsApprovedVettingInfo(response.data);
                setShowTotalAppliocationsApprovedVettingInfo(true);
            })
            .catch((error) => {
                alert("Total Applications Approved After Being Vetted Information Failed");
            });
    }

    async function totalApplicationsReceivedReferrals() {
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}reports/total_applications_received_referrals`,
            data: { fromDate: fromDate, toDate: toDate },
        })
            .then((response) => {
                setTotalApplicationsReceived(response.data);
                setShowTotalApplicationsReceived(true);
            })
            .catch((error) => {
                alert("Total Applications Approved After Being Vetted Failed");
            });
    }

    return (
        <>
            <div className="h-screen w-auto bg-gray-100 my-6 xl:my-0 xl:mx-4 rounded-3xl flex-1 p-5">
                <h1>Applications Reports</h1>

                <div className="my-5 grid grid-cols-6 grid-flow-col gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">From Date</label>
                        <DatePicker
                            selected={fromDate}
                            onChange={(value) => {
                                setFromDate(value);
                                setShowTotalAppliocations(false);
                                setShowTotalAppliocationsSentToVetting(false);
                                setShowTotalAppliocationsDeclinedVetting(false);
                                setShowTotalAppliocationsApprovedVetting(false);
                                setShowTotalApplicationsReceived(false);
                                setShowTotalAppliocationsInfo(false);
                                setShowTotalAppliocationsSentToVettingInfo(false);
                                setShowTotalAppliocationsDeclinedVettingInfo(false);
                                setShowTotalAppliocationsApprovedVettingInfo(false);
                            }}
                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                            dateFormat={"d MMMM yyyy"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">To Date</label>
                        <DatePicker
                            selected={toDate}
                            onChange={(value) => {
                                setToDate(value);
                                setShowTotalAppliocations(false);
                                setShowTotalAppliocationsSentToVetting(false);
                                setShowTotalAppliocationsDeclinedVetting(false);
                                setShowTotalAppliocationsApprovedVetting(false);
                                setShowTotalApplicationsReceived(false);
                                setShowTotalAppliocationsInfo(false);
                                setShowTotalAppliocationsSentToVettingInfo(false);
                                setShowTotalAppliocationsDeclinedVettingInfo(false);
                                setShowTotalAppliocationsApprovedVettingInfo(false);
                            }}
                            className="z-20 block w-full font-light border border-gray-300 shadow-sm mt-1 py-2 pl-2 rounded-lg focus:outline-none focus:ring-primary focus:border-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                            dateFormat={"d MMMM yyyy"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </div>
                </div>

                <div className="my-5 grid grid-cols-2 grid-flow-col gap-4">
                    <div>
                        <button
                            className="justify-center w-48 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => getTotalApplications()}
                        >
                            Total Applications
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocations && showTotalAppliocations === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Name</th>
                                                {/* <th>Booked Date</th> */}
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocations.length > 0 &&
                                                totalAppliocations.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.name}</td>
                                                        {/* <td>{application.date_booked}</td> */}
                                                        <td>{application.count}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <div>
                        <button
                            className="justify-center w-72 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => getTotalApplicationsInfo()}
                        >
                            Total Applications Information
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsInfo && showTotalAppliocationsInfo === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Property Name</th>
                                                <th>Booking Created At</th>
                                                <th>Booking reference</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Current Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsInfo.length > 0 &&
                                                totalAppliocationsInfo.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.property_name}</td>
                                                        <td>{application.created_at}</td>
                                                        <td>{application.booking_reference}</td>
                                                        <td>{application.name}</td>
                                                        <td>{application.surname}</td>
                                                        <td>{application.location}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="my-5 grid grid-cols-2 grid-flow-col gap-4">
                    <div>
                        <button
                            className="justify-center w-64 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => totalApplicationsSentToVetting()}
                        >
                            Applications sent to vetting
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsSentToVetting && showTotalAppliocationsSentToVetting === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Name</th>
                                                {/* <th>Booked Date</th> */}
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsSentToVetting.length > 0 &&
                                                totalAppliocationsSentToVetting.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.name}</td>
                                                        {/* <td>{application.date_booked}</td> */}
                                                        <td>{application.count}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <div>
                        <button
                            className="justify-center w-72 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => totalApplicationsSentToVettingInfo()}
                        >
                            Applications sent to vetting Info
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsSentToVettingInfo && showTotalAppliocationsSentToVettingInfo === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Property Name</th>
                                                <th>Booking Created At</th>
                                                <th>Booking reference</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Current Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsSentToVettingInfo.length > 0 &&
                                                totalAppliocationsSentToVettingInfo.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.property_name}</td>
                                                        <td>{application.created_at}</td>
                                                        <td>{application.booking_reference}</td>
                                                        <td>{application.name}</td>
                                                        <td>{application.surname}</td>
                                                        <td>{application.location}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="my-5 grid grid-cols-2 grid-flow-col gap-4">
                    <div>
                        <button
                            className="justify-center w-96 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => appsDeclinedAfterVetting()}
                        >
                            Applications DECLINED after being vetted
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsDeclinedVetting && showTotalAppliocationsDeclinedVetting === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Name</th>
                                                {/* <th>Booked Date</th> */}
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsDeclinedVetting.length > 0 &&
                                                totalAppliocationsDeclinedVetting.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.name}</td>
                                                        {/* <td>{application.date_booked}</td> */}
                                                        <td>{application.count}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <div>
                        <button
                            className="justify-center w-96 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => appsDeclinedAfterVettingInfo()}
                        >
                            Applications DECLINED after being vetted Info
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsDeclinedVettingInfo && showTotalAppliocationsDeclinedVettingInfo === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Property Name</th>
                                                <th>Booking Created At</th>
                                                <th>Booking reference</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Current Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsDeclinedVettingInfo.length > 0 &&
                                                totalAppliocationsDeclinedVettingInfo.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.property_name}</td>
                                                        <td>{application.created_at}</td>
                                                        <td>{application.booking_reference}</td>
                                                        <td>{application.name}</td>
                                                        <td>{application.surname}</td>
                                                        <td>{application.location}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="my-5 grid grid-cols-2 grid-flow-col gap-4">
                    <div>
                        <button
                            className="justify-center w-96 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => appsApprovedAfterVetting()}
                        >
                            Applications Approved after being vetted
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsApprovedVetting && showTotalAppliocationsApprovedVetting === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Name</th>
                                                {/* <th>Booked Date</th> */}
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsApprovedVetting.length > 0 &&
                                                totalAppliocationsApprovedVetting.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.name}</td>
                                                        {/* <td>{application.date_booked}</td> */}
                                                        <td>{application.count}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>

                    <div>
                        <button
                            className="justify-center w-96 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                            onClick={() => appsApprovedAfterVettingInfo()}
                        >
                            Applications Approved after being vetted Info
                        </button>

                        <div className="my-5 grid grid-cols-1 grid-flow-col gap-4">
                            {totalAppliocationsApprovedVettingInfo && showTotalAppliocationsApprovedVettingInfo === true && (
                                <>
                                    <table className="table-auto min-w-full text-center">
                                        <thead className="border-b bg-gray-50">
                                            <tr>
                                                <th>Property Name</th>
                                                <th>Booking Created At</th>
                                                <th>Booking reference</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Current Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalAppliocationsApprovedVettingInfo.length > 0 &&
                                                totalAppliocationsApprovedVettingInfo.map((application) => (
                                                    <tr className="bg-white border-b">
                                                        <td>{application.property_name}</td>
                                                        <td>{application.created_at}</td>
                                                        <td>{application.booking_reference}</td>
                                                        <td>{application.name}</td>
                                                        <td>{application.surname}</td>
                                                        <td>{application.location}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <button
                    className="justify-center w-80 relative flex text-white uppercase items-center px-4 py-2 rounded-lg text-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary bg-primary hover:bg-primary_hover transition duration-150 ease-in-ease-out hover:-translate-y-0.5"
                    onClick={() => totalApplicationsReceivedReferrals()}
                >
                    Total Applications received - Referral
                </button>

                <div className="my-5 grid grid-cols-3 grid-flow-col gap-4">
                    {totalApplicationsReceived && showTotalApplicationsReceived === true && (
                        <>
                            <table className="table-auto min-w-full text-center">
                                <thead className="border-b bg-gray-50">
                                    <tr>
                                        <th>Name</th>
                                        {/* <th>Booked Date</th> */}
                                        <th>Referral</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {totalApplicationsReceived.length > 0 &&
                                        totalApplicationsReceived.map((application) => (
                                            <tr className="bg-white border-b">
                                                <td>{application.name}</td>
                                                {/* <td>{application.date_booked}</td> */}
                                                <td>{application.referral}</td>
                                                <td>{application.count}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
