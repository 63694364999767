import {
    GET_FLAGGED_IDS_STARTED,
    GET_FLAGGED_IDS_SUCCESS,
    GET_FLAGGED_IDS_FAILED,
    SET_BOOKING_LIST,
    GET_BOOKING_STARTED,
    GET_BOOKING_SUCCESS,
    GET_BOOKING_FAILED,
    GET_BOOKING_FILES_STARTED,
    GET_BOOKING_FILES_SUCCESS,
    GET_BOOKING_FILES_FAILED,
} from "./bookingConstants";

const initialState = {
    current_booking: {},
    booking_list: [],
    loading: false,
    flagged_ids: [],
    error: "",
};

export default function bookingReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_BOOKING_LIST:
            return {
                ...state,
                booking_list: payload.booking_list,
            };
        case GET_BOOKING_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_BOOKING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                current_booking: payload.booking,
            };
        case GET_BOOKING_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case GET_FLAGGED_IDS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_FLAGGED_IDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                flagged_ids: payload.ids,
            };
        case GET_FLAGGED_IDS_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        case GET_BOOKING_FILES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case GET_BOOKING_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                // replace current booking files with new files
                current_booking: {
                    files: payload.files,
                },
            };
        case GET_BOOKING_FILES_FAILED:
            return {
                ...state,
                loading: false,
                error: payload.error,
            };
        default:
            return state;
    }
}
