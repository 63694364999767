import React, { Fragment, useEffect, useState } from "react";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Form, Field, FieldArray } from "formik";
import ErrorBoundary from "../../containers/ErrorBoundary";
import SettingsTextInput from "../Forms/SettingsTextInput";
import NotificationToast from "../Helpers/NotificationToast";
import SubmitButton from "../Forms/SubmitButton";
import SettingsSelect from "../Forms/SettingsSelect";

export default function OrderModal(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const { current_booking } = useSelector((state) => state.booking);
  const { current_staff } = useSelector((state) => state.auth);
  const [properties, setProperties] = useState([]);
  const [iniValues, setIniValues] = useState([]);

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);

  useEffect(async () => {
    getSelection();
    setMainValues();
  }, []);

  async function setMainValues() {
    if (props.type === "general") {
      setIniValues({
        order_type: "",
        order_reason: "",
        item: [],
        property_id: "",
        added_by: current_staff.id,
      });
    } else {
      setIniValues({
        order_type: "",
        order_reason: "",
        item: [],
        booking_id: current_booking.id,
        user_id: current_booking.user.id,
        property_id: current_booking.property_id,
        unit_id: current_booking.unit_id,
        added_by: current_staff.id,
      });
    }
  }

  async function getSelection() {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}properties`,
    })
      .then((response) => {
        let properties = response.data;
        let propertyList = [];

        Object.keys(properties).forEach((key) => {
          if (
            current_staff.building_manager === 0 ||
            (current_staff.building_manager === 1 &&
              current_staff.bm_property_id === properties[key].value)
          ) {
            propertyList.push({
              key: properties[key].value,
              value: properties[key].value,
              label: properties[key].label.split("-")[0],
            });
          }
        });

        setProperties(propertyList);
      })
      .catch((error) => {
        alert("properties failed");
      });

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_selection`,
    })
      .then((response) => {
        let categories = response.data[0];
        let categoryList = [];

        Object.keys(categories).forEach((key) => {
          categoryList.push({
            key: categories[key].id,
            value: categories[key].id,
            label: categories[key].name,
          });
        });

        setCategoriesOptions(categoryList);
      })
      .catch((error) => {
        alert("order load failed");
      });
  }

  async function items(value) {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}get_selection_items/${value}`,
    })
      .then((response) => {
        let items = response.data;
        let itemList = [];

        Object.keys(items).forEach((key) => {
          itemList.push({
            key: items[key].id,
            value: items[key].id,
            label: items[key].name,
            price: items[key].price,
          });
        });

        setItemOptions(itemList);
      })
      .catch((error) => {
        alert("order load failed");
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ErrorBoundary>
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="pr-8 w-full">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="text-lg font-medium text-gray-900"
                    >
                      Maintenance Order
                    </Dialog.Title>

                    <Formik
                      initialValues={iniValues}
                      enableReinitialize="true"
                      validate={(values) => {
                        const errors = {};
                        if (!values.property_id) {
                          errors.property_id = "Required";
                        }
                        if (!values.order_reason) {
                          errors.order_reason = "Required";
                        }
                        if (!values.order_type) {
                          errors.order_type = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={async (
                        values,
                        { setSubmitting, setErrors, resetForm }
                      ) => {
                        setSubmitting(true);

                        await axios({
                          method: "post",
                          url: `${process.env.REACT_APP_API_URL}create_order`,
                          data: values,
                        })
                          .then((response) => {
                            setSubmitting(false);
                            toast(
                              <NotificationToast
                                title={"Order Added"}
                                message="Order Created Successfully"
                                type="success"
                              />
                            );
                            props.setReNewList(true);
                            dispatch(closeModal());
                          })
                          .catch((error) => {
                            toast(
                              <NotificationToast
                                title={"Something went wrong"}
                                message={error.response.data.message}
                                type="error"
                              />
                            );
                          });
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setValues,
                      }) => (
                        <Form
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200 m-4"
                        >
                          <div className="py-6 px-4 sm:p-6 xl:pb-8">
                            <div className="grid grid-cols-6 gap-6 mt-6 mb-5">
                              <SettingsSelect
                                label="Type"
                                name="order_type"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.order_type}
                                touched={touched.order_type}
                                error={errors.order_type}
                                options={
                                  props.type === "general"
                                    ? "order_type_general"
                                    : "order_types"
                                }
                                required
                              />

                              <SettingsSelect
                                label="Reason"
                                name="order_reason"
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                value={values.order_reason}
                                touched={touched.order_reason}
                                error={errors.order_reason}
                                options={
                                  props.type === "general"
                                    ? "order_reasons_general"
                                    : "order_reasons"
                                }
                                required
                              />

                              {props.type === "general" ? (
                                <SettingsSelect
                                  label="Property"
                                  name="property_id"
                                  required
                                  onChange={setFieldValue}
                                  onBlur={setFieldTouched}
                                  value={values.property_id}
                                  touched={touched.property_id}
                                  error={errors.property_id}
                                  options={properties}
                                />
                              ) : (
                                ""
                              )}
                            </div>

                            <button
                              type="button"
                              className="bg-primary w-40 text-white py-1 rounded"
                              onClick={() => {
                                values.item.push({
                                  category: "",
                                  item_name: "",
                                  price: "",
                                  qty: "",
                                });
                                setValues(values);
                              }}
                            >
                              {" "}
                              ADD{" "}
                            </button>

                            <FieldArray
                              name="item"
                              render={(arrayHelpers) => (
                                <div>
                                  {values.item && values.item.length > 0
                                    ? values.item.map((friend, index) => (
                                        <div
                                          key={index}
                                          className="flex my-2 space-x-4"
                                        >
                                          <div className="grow w-20">
                                            <SettingsSelect
                                              name={`item.${index}.category`}
                                              onChange={setFieldValue}
                                              options={categoriesOptions}
                                              items={items}
                                            />
                                          </div>
                                          <div className="grow">
                                            <SettingsSelect
                                              name={`item.${index}.item_name`}
                                              onChange={setFieldValue}
                                              options={itemOptions}
                                              itemPrice={`item.${index}.price`}
                                              setFieldValue={setFieldValue}
                                            />
                                          </div>

                                          <Field
                                            name={`item.${index}.price`}
                                            as={SettingsTextInput}
                                            prepend="R"
                                            currency="true"
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Price"
                                            disabled={true}
                                          />
                                          <Field
                                            name={`item.${index}.qty`}
                                            type="number"
                                            as={SettingsTextInput}
                                            className="rounded-lg block w-60 font-light border border-gray-300 shadow-sm py-2 pl-2 focus:outline-none focus:ring-primary focus:border-primary text-sm transition ease-linear duration-10 hover:border-primary mr-2"
                                            placeholder="Quantity"
                                          />

                                          <button
                                            type="button"
                                            className="bg-dark_background text-white px-5 rounded"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            {" "}
                                            REMOVE{" "}
                                          </button>
                                        </div>
                                      ))
                                    : ""}
                                  <div></div>
                                </div>
                              )}
                            />
                          </div>

                          <div className="mt-8">
                            <SubmitButton
                              name="Save"
                              disabled={!isValid || isSubmitting || !dirty}
                              isSubmitting={isSubmitting}
                              icon={faSave}
                              text="Save"
                              style={{
                                width: "100%",
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </ErrorBoundary>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
