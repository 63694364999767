import React, { useState } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
export default function ViewFile ( props )
{
    const [ file, setFile ] = useState( props.file )
    const [ numPages, setNumPages ] = useState( 0 )

    function onDocumentLoadSuccess ( { numPages: nextNumPages } )
    {
        setNumPages( nextNumPages );
    }

    return (
        <Document
            file={ `data:application/pdf;base64,${ file.content }` }
            onLoadSuccess={ onDocumentLoadSuccess }
            className="overflow-scroll"
        >
            { [ ...Array( numPages ).keys() ].map( i => <Page key={ i + 1 } pageNumber={ i + 1 } /> ) }
        </Document>

    )
}