import React, { useEffect } from "react";
import { useField } from "formik";
import TimePicker from "react-time-picker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

export default function SettingsDateTimePicker({ ...props }) {
    const [field, meta, { setValue, setTouched }] = useField(props);

    return (
        <div className="mx-2">
            <TimePicker onChange={(value) => setValue(value)} value={props.selected} clockIcon={false} disableClock={true} format="hh:mm a" className="bg-white" name={props.name} />
            <p className="text-red-400 text-sm">{props.errors}</p>
        </div>
    );
}
