import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { closeModal } from "../../store/modal/modalReducer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTimes, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import ErrorBoundary from "../../containers/ErrorBoundary";
import { Formik, Form, Field } from "formik";

export default function ModalChooseBed(props) {
  const dispatch = useDispatch();
  const [open] = useState(true);
  const [interested, setInterested] = useState(0);
  const { settings } = useSelector((state) => state.settings);
  const [typeMatch, setTypeMatch] = useState(false);
  const [bed_info, set_bed_info] = useState([]);
  const student_types = [
    "NSFAS TVET",
    "Bursary",
    "Private",
    "NSFAS UJ",
    "NSFAS WITS",
    "CJC",
  ];

  useEffect(async () => {
    if (props.bed_id > 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_bed_bookings/${props.bed_id}/1/${settings.year_id}`,
      })
        .then((response) => {
          setInterested(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}get_bed_info/${props.bed_id}`,
      })
        .then((response) => {
          if (response.data.allowed_type == 0) {
            setTypeMatch(true);
          } else if (
            response.data.allowed_type == 1 &&
            (props.type == 1 ||
              props.type == 5 ||
              props.type == 4 ||
              props.type == 4)
          ) {
            setTypeMatch(true);
          } else if (
            response.data.allowed_type == 2 &&
            (props.type == 2 || props.type == 3)
          ) {
            setTypeMatch(true);
          }

          set_bed_info(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        <ErrorBoundary>
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                    onClick={() => dispatch(closeModal())}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                      icon={faTimes}
                    />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Panel className="text-center">
                      <Formik
                        initialValues={{ bed_id: props.bed_id }}
                        onSubmit={async (
                          values,
                          { setSubmitting, setErrors, resetForm }
                        ) => {
                          if (props.url != "0") {
                            axios({
                              method: "post",
                              url: `${process.env.REACT_APP_API_URL}set_user_bed`,
                              data: {
                                url: props.url,
                                bed_id: values.bed_id,
                                student_type: props.type,
                              },
                            })
                              .then((response) => {
                                // console.log(response.data);

                                if (response.data == "done") {
                                  window.location =
                                    "/step4/" +
                                    props.url +
                                    "/" +
                                    props.property_id +
                                    "/" +
                                    props.type +
                                    "/" +
                                    props.year +
                                    "/" +
                                    props.gender +
                                    "/" +
                                    props.unit +
                                    "/" +
                                    props.bed_id +
                                    "/0";
                                } else {
                                  alert(response.data);
                                }
                              })
                              .catch((error) => {
                                alert("Something went wrong");
                              });
                          } else {
                            window.location =
                              "/step4/" +
                              props.url +
                              "/" +
                              props.property_id +
                              "/" +
                              props.type +
                              "/" +
                              props.year +
                              "/" +
                              props.gender +
                              "/" +
                              props.unit +
                              "/" +
                              props.bed_id +
                              "/0";
                          }
                        }}
                      >
                        {({
                          values,
                          touched,
                          errors,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          dirty,
                        }) =>
                          typeMatch !== undefined &&
                          (typeMatch ? (
                            <Form onSubmit={handleSubmit}>
                              <div>
                                <h2 className="text-3xl font-bold mb-4">
                                  THE RACE IS ON!
                                </h2>

                                {interested > 0 && (
                                  <p className=" mb-4 text-red-600 font-bold">
                                    People are interested in this bed
                                  </p>
                                )}

                                <p className="mb-4">
                                  Officially <strong>secure your bed</strong> by{" "}
                                  <strong>
                                    {" "}
                                    paying your booking fee, uploading all the
                                    documents
                                  </strong>{" "}
                                  required and{" "}
                                  <strong>signing your lease</strong>.
                                  {/* <strong>
                                  completing your application, paying your
                                  booking fee
                                </strong>{" "}
                                and <strong>uploading all the documents</strong>{" "}
                                required. */}
                                </p>

                                <div>
                                  <div className="mx-6  mb-7">
                                    <div className="relative rounded-xl shadow-md z-20 bg-white flex items-center justify-center pt-20 p-6">
                                      <h3 className="absolute top-8 left-0 z-20 bg-dark_background h-14 w-2/3 flex items-center justify-center text-white uppercase font-bold uc_prop_border1 px-3">
                                        {bed_info?.property?.name} -{" "}
                                        {bed_info?.unit_type?.name}
                                      </h3>
                                    </div>

                                    <div className="bg-gray-200 -mt-5 z-10 pt-10 min-h-fit px-5 rounded-xl text-dark_background">
                                      <div className="text-sm font-bold">
                                        Monthly Rental:
                                      </div>

                                      <div className="text-4xl font-bold">
                                        {props.type == 3
                                          ? bed_info?.full_price?.[0]
                                              ?.private_price > 0
                                            ? "R " +
                                              bed_info?.full_price?.[0]
                                                ?.private_price +
                                              "/pm"
                                            : "R " +
                                              bed_info?.unit_type
                                                ?.private_price +
                                              "/pm"
                                          : props.type == 2
                                          ? bed_info?.full_price?.[0]
                                              ?.bursary_price > 0
                                            ? "R " +
                                              bed_info?.unit_type
                                                ?.full_price?.[0]
                                                ?.bursary_price +
                                              "/pm"
                                            : "R " +
                                              bed_info?.unit_type
                                                ?.bursary_price +
                                              "/pm"
                                          : bed_info?.full_price?.[0]
                                              ?.nsfas_price > 0
                                          ? "R " +
                                            bed_info?.full_price?.[0]
                                              ?.nsfas_price +
                                            "/pm"
                                          : bed_info?.unit_type?.nsfas_price ===
                                            0
                                          ? "NSFAS Rate"
                                          : "R " +
                                            bed_info?.unit_type?.nsfas_price +
                                            "/pm"}
                                      </div>
                                      <div className="text-sm mb-3">
                                        {props.type == 3
                                          ? bed_info?.unit_type
                                              ?.private_description
                                          : props.type == 2
                                          ? bed_info?.unit_type
                                              ?.bursary_description
                                          : bed_info?.unit_type
                                              ?.nsfas_description}
                                      </div>

                                      <div className="text-sm italic">
                                        Price Includes:
                                      </div>

                                      <div
                                        className="text-sm mb-7 font-bold"
                                        dangerouslySetInnerHTML={{
                                          __html: bed_info?.unit_type?.included,
                                        }}
                                      ></div>

                                      <div className="text-sm font-bold">
                                        Deposit:
                                      </div>

                                      <div className="text-4xl font-bold">
                                        R
                                        {props.type == 3
                                          ? bed_info?.full_price?.[0]
                                              ?.private_deposit > 0
                                            ? bed_info?.full_price?.[0]
                                                ?.private_deposit
                                            : bed_info?.unit_type
                                                ?.private_deposit
                                          : props.type == 2
                                          ? bed_info?.full_price?.[0]
                                              ?.bursary_deposit > 0
                                            ? bed_info?.full_price?.[0]
                                                ?.bursary_deposit
                                            : bed_info?.unit_type
                                                ?.bursary_deposit
                                          : bed_info?.full_price?.[0]
                                              ?.nsfas_deposit > 0
                                          ? bed_info?.full_price?.[0]
                                              ?.nsfas_deposit
                                          : bed_info?.unit_type?.nsfas_deposit}
                                      </div>
                                      <div className="text-sm mb-6">
                                        {props.type == 3
                                          ? bed_info?.unit_type
                                              ?.private_deposit_desc
                                          : props.type == 2
                                          ? bed_info?.unit_type
                                              ?.bursary_deposit_desc
                                          : bed_info?.unit_type
                                              ?.nsfas_deposit_desc}
                                      </div>

                                      <div className="text-sm font-bold">
                                        Booking Fee:
                                      </div>

                                      <div className="text-4xl font-bold">
                                        R
                                        {props.type == 3
                                          ? bed_info?.full_price?.[0]
                                              ?.private_booking_fee
                                            ? bed_info?.full_price?.[0]
                                                ?.private_booking_fee
                                            : bed_info?.unit_type
                                                ?.private_booking_fee
                                          : props.type == 2
                                          ? bed_info?.full_price?.[0]
                                              ?.bursary_booking_fee
                                            ? bed_info?.full_price?.[0]
                                                ?.bursary_booking_fee
                                            : bed_info?.unit_type
                                                ?.bursary_booking_fee
                                          : bed_info?.full_price?.[0]
                                              ?.nsfas_booking_fee
                                          ? bed_info?.full_price?.[0]
                                              ?.nsfas_booking_fee
                                          : bed_info?.unit_type
                                              ?.nsfas_booking_fee}
                                      </div>
                                      <div className="text-sm mb-6">
                                        {props.type == 3
                                          ? bed_info?.unit_type
                                              ?.private_booking_fee_desc
                                          : props.type == 2
                                          ? bed_info?.unit_type
                                              ?.bursary_booking_fee_desc
                                          : bed_info?.unit_type
                                              ?.nsfas_booking_fee_desc}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <button
                                    type="button"
                                    className="mx-2 relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                    onClick={() => dispatch(closeModal())}
                                  >
                                    BACK
                                  </button>

                                  <button
                                    type="submit"
                                    className="mx-2 relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                  >
                                    CONTINUE
                                  </button>
                                </div>
                              </div>
                            </Form>
                          ) : (
                            <p className="text-red-600 font-bold">
                              This bed is not available to{" "}
                              {`${student_types[props.type - 1]}`} students.
                            </p>
                          ))
                        }
                      </Formik>
                    </Dialog.Panel>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </ErrorBoundary>
      </Dialog>
    </Transition.Root>
  );
}
