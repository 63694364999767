import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";

export default function BookingWysiwyg({ ...props }) {
    //Set our default editor data from formik data
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value))));

    useEffect(() => {
        //We use this to update the editor state if our formik data changes, e.g. from a Template update
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value))));
    }, [props.value]);

    const onEditorStateChange = (editorState) => {
        //Update just our editor state if the editor changes
        setEditorState(editorState);
    };

    const onBlur = () => {
        //When we blur from editor set our prop state, if we do it in onEditorStateChange weird things happen
        const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        props.setFieldValue("message", forFormik);
    };

    var staff_list = [];
    props.staff.map((staff) => {
        staff_list.push({
            text: staff.name,
            value: staff.name,
            url: String(staff.id),
        });
    });

    return (
        <>
            <Editor
                mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: staff_list,
                }}
                hashtag={{}}
                {...props}
                onEditorStateChange={onEditorStateChange}
                editorClassName="px-2 py-2 block w-full sm:text-sm !outline-none resize-none bg-light_background h-full"
                wrapperClassName="bg-light_background"
                editorState={editorState}
                onBlur={onBlur}
                toolbar={{
                    options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link"],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    emoji: false,
                }}
            />
        </>
    );
}
