import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { verifyAuth } from "./auth/authActions";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["modals"],
};

export function configureStore() {
    const persistedReducer = persistReducer(
        persistConfig,
        rootReducer(history)
    );

    const store = createStore(
        persistedReducer,
        composeWithDevTools(applyMiddleware(thunk))
    );

    let persistor = persistStore(store);

    store.dispatch(verifyAuth());

    return { store, persistor };
}
